import FormError from 'components/form/FormError';
import FormPartTitle from 'components/FormPartTitle';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { ApiError } from 'helpers/apiError';
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { AclType } from 'types';
import { aclToString, resolvAclDescription, resolvAclName } from 'types/AclType';
import * as Yup from 'yup';

const AclLabel = styled(Form.Label)`
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 3px;
`;
const AclDescription = styled.div`
  margin-top: 3px;
  margin-bottom: 5px;
  font-size: 0.8em;
  margin-left: 2px;
`;

const FormInitValues = {
  user: undefined,
  email: undefined,
  classroomAcl: 'read-only',
  studentAcl: 'read-only',
  observationAcl: 'read-only',
  mediaAcl: 'read-only',
};

type FormValues = {
  email: string | undefined;
  user: any | undefined;
  classroomAcl: string;
  studentAcl: string;
  observationAcl: string;
  mediaAcl: string;
};

export const AclSchema = Yup.object().shape({
  email: Yup.string().email(),
  classroomAcl: Yup.string().oneOf(['full-access', 'read-only']).required('Ne peut être vide'),
  studentAcl: Yup.string().oneOf(['full-access', 'read-only']).required('Ne peut être vide'),
  observationAcl: Yup.string().oneOf(['forbidden', 'full-access', 'read-only']).required('Ne peut être vide'),
  mediaAcl: Yup.string().oneOf(['forbidden', 'full-access', 'read-only']).required('Ne peut être vide'),
  user: Yup.string().nullable(),
});

type AclFormProps = {
  entity?: AclType;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  onCancel?: () => void;
  error?: ApiError | undefined;
};

const AclForm = forwardRef(({ entity, onSubmit, onCancel, error }: AclFormProps, ref: any) => {
  // HOOKS
  const formRef = useRef<any | null>(null);

  // REF
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
  }));

  // MEMO
  const initvalues = useMemo(() => {
    return {
      ...FormInitValues,
      ...(entity
        ? {
            classroomAcl: entity.classroomAcl,
            studentAcl: entity.studentAcl,
            observationAcl: entity.observationAcl,
            mediaAcl: entity.mediaAcl,
          }
        : {}),
    };
  }, [entity]);

  return (
    <Formik
      innerRef={(ref) => {
        formRef.current = ref;
      }}
      onSubmit={(values, helpers) => onSubmit(AclSchema.cast(values), helpers)}
      initialValues={initvalues}
      validationSchema={AclSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}

          <p className={'text-danger'}>
            <strong style={{ fontWeight: 500 }}>
              <u>Attention</u> : Partager votre classe donnera accés à votre classe à un autre utilisateur. Veillez à
              avoir les autorisations nécessaires avant de procéder au partage.
            </strong>
          </p>

          <FormPartTitle>Utilisateur</FormPartTitle>
          <Form.Group controlId="formAcl.email">
            <Form.Control
              type="email"
              value={
                entity && entity?.user?.givenName
                  ? `${entity.user.givenName} ${entity.user.familyName}`
                  : entity?.user?.email
                  ? entity.user.email
                  : values.email
              }
              disabled={entity !== undefined}
              name={'email'}
              placeholder={"Email de l'utilisateur"}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.email && !!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>

          <FormPartTitle>Restrictions d'accès</FormPartTitle>
          <Form.Group controlId="formAcl.classroomacl">
            <AclLabel className="mt-0">{resolvAclName('classroomAcl')}</AclLabel>
            <AclDescription className="text-muted">{resolvAclDescription('classroomAcl')}</AclDescription>
            <Form.Select
              name={'classroomAcl'}
              value={values.classroomAcl}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.classroomAcl && !!errors.classroomAcl}
            >
              <option value={'read-only'}>{aclToString('read-only')}</option>
              <option value={'full-access'}>{aclToString('full-access')}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.classroomAcl}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formAcl.studentacl">
            <AclLabel>{resolvAclName('studentAcl')}</AclLabel>
            <AclDescription className="text-muted">{resolvAclDescription('studentAcl')}</AclDescription>
            <Form.Select
              name={'studentAcl'}
              value={values.studentAcl}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.studentAcl && !!errors.studentAcl}
            >
              <option value={'read-only'}>{aclToString('read-only')}</option>
              <option value={'full-access'}>{aclToString('full-access')}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.studentAcl}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formAcl.observationacl">
            <AclLabel>{resolvAclName('observationAcl')}</AclLabel>
            <AclDescription className="text-muted">{resolvAclDescription('observationAcl')}</AclDescription>
            <Form.Select
              name={'observationAcl'}
              value={values.observationAcl}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.observationAcl && !!errors.observationAcl}
            >
              <option value={'forbidden'}>{aclToString('forbidden')}</option>
              <option value={'read-only'}>{aclToString('read-only')}</option>
              <option value={'full-access'}>{aclToString('full-access')}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.observationAcl}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formAcl.mediaacl">
            <AclLabel>{resolvAclName('mediaAcl')}</AclLabel>
            <AclDescription className="text-muted">{resolvAclDescription('mediaAcl')}</AclDescription>
            <Form.Select
              name={'mediaAcl'}
              value={values.mediaAcl}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.mediaAcl && !!errors.mediaAcl}
            >
              <option value={'forbidden'}>{aclToString('forbidden')}</option>
              <option value={'read-only'}>{aclToString('read-only')}</option>
              <option value={'full-access'}>{aclToString('full-access')}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.mediaAcl}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

export default AclForm;
