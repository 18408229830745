import React, { useMemo, useImperativeHandle, useRef, forwardRef } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Form, Col, Row } from 'react-bootstrap';
import { ParentType } from 'types';
import FormError from 'components/form/FormError';
import FormPartTitle from 'components/FormPartTitle';
import FormControlWithIcon from 'components/FormControlWithIcon';
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi';
import { ParentCategory, resolvParentType, resolvParentTypeAsEmoj } from 'types/ParentType';
import { ApiError } from 'helpers/apiError';

const FormInitValues = {
  familyName: '',
  givenName: '',
  type: ParentCategory.father,
};

type FormValues = {
  familyName: string;
  givenName: string;
  type: string;
  comment?: string | undefined;
  email?: string | undefined;
  address?: string | undefined;
  phone?: string | undefined;
  profession?: string | undefined;
};

const FormSchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(ParentCategory)).required('Ne peut être vide'),
  familyName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères')
    .required('Ne peut être vide'),
  givenName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères')
    .required('Ne peut être vide'),
  comment: Yup.string()
    .trim()
    .max(255, 'Doit contenir au maximum 255 caractères')
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  email: Yup.string()
    .email('Doit être un email valide')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  phone: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères')
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  profession: Yup.string()
    .trim()
    .max(32, 'Doit contenir au maximum 32 caractères')
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  address: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .transform((v) => (v === '' ? null : v))
    .nullable(),
});

type ParentFormProps = {
  entity?: ParentType;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  error?: ApiError | undefined;
  onCancel?: () => void;
};

const ParentForm = forwardRef(({ entity, onSubmit, error, onCancel }: ParentFormProps, ref: any) => {
  // REF
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
    handleReset: async () => {
      formRef && formRef.current && (await formRef.current.resetForm());
    },
  }));

  // HOOKS
  const formRef = useRef<any | null>(null);

  // MEMO
  const initvalues = useMemo(() => {
    return {
      ...FormInitValues,
      ...(entity
        ? {
            familyName: entity.familyName,
            givenName: entity.givenName,
            type: entity.type,
            comment: entity.comment,
            email: entity.email,
            phone: entity.phone,
            address: entity.address,
            profession: entity.profession,
          }
        : {}),
    };
  }, [entity]);

  return (
    <Formik
      innerRef={(ref) => {
        formRef.current = ref;
      }}
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={initvalues}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}

          <Form.Group>
            <Row className={'align-items-center g-3'}>
              <Col xs={'auto'} style={{ fontSize: 35 }}>
                {resolvParentTypeAsEmoj(values.type)}
              </Col>
              <Col>
                <Form.Select
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.type && !!errors.type}
                >
                  {Object.values(ParentCategory).map((item, idx) => (
                    <option key={idx} value={item}>
                      {resolvParentType(item)}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="givenName"
              placeholder={'Prénom'}
              value={values.givenName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.givenName && !!errors.givenName}
            />
            <Form.Control.Feedback type="invalid">{errors.givenName}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nom de famille</Form.Label>
            <Form.Control
              type="text"
              name="familyName"
              placeholder={'Nom de famille'}
              value={values.familyName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.familyName && !!errors.familyName}
            />
            <Form.Control.Feedback type="invalid">{errors.familyName}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Profession</Form.Label>
            <Form.Control
              type="text"
              name="profession"
              value={values.profession}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.profession && !!errors.profession}
            />
            <Form.Control.Feedback type="invalid">{errors.profession}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Commentaire</Form.Label>
            <Form.Control
              as={'textarea'}
              rows={2}
              type="text"
              name="comment"
              value={values.comment}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.comment && !!errors.comment}
            />
            <Form.Control.Feedback type="invalid">{errors.comment}</Form.Control.Feedback>
          </Form.Group>

          <FormPartTitle>Coordonnées</FormPartTitle>
          <Form.Group className="mb-2">
            <FormControlWithIcon icon={<FiPhone />}>
              <Form.Control
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phone && !!errors.phone}
                placeholder={'Numéro de téléphone'}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2">
            <FormControlWithIcon icon={<FiMail />}>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
                placeholder={'Adresse email'}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2">
            <FormControlWithIcon icon={<FiMapPin />}>
              <Form.Control
                rows={3}
                as={'textarea'}
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.address && !!errors.address}
                placeholder={'Adresse\nprincipale'}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

export default ParentForm;
