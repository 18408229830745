export enum ObservationCategory {
  behaviour = 'behaviour',
  work = 'work',
  family = 'family',
  other = 'other',
}

export type ObservationType = {
  ['@id']: string;
  id: string;
  createdAt: string;
  updatedAt: string;

  type: ObservationCategory;
  content: string;
  date: string;
  student?: {
    ['@id']: string;
    id: string;
    familyName: string;
    givenName: string;
    avatar?: {
      regular: string;
      thumbnail: string;
    };
  };
  createdBy?: {
    ['@id']: string;
    id: string;
    familyName: string;
    givenName: string;
    avatar?: {
      regular: string;
      thumbnail: string;
    };
  };
  classroom: {
    ['@id']: string;
    id: string;
    name: string;
    avatar?: {
      regular: string;
      thumbnail: string;
    };
  };
  medias: {
    ['@id']: string;
    id: string;
    name: string;
    file: {
      regular: string;
      thumbnail: string;
      createdBy?: {
        ['@id']: string;
        id: string;
        familyName: string;
        givenName: string;
      };
      student?: {
        ['@id']: string;
        id: string;
        familyName: string;
        givenName: string;
        avatar?: {
          regular: string;
          thumbnail: string;
        };
      };
      classroom?: {
        ['@id']: string;
        id: string;
        name: string;
        avatar?: {
          regular: string;
          thumbnail: string;
        };
      };
      createdAt?: string;
    };
    type: 'image';
    date: string;
  }[];
};

export const resolvObservationType = (value: string): string => {
  switch (value) {
    case ObservationCategory.work:
      return 'Travail';
    case ObservationCategory.behaviour:
      return 'Comportement';
    case ObservationCategory.family:
      return 'Famille';
    default:
      return 'Autre';
  }
};

export const resolvObservationTypeColor = (value: string): string => {
  switch (value) {
    case ObservationCategory.work:
      return 'steelblue';
    case ObservationCategory.behaviour:
      return 'tomato';
    case ObservationCategory.family:
      return '#ffa954';
    default:
      return 'yellowgreen';
  }
};
