import React, { ComponentType } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';

const StyledEdit = styled(Button)`
  margin: 0;
  padding: 0 8px;
  display: inline;
`;

type EditButtonProps = {
  size?: 'sm' | 'lg';
  onClick: () => void;
  className?: string;
  variant?:
    | 'link'
    | 'outline-secondary'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'outline-primary'
    | 'outline-success'
    | 'outline-danger';
};

const EditButton: ComponentType<EditButtonProps> = ({ size, onClick, variant, className }) => {
  return (
    <>
      <StyledEdit className={className} size={size} variant={variant ?? 'link'} onClick={onClick}>
        <FiEdit />
      </StyledEdit>
    </>
  );
};

export default EditButton;
