import FormModal from 'components/modal/FormModal';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { StudentGroupEnhanced } from 'hooks/useCurrentClassroom';
import React, { ComponentType, useCallback, useState } from 'react';
import StudentGroupForm from './StudentGroupForm';
import { useCurrentClassroom } from 'hooks';
import useEntities from 'hooks/useEntities';
import DraggableStudentList from './DraggableStudentList';
import StudentGroupTitle from './StudentGroupTitle';

type StudentGroupProps = {
  group: StudentGroupEnhanced;
  handleAddStudent?: () => void;
};

const StudentGroup: ComponentType<StudentGroupProps> = ({ group, handleAddStudent }) => {
  /**
   * HOOKS
   */
  const [classroom, , { refreshStudents }] = useCurrentClassroom();
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [editError, setEditError] = useState<ApiError | undefined>();
  const { deleteEntity, editEntity } = useEntities();

  /**
   * REFRESH
   */
  const handleRefresh = useCallback(
    async (classroomId?: string | undefined) => {
      await refreshStudents(classroomId ?? group.classroom);
    },
    [group, refreshStudents]
  );

  /**
   * HANDLERS
   */
  const handleEdit = useCallback(
    async (values, helpers) => {
      if (!group) {
        return;
      }

      try {
        const classroomId = group.classroom;
        await editEntity(group['@id'], values);
        setEditVisible(false);
        await handleRefresh(classroomId);
        helpers.resetForm();
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [editEntity, group, handleRefresh]
  );

  const handleDelete = useCallback(async () => {
    const classroomId = group?.classroom;
    await deleteEntity(group['@id']);
    await refreshStudents(classroomId);
    setEditVisible(false);
  }, [deleteEntity, group, refreshStudents]);

  /**
   * RENDER
   */
  return (
    <>
      <StudentGroupTitle
        name={group.name}
        onEdit={classroom?.myAcl.studentAcl === 'full-access' ? () => setEditVisible(true) : undefined}
      />
      <DraggableStudentList id={group['@id']} students={group.students} handleAddStudent={handleAddStudent} />
      <FormModal
        size="sm"
        title={"Modifier le groupe d'élèves"}
        show={editVisible}
        onSubmit={handleEdit}
        onDelete={handleDelete}
        onClose={() => {
          setEditVisible(false);
          setEditError(undefined);
        }}
        error={editError}
        entity={group}
        form={(ref, onSubmit, error, entity) => (
          <StudentGroupForm ref={ref} onSubmit={onSubmit} error={error} entity={entity} />
        )}
        modalVisibilityHandler={() => setEditVisible(false)}
      />
    </>
  );
};

export default StudentGroup;
