import React, { ComponentType } from 'react';
import styled from 'styled-components';

const HiddenComponent = styled.div`
  height: 3.5rem;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &.hiddenable-hidden {
    height: 0 !important;
  }
`;

type HiddenableComponentProps = {
  hidden: boolean;
  style?: any;
};

const HiddenableComponent: ComponentType<HiddenableComponentProps> = ({ hidden, style, children }) => {
  return (
    <HiddenComponent className={hidden ? 'hiddenable-hidden' : ''} style={style}>
      {children}
    </HiddenComponent>
  );
};

export default HiddenableComponent;
