import Loader from 'components/Loader';
import { useCurrentClassroom } from 'hooks';
import React, { ComponentType } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import InformationList from './tabs/Informations/components/InformationList';
import StudentMedias from './tabs/Medias/StudentMedias';
import StudentObservations from './tabs/Observations/StudentObservations';
import ParentList from './tabs/Parents/components/ParentList';

const StudentRouter: ComponentType = () => {
  /** HOOKS */
  const [classroom] = useCurrentClassroom();

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          classroom ? (
            <Navigate to={classroom.myAcl.observationAcl !== 'forbidden' ? `observations` : `parents`} replace={true} />
          ) : (
            <Loader />
          )
        }
      />
      <Route path={`/parents`} element={<ParentList />} />
      <Route path={`/informations`} element={<InformationList />} />
      <Route path={`/observations`} element={<StudentObservations />} />
      <Route path={`/medias`} element={<StudentMedias />} />
    </Routes>
  );
};

export default StudentRouter;
