import React, { ComponentType } from 'react';
import ProgressiveMediasGallery from 'components/ProgressiveMediasGallery';
import useCurrentClassroom from 'hooks/useCurrentClassroom';

const ClassroomMedias: ComponentType = () => {
  // HOOKS
  const [classroom] = useCurrentClassroom();

  return (
    <>
      {classroom && (
        <ProgressiveMediasGallery
          classroom={classroom}
          baseurl={`/media?classroom=${classroom?.['@id']}&order[date]=desc`}
          enableEditAndDelete={classroom?.myAcl.mediaAcl === 'full-access'}
        />
      )}
    </>
  );
};

export default ClassroomMedias;
