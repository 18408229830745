import React, { ComponentType, useCallback, useState } from 'react';
import HelpForm from './components/HelpForm';
import useApiClient from 'hooks/useApiClient';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { useConnectedUser, useToast } from 'hooks';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { imgHelp } from 'helpers/illustration';
import { useNavigate } from 'react-router';

const Help: ComponentType = () => {
  // HOOKS
  const client = useApiClient();
  const [{ toast }] = useToast();
  const [user] = useConnectedUser();
  const navigate = useNavigate();

  // STATES
  const [submitError, setSubmitError] = useState<ApiError | undefined>();

  // HANDLER
  const handleSubmit = useCallback(
    async (values, helpers) => {
      try {
        await client.post('/help_request', values);

        helpers.resetForm();
        if (user) {
          navigate(`/dashboard`);
        } else {
          navigate(`/`);
        }

        toast({
          type: 'success',
          title: 'Votre message a été envoyé',
          message: 'Nous reviendrons vers vous aussi vite que possible.',
        });
      } catch (err) {
        setSubmitError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [client, toast, user, navigate]
  );

  return (
    <Container className={'marginauto'}>
      <div className={'text-center'}>
        <img src={imgHelp} alt={''} style={{ width: 400 }} />
        <div className="mt-4">
          <h3>Contactez-nous</h3>
        </div>
      </div>
      <p className={'text-center mt-4 mb-5'}>
        Un problème technique ? Une question ? Une idée d'amélioration ?<br />
        N'hésitez pas à nous contacter via le formulaire ci-dessous, nous vous répondrons dans les plus brefs délais.
      </p>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body>
              <HelpForm onSubmit={handleSubmit} error={submitError} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Help;
