import React, { ComponentType, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from 'views/NotFound/NotFound';
import Header from 'views/Header';
import Footer from 'views/Footer';
import Help from 'views/Help/Help';
import Terms from 'views/Legal/Terms';
import Discover from 'views/Discover/Discover';
import { useConnectedUser } from 'hooks';
import Account from 'views/Account/Account';
import ClassroomConsult from 'views/ClassroomConsult/ClassroomConsult';
import ClassroomInvitationConfirm from 'views/ClassroomInvitationConfirm/ClassroomInvitationConfirm';
import Dashboard from 'views/Dashboard/Dashboard';
import ConfirmEmail from 'views/ConfirmEmail/ConfirmEmail';
import PasswordLost from 'views/PasswordLost/PasswordLost';
import LogIn from 'views/LogIn/LogIn';
import Register from 'views/Register/Register';
import BigLoader from 'components/BigLoader';
import PrivacyPolicy from 'views/Legal/PrivacyPolicy';
import Legal from 'views/Legal/Legal';
import StudentConsult from 'views/StudentConsult/StudentConsult';
import CancelDeletion from 'views/CancelDeletion/CancelDeletion';

const RequireAuth = ({ children }) => {
  const [user, , userLoaded] = useConnectedUser();
  const { pathname, search } = useLocation();
  return userLoaded && !user ? <Navigate to={`/signin`} state={{ redirectTo: pathname + search }} /> : children;
};

const MainRouter: ComponentType = () => {
  // HOOKS
  const { pathname } = useLocation();
  const [, , userLoaded] = useConnectedUser();

  // MEMOS
  const isGray = useMemo(() => {
    const whiteUrls = ['/', '/dashboard', '/terms', '/privacy', '/legal', '/help'];
    if (!whiteUrls.includes(pathname)) {
      return true;
    }

    return false;
  }, [pathname]);

  return (
    <main role={'main'} className={isGray ? 'gray' : undefined}>
      <Header />
      {(userLoaded && (
        <Routes>
          <Route path={'/'} element={<Discover />} />
          <Route path={`/terms`} element={<Terms />} />
          <Route path={`/legal`} element={<Legal />} />
          <Route path={`/privacy`} element={<PrivacyPolicy />} />
          <Route path={`/help`} element={<Help />} />
          <Route path={`/signin`} element={<LogIn />} />
          <Route path={`/register`} element={<Register />} />
          <Route path={`/confirm_account`} element={<ConfirmEmail />} />
          <Route path={`/cancel_deletion`} element={<CancelDeletion />} />
          <Route path={`/password_lost`} element={<PasswordLost />} />

          <Route
            path={`/dashboard`}
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path={`/account`}
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
          <Route
            path={`/classrooms/:classroomId/*`}
            element={
              <RequireAuth>
                <ClassroomConsult />
              </RequireAuth>
            }
          />
          <Route
            path={`/classrooms/:classroomId/students/:studentId/*`}
            element={
              <RequireAuth>
                <StudentConsult />
              </RequireAuth>
            }
          />
          <Route
            path={`/classroom_invitation_confirm`}
            element={
              <RequireAuth>
                <ClassroomInvitationConfirm />
              </RequireAuth>
            }
          />

          <Route path={'*'} element={<NotFound />} />
        </Routes>
      )) || <BigLoader />}
      <Footer />
    </main>
  );
};

export default MainRouter;
