import React, { ComponentType } from 'react';
import { ClassroomType } from 'types';
import AppAvatar from './AppAvatar';

type ClassroomAvatarProps = {
  classroom?: ClassroomType;
  className?: string;
  shape: 'rounded' | 'square' | 'round';
  viewer?: boolean;
};

const ClassroomAvatar: ComponentType<ClassroomAvatarProps & any> = ({ classroom, ...props }) => {
  return (
    <AppAvatar
      alt={classroom?.name?.substr(0, 1)}
      source={classroom?.avatar?.thumbnail}
      sourceFullQuality={classroom?.avatar?.regular}
      {...props}
    />
  );
};

export default ClassroomAvatar;
