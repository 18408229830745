import React, { ComponentType, useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from 'components/Loader';
import ParentItem from './ParentItem';
import { ParentType } from 'types';
import ParentForm from 'views/StudentConsult/components/tabs/Parents/components/ParentForm';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import FormModal from 'components/modal/FormModal';
import ButtonWithIcon from 'components/ButtonWithIcon';
import EmptyMessageWithPicture from 'components/EmptyMessageWithPicture';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';
import { imgFamily } from 'helpers/illustration';

const ParentList: ComponentType = () => {
  // HOOKS
  const { postEntity } = useEntities();
  const [classroom] = useCurrentClassroom();
  const [student, { parents }, { refreshParents }] = useCurrentStudent();

  // STATES
  const [addVisible, setAddVisible] = useState(false);
  const [addError, setAddError] = useState<ApiError | undefined>();

  // REFRESH
  const handleRefresh = useCallback(async () => {
    if (!student) {
      return;
    }
    await refreshParents(student['@id']);
  }, [refreshParents, student]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const handleAdd = useCallback(
    async (values, helpers) => {
      if (!student) {
        return;
      }
      try {
        await postEntity(`/student_parents`, {
          ...values,
          ...{
            student: student['@id'],
          },
        });
        await handleRefresh();
        setAddVisible(false);
        helpers.resetForm();
      } catch (err) {
        setAddError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [postEntity, handleRefresh, student]
  );

  const handleEdit = useCallback(
    async (entity: any) => {
      await handleRefresh();
    },
    [handleRefresh]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await handleRefresh();
    },
    [handleRefresh]
  );

  if (!student || !parents) {
    return <Loader />;
  }

  return (
    <>
      {(parents && parents.length > 0 && (
        <>
          <Row>
            {parents.map((parent: ParentType) => (
              <Col md={6} key={parent.id}>
                <ParentItem
                  parent={parent}
                  onEdit={classroom?.myAcl.studentAcl === 'full-access' && handleEdit}
                  onDelete={classroom?.myAcl.studentAcl === 'full-access' && handleDelete}
                />
              </Col>
            ))}
          </Row>
        </>
      )) || (
        <EmptyMessageWithPicture img={imgFamily} title={'Un lien avec la famille'}>
          <p>Ici, retrouvez les informations et coordonnées des membres de la famille.</p>
        </EmptyMessageWithPicture>
      )}

      {classroom?.myAcl.studentAcl === 'full-access' && (
        <Row>
          <Col xs={'auto'} className={'ms-auto me-auto'}>
            <ButtonWithIcon variant={'primary'} onClick={() => setAddVisible(true)}>
              Nouveau parent
            </ButtonWithIcon>
          </Col>
        </Row>
      )}
      <FormModal
        title={'Nouveau parent'}
        show={addVisible}
        onSubmit={handleAdd}
        error={addError}
        onClose={() => {
          setAddVisible(false);
          setAddError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => <ParentForm ref={ref} onSubmit={onSubmit} error={error} />}
      />
    </>
  );
};

export default ParentList;
