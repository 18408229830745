import React, { ComponentType, useState, useCallback, useEffect } from 'react';
import useConfirm from 'hooks/useConfirm';
import { Modal, Button, Form } from 'react-bootstrap';

const ConfirmBox: ComponentType = () => {
  // HOOKS
  const [confirm] = useConfirm();

  // STATES
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState(undefined);

  // EFFECTS
  useEffect(() => {
    setVisible(confirm ? true : false);
    setDeleteConfirmText(undefined);
  }, [confirm]);

  // HANDLERS
  const handleClose = useCallback(() => setVisible(false), [setVisible]);
  const handleConfirm = useCallback(async () => {
    setProcessing(true);
    if (confirm) {
      await confirm.handleConfirm();
    }
    handleClose();
    setProcessing(false);
  }, [confirm, handleClose]);

  return (
    <>
      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{confirm?.title ?? 'Confirmation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirm?.body}
          {confirm?.confirmInputValue && (
            <div className={'mt-4 mb-3'}>
              <Form.Group controlId="formConfirmInput">
                <Form.Text className="text-muted">
                  Pour confirmer, veuillez saisir "{confirm.confirmInputValue}" :
                </Form.Text>
                <Form.Control
                  type="text"
                  placeholder={`Saisir "${confirm.confirmInputValue}"`}
                  value={deleteConfirmText}
                  onChange={(event) => setDeleteConfirmText(event.target.value)}
                  autoComplete={'off'}
                  autoCorrect={'off'}
                />
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              handleClose();
              confirm?.handleCancel && confirm.handleCancel();
            }}
            disabled={processing}
          >
            {confirm?.cancelText ?? 'Annuler'}
          </Button>
          <Button
            variant={confirm?.variant ?? 'danger'}
            onClick={async () => await handleConfirm()}
            disabled={processing || (confirm?.confirmInputValue && deleteConfirmText !== confirm?.confirmInputValue)}
          >
            {confirm?.confirmText ?? 'Confirmer'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmBox;
