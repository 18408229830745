import useApiClient from './useApiClient';
import { useCallback } from 'react';
import useConfig from './useConfig';

const useEntities = () => {
  // CONFIG
  const maxItemPerPage = useConfig((config) => config.api.maxItemPerPage);

  // HOOKS
  const client = useApiClient();

  // METHODS
  const fetchEntity = useCallback(
    async <T = any>(iri: string): Promise<T> => {
      const resp = await client.get(iri);
      return resp.data;
    },
    [client]
  );

  const postEntity = useCallback(
    async <T = any>(iri: string, data: any): Promise<T> => {
      const resp = await client.post(iri, data);
      return resp.data;
    },
    [client]
  );

  const editEntity = useCallback(
    async <T = any>(iri: string, data: any): Promise<T> => {
      const resp = await client.put(iri, data);
      return resp.data;
    },
    [client]
  );

  const deleteEntity = useCallback(
    async (iri: string) => {
      await client.delete(iri);
    },
    [client]
  );

  const fetch = useCallback(
    async (url: string, pageFrom: number, pageTo?: number): Promise<[any[], { isLastPage: boolean }]> => {
      pageTo = pageTo ?? pageFrom;
      let data: any[] = [];
      for (let page = pageFrom; page <= pageTo; page++) {
        const urlObj = new URL(url, 'https://127.0.0.1/');
        urlObj.searchParams.set('page', page.toString());
        const resp = await client.get(urlObj.pathname + urlObj.search);
        let respData: any[];
        if (resp.data['hydra:member']) {
          respData = resp.data['hydra:member'];
        } else {
          respData = resp.data;
        }
        data = [...data, ...respData];
        if (resp.data.length < maxItemPerPage) {
          break;
        }
      }
      return [
        data,
        {
          isLastPage: data.length < maxItemPerPage * (pageTo + 1 - pageFrom),
        },
      ];
    },
    [client, maxItemPerPage]
  );

  const fetchAll = useCallback(
    async <T = any>(url: string): Promise<T[]> => {
      // fetch all
      let iterationPage = 1;
      let entities: any[] = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const [lastFetchEntities, data] = await fetch(url, iterationPage++);
        entities = [...entities, ...lastFetchEntities];
        if (data.isLastPage) {
          break;
        }
      }
      return entities;
    },
    [fetch]
  );

  return {
    fetch,
    fetchAll,
    fetchEntity,
    editEntity,
    deleteEntity,
    postEntity,
  };
};

export default useEntities;
