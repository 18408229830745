import React, { ComponentType } from 'react';
import styled from 'styled-components';
import ObservationTypeName from 'components/ObservationTypeName';
import { ObservationCategory } from 'types/ObservationType';
import { Col, Row } from 'react-bootstrap';

const ButtonGroupS = styled(Row)`
  height: 100%;
`;

const ButtonS = styled.div`
  background: transparent !important;

  font-size: 1em;

  &.active,
  &:hover {
    background: transparent !important;
  }

  &:hover {
    cursor: pointer;
  }

  div {
    width: 100%;
  }
`;

type ObservationTypeInputProps = {
  value?: string;
  onChange: (arg: string) => void;
};

const ObservationTypeInput: ComponentType<ObservationTypeInputProps> = ({ value, onChange }) => {
  return (
    <ButtonGroupS className={'justify-content-center g-0'}>
      {Object.values(ObservationCategory).map((item, idx) => (
        <Col key={idx} xs={3} className={'px-2'}>
          <ButtonS onClick={() => onChange(item)} className={`${value === item ? 'active' : ''}`}>
            <ObservationTypeName variant={value === item ? 'regular' : 'outline'} type={item} />
          </ButtonS>
        </Col>
      ))}
    </ButtonGroupS>
  );
};

export default ObservationTypeInput;
