import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { Row, Col, Container } from 'react-bootstrap';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';
import StudentMainCard from './components/StudentMainCard';
import MediasPreviewCard from 'components/MediasPreviewCard';
import BackButton from 'components/BackButton';
import StudentNavBar from './components/StudentNavBar';
import StudentRouter from './components/StudentRouter';
import BigLoader from 'components/BigLoader';
import ErrorScreen from 'views/ErrorScreen/ErrorScreen';
import { imgLost } from 'helpers/illustration';

const StudentConsult: ComponentType = () => {
  // HOOKS
  const [classroom, , { init: initClassroom }] = useCurrentClassroom();
  const { studentId, classroomId } = useParams<{ studentId: string; classroomId: string }>();
  const navigate = useNavigate();
  const [student, , { init: initStudent }] = useCurrentStudent();
  const { pathname } = useLocation();

  // STATES
  const [err, setErr] = useState<string | undefined>(undefined);

  // REFRESH
  const handleRefresh = useCallback(async () => {
    await Promise.all([
      (async () => {
        try {
          await initStudent(`/students/${studentId}`);
        } catch (erro: any) {
          if (erro?.request?.status === 404) {
            setErr('not_found');
          } else {
            setErr('unknown');
          }
        }
      })(),
      (async () => {
        try {
          await initClassroom(`/classrooms/${classroomId}`);
        } catch (erro: any) {
          if (erro?.request?.status === 404) {
            setErr('not_found');
          } else {
            setErr('unknown');
          }
        }
      })(),
    ]);
  }, [classroomId, initClassroom, initStudent, studentId]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const goToClassroom = useCallback(() => navigate(`/classrooms/${classroom?.id}`), [classroom?.id, navigate]);

  // RENDER
  if (err) {
    if (err === 'not_found') {
      return (
        <ErrorScreen
          title={'Élève introuvable'}
          description={"Nous n'avons pu trouver cet élève, peut-être a-t-il été supprimé ?"}
          img={imgLost}
        />
      );
    } else {
      return <ErrorScreen />;
    }
  }

  if (!student || !classroom) {
    return <BigLoader />;
  }

  return (
    <Container>
      <Row>
        <Col md={5} lg={4} xl={4} xxl={3}>
          <Row>
            <Col>
              <BackButton onClick={goToClassroom}>Classe "{classroom?.name}"</BackButton>
            </Col>
          </Row>
          <StudentMainCard />
          {classroom &&
            classroom.myAcl.mediaAcl !== 'forbidden' &&
            pathname !== `/classrooms/${classroom?.id}/students/${student?.id}/medias` && (
              <div className={'d-none d-md-block'}>
                <MediasPreviewCard
                  baseUrl={`/media?student=${student?.['@id']}&order[date]=desc`}
                  viewAllLink={`/classrooms/${classroom?.id}/students/${student?.id}/medias`}
                />
              </div>
            )}
        </Col>
        <Col md={7} lg={8} xl={8} xxl={9}>
          <StudentNavBar />
          <StudentRouter />
        </Col>
      </Row>
    </Container>
  );
};

export default StudentConsult;
