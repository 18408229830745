import React, { ComponentType, useRef, useState, useCallback } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import ObservationForm from './form/ObservationForm';
import ButtonWithIcon from './ButtonWithIcon';
import { FormikHelpers } from 'formik';
import { MediaInputItem } from './form/input/MediaInput';
import { MediaType } from 'types';
import HiddenableComponent from 'components/HiddenableComponent';
import { FormRef } from 'helpers/form';
import { ApiError } from 'helpers/apiError';
import styled from 'styled-components';

const CardBody = styled(Card.Body)`
  transition: padding 0.3s ease-in-out;
  &.minimized {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

type ObservationInputProps = {
  onSubmit: (values: any, helpers: FormikHelpers<any>) => Promise<void>;
  onAddMedia: (arg1: MediaInputItem) => Promise<MediaType>;
  onCancel?: () => void;
  error?: ApiError;
};

const ObservationInput: ComponentType<ObservationInputProps> = ({ error, onSubmit, onCancel, onAddMedia }) => {
  // HOOKS
  const formRef = useRef<FormRef>();

  // STATES
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isMinimized, setIsMinimised] = useState<boolean>(true);

  // HANDLERS
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    formRef && formRef.current && (await formRef.current.handleSubmit());
    setIsSubmitting(false);
    setIsMinimised(true);
  }, []);

  const handleCancel = useCallback(async () => {
    setIsMinimised(true);
    formRef && formRef.current && (await formRef.current.handleCancel());
  }, []);

  const handleFocus = useCallback(() => setIsMinimised(false), []);

  return (
    <Row className={'align-items-center justify-content-center'}>
      <Col lg={11} xl={9} xxl={7}>
        <Card
          style={{
            borderRadius: 10,
          }}
        >
          <CardBody className={!error && isMinimized ? 'minimized' : ''}>
            <ObservationForm
              ref={formRef}
              onSubmit={onSubmit}
              onAddMedia={onAddMedia}
              onCancel={onCancel}
              error={error}
              isMinimized={!error && isMinimized}
              onFocus={handleFocus}
            />
            <HiddenableComponent hidden={!error && isMinimized}>
              <Row>
                <Col xs={'auto'} className={'ms-auto me-auto text-center'}>
                  <ButtonWithIcon onClick={handleCancel} disabled={isSubmitting} variant={'link'}>
                    Annuler
                  </ButtonWithIcon>
                  <ButtonWithIcon
                    onClick={handleSubmit}
                    variant={'primary'}
                    isSubmitting={isSubmitting}
                    className={`btn-round`}
                  >
                    Enregistrer
                  </ButtonWithIcon>
                </Col>
              </Row>
            </HiddenableComponent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ObservationInput;
