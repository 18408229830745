import React, { ComponentType } from 'react';
import { Row, Col } from 'react-bootstrap';

type FormControlWithIconProps = {
  icon: JSX.Element;
};

const FormControlWithIcon: ComponentType<FormControlWithIconProps> = ({ icon, children }) => {
  return (
    <Row className={'g-0 align-items-center'}>
      <Col xs={'auto'} className={'ms-1 me-3'}>
        {icon}
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default FormControlWithIcon;
