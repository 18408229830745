import React, { useMemo, useRef, forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import { ObservationType, MediaType } from 'types';
import ObservationTypeInput from './input/ObservationTypeInput';
import ObservationsMediaInput, { MediaInputItem, MediaInputPreview } from './input/MediaInput';
import moment from 'moment';
import FormError from './FormError';
import LoadingOverlay from 'components/LoadingOverlay';
import styled from 'styled-components';
import HiddenableComponent from 'components/HiddenableComponent';
import { useCurrentClassroom } from 'hooks';
import { ObservationCategory } from 'types/ObservationType';
import { ApiError } from 'helpers/apiError';

const SMainInput = styled.textarea`
  &.form-control {
    transition: all 0.3s ease-in-out;
    max-height: 200px;
    height: 7rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &.minized {
      border: 1px solid transparent;
      height: 3rem;
    }
  }
`;

const FormInitValues: {
  type: string;
  content: string;
  medias: MediaInputItem[];
  date?: string | undefined;
} = {
  type: ObservationCategory.behaviour,
  content: '',
  medias: [],
  // date: moment().local().format('YYYY-MM-DDTHH:mm'),
};

type FormValues = {
  type: string;
  content: string;
  medias: MediaInputItem[];
  date?: string | undefined;
};

export const ObservationSchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(ObservationCategory)),
  content: Yup.string()
    .trim()
    .min(1, 'Doit contenir au moins 1 caractère')
    .max(2048, 'Doit contenir au maximum 2048 caractères')
    .required('Ne peut être vide'),
  date: Yup.date(),
});

type ObservationFormProps = {
  entity?: ObservationType;
  onSubmit: (values: any, helpers: FormikHelpers<FormValues>) => Promise<void>;
  onDelete?: () => void;
  error?: ApiError | undefined;
  onAddMedia: (arg1: MediaInputItem) => Promise<MediaType>;
  onCancel?: () => void;
  onFocus?: () => void;
  isMinimized: boolean;
};

const ObservationForm = forwardRef(
  (
    { entity, onSubmit, error, onAddMedia, onCancel, onFocus, onDelete, isMinimized }: ObservationFormProps,
    ref: any
  ) => {
    // REF
    useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        formRef && formRef.current && (await formRef.current.submitForm());
      },
      handleCancel: async () => {
        handleCancel();
      },
    }));

    // HOOKS
    const formRef = useRef<any | null>(null);
    const contentInput = useRef<any>(null);
    const [classroom] = useCurrentClassroom();

    // STATES
    const [dateNow, setDateNow] = useState<string | undefined>();

    // EFFECTS
    useEffect(() => {
      setDateNow(moment().local().format('YYYY-MM-DDTHH:mm'));
    }, []);

    // HANDLERS
    const handleCancel = useCallback(async () => {
      formRef.current.resetForm();
      onCancel && (await onCancel());
    }, [onCancel]);

    const handleSubmit = useCallback(
      async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        // Transform data
        // post medias
        const mediaIris = [];
        for (let i = 0; i < values.medias.length; i++) {
          const media = values.medias[i];
          // ignore old medias
          if (media.iri) {
            mediaIris.push(media.iri);
            continue;
          }
          if (media.blob) {
            const mediaFromApi = await onAddMedia(media);
            mediaIris.push(mediaFromApi?.['@id']);
          }
        }

        await onSubmit({ ...values, medias: mediaIris, date: moment(values.date).format() }, helpers);
      },
      [onAddMedia, onSubmit]
    );

    // MEMO
    const initvalues = useMemo(() => {
      // medias
      const medias: MediaInputItem[] = [];
      if (entity) {
        entity.medias.map((media) =>
          medias.push({
            iri: media['@id'],
            thumbnail: media.file.thumbnail,
          })
        );
      }

      return {
        ...FormInitValues,
        ...(entity
          ? {
              content: entity.content,
              type: entity.type,
              date: moment(entity.date).local().format('YYYY-MM-DDTHH:mm'),
              medias: medias,
            }
          : {}),
      };
    }, [entity]);

    return (
      <Formik
        innerRef={(ref) => {
          formRef.current = ref;
        }}
        onSubmit={handleSubmit}
        initialValues={initvalues}
        validationSchema={ObservationSchema}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
          handleBlur,
          setFieldValue,
          setFieldError,
          submitCount,
          resetForm,
        }: FormikProps<FormValues>) => (
          <>
            <Form onSubmit={handleSubmit}>
              {error && <FormError error={error} />}
              <LoadingOverlay visible={isSubmitting} />
              <HiddenableComponent hidden={isMinimized} style={{ height: '2.6rem' }}>
                <ObservationTypeInput
                  value={values.type}
                  onChange={(val) => {
                    setFieldValue('type', val);
                    contentInput.current.focus();
                  }}
                />
              </HiddenableComponent>

              <Form.Group className={!isMinimized ? 'mb-3' : ''}>
                <Form.Control
                  ref={contentInput}
                  as={SMainInput}
                  type="text"
                  name="content"
                  value={values.content}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={submitCount > 0 && !!errors.content}
                  placeholder={'Saisissez une note ...'}
                  onFocus={() => {
                    onFocus && onFocus();
                  }}
                  className={isMinimized ? 'minized' : ''}
                />
                <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
              </Form.Group>
              <HiddenableComponent hidden={isMinimized}>
                <Row className="g-0">
                  {classroom.myAcl.mediaAcl === 'full-access' && (
                    <Form.Group as={Col} xs={'auto'}>
                      <ObservationsMediaInput
                        value={values.medias}
                        onChange={(val) => setFieldValue('medias', val)}
                        onError={(err) => setFieldError('medias', err)}
                      />
                      <Form.Control.Feedback type="invalid">{errors.medias}</Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group as={Col}>
                    <Form.Control
                      type="datetime-local"
                      name="date"
                      value={values.date ?? dateNow}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={submitCount > 0 && !!errors.date}
                      placeholder={'maintenant'}
                    />
                    <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </HiddenableComponent>
              {classroom.myAcl.mediaAcl === 'full-access' && (
                <Row className={!isMinimized ? 'my-2' : ''}>
                  <Col>
                    <MediaInputPreview value={values.medias} onChange={(val) => setFieldValue('medias', val)} />
                  </Col>
                </Row>
              )}
            </Form>
          </>
        )}
      </Formik>
    );
  }
);

export default ObservationForm;
