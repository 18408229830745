import compose from 'hoc';

import { withAuthentication } from './useAuthentication';
import { withConnectedUser } from './useConnectedUser';
import { withConfirm } from './useConfirm';
import { withCurrentStudent } from './useCurrentStudent';
import { withGlobal } from './useGlobal';
import { withCurrentClassroom } from './useCurrentClassroom';
import { withQuery } from './useQuery';
import { withToast } from './useToast';
import { withUploadBox } from './useUploadBox';

export { default as useConfirm } from './useConfirm';
export { default as useQuery } from './useQuery';
export { default as useConnectedUser } from './useConnectedUser';
export { default as useAuthentication } from './useAuthentication';
export { default as useCurrentStudent } from './useCurrentStudent';
export { default as useGlobal } from './useGlobal';
export { default as useCurrentClassroom } from './useCurrentClassroom';
export { default as useToast } from './useToast';
export { default as useUploadBox } from './useUploadBox';

export const withCommonHookProviders = compose(
  withUploadBox,
  withToast,
  withConfirm,
  withAuthentication,
  withConnectedUser,
  withGlobal,
  withCurrentClassroom,
  withCurrentStudent
);

export const withRouterHooksProviders = compose(withQuery);
