import React, { ComponentType, useMemo } from 'react';

type EmptyListMessageProps = {
  size?: 'sm' | 'md' | 'lg';
};

const EmptyListMessage: ComponentType<EmptyListMessageProps> = ({ children, size }) => {
  const sizeClass = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'my-3';
      case 'lg':
        return 'my-5';
      default:
        return 'my-4';
    }
  }, [size]);

  return (
    <>
      <p className={`text-center text-muted ${sizeClass}`}>{children ?? 'Rien à afficher pour le moment.'}</p>
    </>
  );
};

export default EmptyListMessage;
