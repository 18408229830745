import React, { forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { ButtonProps } from 'react-bootstrap';

type ButtonWithIconProps = ButtonProps & {
  type?: 'button' | 'reset' | 'submit';
  icon?: JSX.Element;
  isSubmitting?: boolean;
  submittingText?: string;
  children?: any;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  size?: 'sm' | 'lg';
};

const ButtonWithIcon = forwardRef(
  (
    {
      icon,
      isSubmitting,
      submittingText,
      children,
      type,
      variant,
      disabled,
      onClick,
      className,
      size,
      style,
      ...props
    }: ButtonWithIconProps,
    ref
  ) => {
    // REF
    useImperativeHandle(ref, () => ({
      click: handleClick,
    }));

    // HOOKS
    const btnRef = useRef<any | null>(null);

    // HANDLERS
    const handleClick = useCallback(() => {
      btnRef && btnRef.current && btnRef.current.click();
    }, []);

    return (
      <Button
        ref={btnRef}
        type={type ?? 'button'}
        variant={variant}
        disabled={disabled || isSubmitting}
        onClick={onClick}
        className={className}
        size={size}
        style={style}
        {...props}
      >
        {(isSubmitting && (
          <>
            <span className={'me-2 d-inline-block'} style={{ marginTop: -3 }}>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            </span>
            {submittingText ?? children}
          </>
        )) || (
          <>
            {icon}
            <span className={icon ? 'ms-2' : ''}>{children}</span>
          </>
        )}
      </Button>
    );
  }
);

export default ButtonWithIcon;
