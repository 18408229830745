import React, { ComponentType, useCallback, useEffect } from 'react';
import ClassroomList from './components/ClassroomList';
import { useGlobal, useCurrentClassroom } from 'hooks';
import { Row, Col, Container } from 'react-bootstrap';
import BlockQuote from './components/BlockQuote';
import { RiQuestionFill } from 'react-icons/ri';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import BigLoader from 'components/BigLoader';

const HelpButton = styled.div`
  border-radius: 10px;
  width: 320px;
  padding: 6px 15px;
  font-weight: 600;
  font-size: 15px;

  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.07);

  transition: 0.2s;
  transform: scale(0.95);

  &:hover {
    cursor: pointer;
    transform: scale(1);
  }

  .left-icon {
    font-size: 32px;
    margin-right: 13px;
  }

  small {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
  }
`;

const Dashboard: ComponentType = () => {
  /**
   * HOOKS
   */
  const [, , { cleanup: cleanupClassroom }] = useCurrentClassroom();
  const [{ classrooms, quote }, { refreshClassrooms, refreshQuote }] = useGlobal();
  const navigate = useNavigate();

  /**
   * REFRESH
   */
  const handleRefresh = useCallback(async () => {
    try {
      await Promise.all([refreshClassrooms(), refreshQuote()]);
    } catch (error) {
      console.error('unable to refresh data', error);
    }
  }, [refreshClassrooms, refreshQuote]);
  useEffect(() => {
    handleRefresh();
    cleanupClassroom();
  }, [cleanupClassroom, handleRefresh]);

  if (!classrooms) {
    return <BigLoader />;
  }

  return (
    <>
      <BlockQuote quote={quote} />
      <Container>
        <Row className={'justify-content-center'}>
          <Col lg={10} xl={8}>
            <ClassroomList classrooms={classrooms} />
          </Col>
        </Row>

        <Row className={'justify-content-center mt-5'}>
          <Col xs={'auto'}>
            <HelpButton onClick={() => navigate(`/help`)}>
              <Row className={'align-items-center g-0'}>
                <Col xs={'auto'} className={'left-icon text-primary'}>
                  <RiQuestionFill />
                </Col>
                <Col>
                  <div>
                    <small>Un problème ? Une suggestion ?</small>
                  </div>
                  Contactez nous
                </Col>
              </Row>
            </HelpButton>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
