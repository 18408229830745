/* eslint-disable */
type HOC = <T>(Component: T) => T;

const compose = (...fns: Function[]) =>
  fns.reduce(
    (f, g) =>
      (...args: unknown[]) =>
        f(g(...args))
  ) as HOC;

export default compose;
