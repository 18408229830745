import React, { ComponentType, useState, useCallback, useMemo } from 'react';
import { useNavigate, Link, useLocation, Navigate } from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { useConnectedUser } from 'hooks';
import LogInForm from './components/LogInForm';
import { Card, Row, Col, Container } from 'react-bootstrap';
import useApiClient from 'hooks/useApiClient';
import iconImg from 'assets/icon.png';
import styled from 'styled-components';
import BigLoader from 'components/BigLoader';

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 75px;
  width: 75px;
  border-radius: 15px;
  display: block;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
  }
`;

const SignIn: ComponentType = () => {
  // HOOKS
  const client = useApiClient();
  const [, { setToken }] = useAuthentication();
  const navigate = useNavigate();
  const [user, , userLoaded] = useConnectedUser();
  const location: any = useLocation();

  // STATES
  const [errorLogIn, setErrorLogIn] = useState<ApiError | undefined>();

  // MEMO
  const redirectTarget = useMemo(() => {
    let { redirectTo } = location.state || { from: '/dashboard' };
    if (!redirectTo || (typeof redirectTo === 'string' && redirectTo.startsWith(`/signin`))) {
      redirectTo = '/dashboard';
    }
    return redirectTo;
  }, [location.state]);

  // HANDLERS
  const handleLogIn = useCallback(
    async (values, helpers) => {
      try {
        // get jwt token
        const resp = await client.post('/token', {
          email: values.email,
          password: values.password,
        });

        setToken({
          token: resp.data.token,
          refreshToken: resp.data.refresh_token,
          vaultSecret: resp.data.vault_secret,
        });

        // navigate(redirectTarget);
      } catch (err) {
        if (err?.response?.status === 423) {
          navigate(`/confirm_account?email=${values.email}`);
        } else {
          setErrorLogIn(parseFormApiError(err, helpers));
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [client, setToken, navigate]
  );

  if (!userLoaded) {
    return <BigLoader />;
  }

  if (user) {
    return <Navigate to={redirectTarget ?? '/dashboard'} replace />;
  }

  return (
    <Container className={'marginauto'}>
      <Row className={'pt-5'}>
        <Col xxl={4} xl={5} lg={6} md={8} sm={10} className={'ms-auto me-auto'}>
          <Logo src={iconImg} onClick={() => navigate('/')} />

          <h4 className={'text-center mb-4'}>Bienvenue sur elevapp</h4>
          <Card>
            <Card.Body className={'p-4'}>
              <LogInForm onSubmit={handleLogIn} error={errorLogIn} />
            </Card.Body>
          </Card>
          <p className={'text-center text-muted'}>
            <>
              Vous n'avez pas de compte ? <Link to={`/register`}>Inscrivez-vous</Link>
            </>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
