import AppAvatar from 'components/AppAvatar';
import EditButton from 'components/EditButton';
import FormModal from 'components/modal/FormModal';
import UserAvatar from 'components/UserAvatar';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import useEntities from 'hooks/useEntities';
import React, { ComponentType, useCallback, useState } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AclType } from 'types';
import { aclToColor, aclToString, resolvAclDescription, resolvAclName } from 'types/AclType';
import AclForm from 'views/ClassroomConsult/views/Acls/components/AclForm';

const AclUserName = styled.h5`
  font-weight: 600;
  font-size: 1.1em;
`;

const MoreButton = styled.div`
  font-size: 0.85em;
  &:hover {
    cursor: pointer;
  }
`;

const SAclType = styled.h6`
  font-size: 0.92em;
  font-weight: 600;
  color: #666;
  margin-top: 0;
  margin-bottom: 0;
`;

const AclDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 0.8em;
`;

type AclItemProps = {
  acl: AclType;
  onEdit: (entity: any) => void;
  onDelete: (id: string) => void;
};

const AclItem: ComponentType<AclItemProps> = ({ acl, onDelete, onEdit }) => {
  /**
   * HOOKS
   */
  const { editEntity, deleteEntity } = useEntities();

  /**
   * STATES
   */
  const [editVisible, setEditVisible] = useState(false);
  const [editError, setEditError] = useState<ApiError | undefined>();
  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  /**
   * HANDLERS
   */
  const handleEdit = useCallback(
    async (values, helpers) => {
      try {
        const newAcl = await editEntity(acl['@id'], values);
        onEdit(newAcl);
        setEditVisible(false);
        helpers.resetForm();
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [editEntity, acl, onEdit]
  );

  const handleDelete = useCallback(async () => {
    await deleteEntity(`${acl?.['@id']}`);
    onDelete(acl.id);
  }, [deleteEntity, acl, onDelete]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col className={'my-auto'}>
            <AclUserName>
              {!acl.acceptedAt && (
                <>
                  <AppAvatar size={24} shape={'round'} style={{ marginRight: 8 }} alt={'?'} /> {acl.user.email}{' '}
                  <small className={'text-muted'}>(invitation en attente)</small>
                </>
              )}
              {acl.acceptedAt && (
                <>
                  <UserAvatar size={24} shape={'round'} user={acl.user} style={{ marginRight: 8 }} />{' '}
                  {acl.user.givenName} {acl.user.familyName}
                </>
              )}
            </AclUserName>
          </Col>
          {onEdit && (
            <Col xs={'auto'} className={'ms-auto d-flex'}>
              <EditButton size={'sm'} onClick={() => setEditVisible(true)} />
            </Col>
          )}
        </Row>

        <Row>
          {['classroomAcl', 'studentAcl', 'observationAcl', 'mediaAcl'].map((a: string) => (
            <Col sm={'6'} className={'my-1'} key={a}>
              <Row className={'align-items-center'}>
                <Col>
                  <SAclType>{resolvAclName(a)}</SAclType>
                </Col>
                <Col xs={'auto'} className={'ms-auto'}>
                  <Badge bg={aclToColor(acl[a])}>{aclToString(acl[a])}</Badge>
                </Col>
              </Row>
              {detailVisible && <AclDescription className={'text-muted'}>{resolvAclDescription(a)}</AclDescription>}
            </Col>
          ))}
        </Row>

        <MoreButton className={'text-muted text-center mt-3'} onClick={() => setDetailVisible(!detailVisible)}>
          {!detailVisible ? 'Afficher les details' : 'Masquer les details'}
        </MoreButton>

        <FormModal
          title={'Modifier le partage'}
          show={editVisible}
          onSubmit={handleEdit}
          onDelete={handleDelete}
          error={editError}
          entity={acl}
          onClose={() => {
            setEditVisible(false);
            setEditError(undefined);
          }}
          form={(ref, onSubmit, error, entity) => (
            <AclForm ref={ref} onSubmit={onSubmit} entity={entity} error={error} />
          )}
          modalVisibilityHandler={setEditVisible}
        />
      </Card.Body>
    </Card>
  );
};

export default AclItem;
