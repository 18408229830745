import FormError from 'components/form/FormError';
import Loader from 'components/Loader';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { useQuery, useToast } from 'hooks';
import useApiClient from 'hooks/useApiClient';
import React, { ComponentType, useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const ClassroomInvitationConfirm: ComponentType = ({ children }) => {
  /**
   * HOOKS
   */
  const client = useApiClient();
  const navigate = useNavigate();
  const query = useQuery();
  const [{ toast }] = useToast();

  /**
   * STATES
   */
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<{ classroomName: string; classroomOwner: string } | undefined>();
  const [error, setError] = useState<ApiError | undefined>();

  /**
   * HANDLERS
   */
  const handleRefresh = useCallback(async () => {
    try {
      const resp = await client.get(`/classroom_invitations/${query.get('id')}`);
      setInvitation(resp.data);
    } catch (err) {
      setError(parseFormApiError(err));
    }
  }, [client, query]);

  const handleAnswer = useCallback(
    async (action: 'accept' | 'reject') => {
      setSubmitting(true);
      try {
        await client.post(`/classroom_invitations/${query.get('id')}/${action}`, {
          invitationSecret: query.get('secret'),
        });

        navigate(`/dashboard`);

        if (action === 'accept') {
          toast({
            type: 'success',
            title: 'Invitation acceptée',
            message: `Vous avez désormais accès à ${invitation.classroomName}`,
          });
        }
      } catch (err) {
        console.error(err);
        toast({
          type: 'danger',
          title: 'Erreur',
          message: 'Invitation invalide : ' + parseFormApiError(err).message,
        });
        navigate(`/`);
      } finally {
        setSubmitting(false);
      }
    },
    [client, query, navigate, toast, invitation?.classroomName]
  );

  /**
   * EFFECTS
   */
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <Container className={'marginauto'}>
      <Row className={'align-items-center'}>
        <Col xs={'auto'} md={8} lg={6} xl={5} className={'ms-auto me-auto'}>
          <Card className={'ms-auto me-auto'}>
            <Card.Body>
              <Card.Title>Partage de classe</Card.Title>
              {!error && !invitation && <Loader />}
              {!error && invitation && (
                <>
                  <Card.Text>
                    Souhaitez-vous accepter l'invitation pour accéder à la classe <b>{invitation.classroomName}</b> de{' '}
                    <b>{invitation.classroomOwner}</b> ?
                  </Card.Text>
                  <div className={'text-center'}>
                    <Button disabled={submitting} variant="secondary" onClick={() => handleAnswer('reject')}>
                      Refuser
                    </Button>
                    <Button
                      disabled={submitting}
                      variant="primary"
                      className={'ms-4'}
                      onClick={() => handleAnswer('accept')}
                    >
                      Accepter
                    </Button>
                  </div>
                </>
              )}
              {error && (
                <>
                  <FormError error={error} />
                  <div className={'text-center'}>
                    <Card.Text>
                      <Button variant={'secondary'} onClick={() => navigate('/')}>
                        Retour à l'acceuil
                      </Button>
                    </Card.Text>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ClassroomInvitationConfirm;
