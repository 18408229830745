import React, { ComponentType } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonWithIcon from './ButtonWithIcon';

type PartTitleProps = {
  title: string | JSX.Element;
  showButton?: boolean;
  buttonTitle?: string | JSX.Element;
  onClick?: () => void;
  as?: React.ElementType;
  className?: string;
};

const PartTitle: ComponentType<PartTitleProps> = ({
  title,
  as,
  className,
  showButton,
  buttonTitle,
  onClick,
  children,
}) => {
  const As = as ?? 'h6';

  return (
    <>
      <Row className={className ? className : `my-3`}>
        <Col className={'d-flex align-items-center'}>
          <As className={'my-0'}>{title}</As>
        </Col>
        {showButton && (
          <Col xs={'auto'} className={'ms-auto'}>
            <ButtonWithIcon
              variant={'secondary'}
              size={'sm'}
              onClick={onClick}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {buttonTitle ?? 'Ajouter'}
            </ButtonWithIcon>
          </Col>
        )}
      </Row>
    </>
  );
};

export default PartTitle;
