import React, { ComponentType, useMemo } from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, FormikProps } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import FormError from 'components/form/FormError';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { useConnectedUser } from 'hooks';
import Loader from 'components/Loader';
import { ApiError } from 'helpers/apiError';

const FormInitValues = {
  name: '',
  email: '',
  message: '',
};

type FormValues = typeof FormInitValues;

const FormSchema = Yup.object().shape({
  name: Yup.string().trim().max(255, 'Doit contenir au maximum 255 caractères').required('Ne peut être vide'),
  email: Yup.string().email('Doit être un email valide').required('Ne peut être vide'),
  message: Yup.string().max(1000, 'Doit contenir au maximum 1000 caractères').required('Ne peut être vide'),
});

type HelpFormProps = {
  onSubmit: (arg1: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  error?: ApiError | undefined;
};

const HelpForm: ComponentType<HelpFormProps> = ({ onSubmit, error }) => {
  // HOOKS
  const [user, , isUserLoaded] = useConnectedUser();

  const initValues = useMemo(() => {
    if (!user) {
      return FormInitValues;
    }
    return {
      ...FormInitValues,
      ...(isUserLoaded && user
        ? {
            name: `${user.givenName} ${user.familyName}`,
            email: `${user.email}`,
          }
        : {}),
    };
  }, [isUserLoaded, user]);

  if (!isUserLoaded) {
    return <Loader />;
  }

  return (
    <Formik
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      validationSchema={FormSchema}
      enableReinitialize
      initialValues={initValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldError,
        setFieldValue,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}
          <Row className="mb-2">
            <Form.Group as={Col} md={6}>
              <Form.Label>Votre nom</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && !!errors.name}
                disabled={user ? true : false}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Votre email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
                disabled={user ? true : false}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className={'mb-2'}>
            <Form.Label>Votre message</Form.Label>
            <Form.Control
              as={'textarea'}
              type="text"
              rows={5}
              name="message"
              placeholder={'Votre message'}
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.message && !!errors.message}
            />
            <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col xs={'auto'} className={'ms-auto me-auto'}>
              <ButtonWithIcon className={'btn-round'} type={'submit'} variant={'primary'} isSubmitting={isSubmitting}>
                Envoyer
              </ButtonWithIcon>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default HelpForm;
