import React, { ComponentType, useCallback, useState } from 'react';
import useApiClient from 'hooks/useApiClient';
import { useNavigate } from 'react-router';
import Loader from 'components/Loader';
import { useQuery, useToast } from 'hooks';
import { Row, Col, Card, Container } from 'react-bootstrap';
import ConfirmIdentity from 'components/ConfirmIdentity';
import { parseFormApiError } from 'helpers/apiError';

const ConfirmEmail: ComponentType = () => {
  // HOOKS
  const client = useApiClient();
  const navigate = useNavigate();
  const [{ toast }] = useToast();
  const query = useQuery();

  // STATES
  const [step, setStep] = useState(0);
  const [, setError] = useState<Error | undefined>();

  const handleIdentityConfirmed = useCallback(
    (identityToken: string) => {
      setStep(1);
      (async () => {
        try {
          await client.post('/confirm_email', {
            identityRequestToken: identityToken,
          });
          toast({
            type: 'success',
            title: 'Email validé',
            message: 'Vous pouvez désormais vous connecter',
          });
          navigate(`/signin`);
        } catch (err) {
          setError(new Error(parseFormApiError(err).message));
        }
      })();
    },
    [toast, client, navigate]
  );

  return (
    <Container className={'marginauto'}>
      <Row className={'mt-5'}>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Body>
              {step === 0 && (
                <>
                  <Card.Title>Vérification d'identité</Card.Title>
                  <ConfirmIdentity emailProp={query.get('email')} onSuccess={handleIdentityConfirmed} />
                </>
              )}
              {step === 1 && <Loader />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmEmail;
