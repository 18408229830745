import React, { forwardRef, useRef, useImperativeHandle, useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, Col, Row, Spinner } from 'react-bootstrap';
import { ClassroomType, StudentType } from 'types';
import { useEffect } from 'react';
import useEntities from 'hooks/useEntities';
import { useCallback } from 'react';

const FormInitValues = {
  studentIds: undefined,
};

type FormValues = {
  studentIds: string[];
};

type StudentImportFormProps = {
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  onCancel?: () => void;
};

const StudentImportForm = forwardRef(({ onSubmit, onCancel }: StudentImportFormProps, ref: any) => {
  /**
   * REF
   */
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
  }));

  /**
   * HOOKS
   */
  const formRef = useRef<any | null>(null);
  const { fetchAll } = useEntities();

  /**
   * STATES
   */
  const [classrooms, setClassrooms] = useState<ClassroomType[] | undefined>();
  const [students, setStudents] = useState<StudentType[] | undefined>();
  const [selectedClassroom, setSelectedClassroom] = useState<string | undefined>();

  /**
   * HANDLERS
   */
  const handleRefreshClassrooms = useCallback(async () => {
    const classrooms = await fetchAll(`/classrooms?order[name]=ASC`);
    setClassrooms(classrooms);
  }, [fetchAll]);

  const handleRefreshStudents = useCallback(async () => {
    setStudents(undefined);
    if (selectedClassroom) {
      const classrooms = await fetchAll(`/students?classroom=${selectedClassroom}`);
      setStudents(classrooms);
    }
  }, [fetchAll, selectedClassroom]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    handleRefreshClassrooms();
  }, [handleRefreshClassrooms]);

  useEffect(() => {
    handleRefreshStudents();
  }, [handleRefreshStudents, selectedClassroom]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={FormInitValues}
      enableReinitialize
      innerRef={(ref) => {
        formRef.current = ref;
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldError,
        setFieldValue,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          <p className={'text-muted'}>
            <small>
              Cette action permet de dupliquer un élève (informations et parents) dans cette classe.
              <br />
              <u>Attention</u> : cela ne concerne pas les observations et media.
            </small>
          </p>
          <Form.Group>
            <Form.Label>Classe</Form.Label>
            <Row>
              <Col>
                <Form.Select
                  onChange={(e) => setSelectedClassroom(e.target.value)}
                  placeholder={'Séléctionnez une classe'}
                  value={selectedClassroom}
                >
                  <option value={undefined}>Choisissez une classe</option>
                  {classrooms?.map((classroom) => (
                    <option key={classroom['@id']} value={classroom.id}>
                      {classroom.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={'2'}>{!classrooms && <Spinner animation={'border'} size={'sm'} />}</Col>
            </Row>

            <Form.Label>Élèves</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  name={'studentIds'}
                  as="select"
                  disabled={!selectedClassroom}
                  multiple
                  onChange={handleChange}
                  value={values.studentIds}
                  htmlSize={10}
                >
                  {students
                    ?.sort((a: StudentType, b: StudentType) => {
                      const valueA = `${a.familyName} ${a.givenName}`;
                      const valueB = `${b.familyName} ${b.givenName}`;
                      if (valueA === valueB) {
                        return 0;
                      }
                      return valueA < valueB ? -1 : 1;
                    })
                    .map((student) => (
                      <option key={student['@id']} value={student.id}>
                        {student.givenName} {student.familyName}
                      </option>
                    ))}
                </Form.Control>
              </Col>
              <Col xs={'2'}>{selectedClassroom && !students && <Spinner animation={'border'} size={'sm'} />}</Col>
            </Row>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

export default StudentImportForm;
