import React, { ComponentType } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type ErrorScreenProps = {
  title?: string;
  description?: string;
  img?: any;
};

const ErrorScreen: ComponentType<ErrorScreenProps> = ({ title, description, img }) => {
  return (
    <Container className={'marginauto'}>
      <Row className={'align-items-center'}>
        <Col className={'text-center'}>
          {img && (
            <div className={'mb-3'}>
              <Image src={img} style={{ width: 400 }} />
            </div>
          )}
          <h2 className={'my-3'}>{title ?? 'Une erreur est survenue :('}</h2>
          <p>{description ?? 'Nous ne sommes pas en capacité de vous afficher la page demandée pour le moment.'}</p>
          <p>
            <Link to={'/'}>Retour à l'accueil</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorScreen;
