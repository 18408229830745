import React, { ComponentType, useState, useCallback } from 'react';
import { Col, Row, Button, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from 'hooks';
import useEntities from 'hooks/useEntities';
import styled from 'styled-components';

const ViewMoreOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
  font-size: 1.3em;
  font-weight: 700;
  padding-top: calc(50% - 1rem);
  transition: background-color 0.3s;
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

const SImage = styled.div`
  position: relative;
  width: calc(100% - 0.5rem);
  height: calc(100% - 0.5rem);
  max-width: 140px;
  aspect-ratio: 1/1;
  &:hover {
    cursor: pointer;
  }
  overflow: hidden;
  border-radius: 7px;
  background-color: #dcdcdc;

  margin: 0.25rem;

  img {
    width: 100%;
  }
`;

export type MediaGalleryItem = {
  ['@id']: string;
  id: string;
  date: string;
  file: {
    regular: string;
    thumbnail: string;
  };
};

type MediaGalleryProps = {
  entities: MediaGalleryItem[];
  onDelete?: (arg: string) => void;
  caption?: (arg: any) => JSX.Element;
  previewLimit?: number;
  xs?: any;
  sm?: any;
  md?: any;
  lg?: any;
  xl?: any;
  xxl?: any;
};

const MediaGallery: ComponentType<MediaGalleryProps> = ({
  entities,
  onDelete,
  caption,
  previewLimit,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  // STATES
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [, setConfirm] = useConfirm();
  const { deleteEntity } = useEntities();

  // HANDLERS
  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      {entities && (
        <>
          {entities.length > 0 && (
            <Row className={'mx-1 g-0'}>
              {entities &&
                entities.length > 0 &&
                (previewLimit ? entities.slice(0, previewLimit) : entities).map(
                  (media: MediaGalleryItem, mediaIndex: number) => (
                    <Col
                      key={media.id}
                      xs={xs}
                      sm={sm}
                      md={md}
                      lg={lg}
                      xl={xl}
                      xxl={xxl}
                      onClick={() => openLightbox(mediaIndex)}
                    >
                      <SImage>
                        {previewLimit && previewLimit < entities.length && previewLimit === mediaIndex + 1 && (
                          <ViewMoreOverlay>+{entities.length - previewLimit}</ViewMoreOverlay>
                        )}
                        <Image src={media.file.thumbnail} alt="" />
                      </SImage>
                    </Col>
                  )
                )}
            </Row>
          )}
          {viewerIsOpen && entities.length > 0 && (
            <Lightbox
              imageCaption={
                <>
                  <Row>
                    <Col>{caption && caption(entities[currentImage])}</Col>
                    <Col xs={'auto'} className={'ms-auto'}>
                      {onDelete && (
                        <Button
                          size={'sm'}
                          variant={'link'}
                          onClick={() =>
                            setConfirm({
                              body: <>Souhaitez-vous réellement supprimer cette photo ?</>,
                              handleConfirm: async () => {
                                await deleteEntity(`${entities[currentImage]?.['@id']}`);
                                // if is last image go to previous
                                if (currentImage === entities.length - 1) {
                                  setCurrentImage((currentImage + entities.length - 1) % entities.length);
                                }
                                if (entities.length === 1) {
                                  setViewerIsOpen(false);
                                }
                                onDelete && onDelete(entities[currentImage].id);
                              },
                            })
                          }
                        >
                          <FontAwesomeIcon icon={'trash'} color={'white'} />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              }
              mainSrc={entities[currentImage].file.regular}
              nextSrc={entities[(currentImage + 1) % entities.length].file.regular}
              prevSrc={entities[(currentImage + entities.length - 1) % entities.length].file.regular}
              mainSrcThumbnail={entities[currentImage].file.thumbnail}
              nextSrcThumbnail={entities[(currentImage + 1) % entities.length].file.thumbnail}
              prevSrcThumbnail={entities[(currentImage + entities.length - 1) % entities.length].file.thumbnail}
              onCloseRequest={closeLightbox}
              onMovePrevRequest={() =>
                currentImage > 0 && setCurrentImage((currentImage + entities.length - 1) % entities.length)
              }
              onMoveNextRequest={() =>
                currentImage !== entities.length - 1 && setCurrentImage((currentImage + 1) % entities.length)
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default MediaGallery;
