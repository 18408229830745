import React, { useMemo, useImperativeHandle, useRef, forwardRef } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Form, Col, Row } from 'react-bootstrap';
import { StudentGroupType, StudentType } from 'types';
import moment from 'moment';
import AvatarInput from 'components/form/input/AvatarInput';
import FormError from 'components/form/FormError';
import styled from 'styled-components';
import { FiPhone, FiMail, FiMapPin, FiUmbrella } from 'react-icons/fi';
import FormPartTitle from 'components/FormPartTitle';
import FormControlWithIcon from 'components/FormControlWithIcon';
import { ApiError } from 'helpers/apiError';
import InfoBlock from 'components/InfoBlock';

const GenderChoice = styled.div`
  width: 100%;
  color: white;
  background-color: #4aa2d9;
  font-weight: 600;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  font-size: 0.8em;
  transition: 0.3s;
  opacity: 0.5;

  &.active {
    opacity: 1 !important;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const FormInitValues = {
  familyName: '',
  givenName: '',
  gender: 'male',
};

type FormValues = {
  familyName: string;
  givenName: string;
  gender: string;
  comment?: string | undefined;
  email?: string | undefined;
  birthDate?: any | undefined;
  insurance?: string | undefined;
  address?: string | undefined;
  phone?: string | undefined;
  avatarData?: string | undefined;
  group?: string | undefined;
};

const FormSchema = Yup.object().shape({
  familyName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères')
    .required('Ne peut être vide'),
  givenName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères')
    .required('Ne peut être vide'),
  comment: Yup.string()
    .transform((v) => (v === '' ? null : v))
    .max(255, 'Doit contenir au maximum 255 caractères')
    .nullable(),
  email: Yup.string()
    .transform((v) => (v === '' ? null : v))
    .nullable()
    .email('Doit être un email valide')
    .max(255, 'Doit contenir au maximum 255 caractères'),
  gender: Yup.string().oneOf(['male', 'female']).required('Ne peut être vide'),
  birthDate: Yup.string()
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  insurance: Yup.string()
    .transform((v) => (v === '' ? null : v))
    .nullable()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères'),
  phone: Yup.string()
    .transform((v) => (v === '' ? null : v))
    .nullable()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères'),
  address: Yup.string()
    .transform((v) => (v === '' ? null : v))
    .nullable()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères'),
});

type StudentFormProps = {
  entity?: StudentType;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  onCancel?: () => void;
  availableGroups?: StudentGroupType[] | undefined;
  error?: ApiError | undefined;
};

const StudentForm = forwardRef(({ entity, onSubmit, error, onCancel, availableGroups }: StudentFormProps, ref: any) => {
  // REF
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
  }));

  // HOOKS
  const formRef = useRef<any | null>(null);

  // MEMO
  const initvalues = useMemo(() => {
    return {
      ...FormInitValues,
      ...(entity
        ? {
            gender: entity.gender,
            familyName: entity.familyName,
            givenName: entity.givenName,
            birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : undefined,
            comment: entity.comment ?? undefined,
            email: entity.email ?? undefined,
            phone: entity.phone ?? undefined,
            address: entity.address ?? undefined,
            insurance: entity.insurance ?? undefined,
            group: entity.group ?? undefined,
          }
        : {}),
    };
  }, [entity]);

  return (
    <Formik
      innerRef={(ref) => {
        formRef.current = ref;
      }}
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={initvalues}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldError,
        setFieldValue,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}

          <Row className="mb-2">
            <Form.Group as={Col} xs={{ span: 8, offset: 2 }}>
              <Row className={'g-0 mb-2'}>
                <Col className={'mx-1'}>
                  <GenderChoice
                    className={values.gender === 'male' ? 'active' : ''}
                    onClick={() => setFieldValue('gender', 'male')}
                  >
                    Garçon
                  </GenderChoice>
                </Col>
                <Col className={'mx-1'}>
                  <GenderChoice
                    className={values.gender === 'female' ? 'active' : ''}
                    onClick={() => setFieldValue('gender', 'female')}
                  >
                    Fille
                  </GenderChoice>
                </Col>
              </Row>
            </Form.Group>
          </Row>

          <Row className={'align-items-center mb-2'}>
            <Form.Group as={Col} xs={'auto'} className={'me-3'}>
              <div className={'ms-auto me-auto'}>
                <AvatarInput
                  alt={`${values.givenName.substr(0, 1)}${values.familyName.substr(0, 1)}`}
                  value={entity?.avatar && entity.avatar.thumbnail}
                  onChange={(val) => setFieldValue('avatarData', val)}
                  onError={(err) => {
                    setFieldError('avatarData', err);
                  }}
                  isInvalid={!!errors.avatarData}
                />
              </div>
              <Form.Control.Feedback type="invalid">{errors.avatarData}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className={'align-items-center'}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="givenName"
                  value={values.givenName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.givenName && !!errors.givenName}
                  placeholder={'Prénom'}
                />
                <Form.Control.Feedback type="invalid">{errors.givenName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="familyName"
                  value={values.familyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.familyName && !!errors.familyName}
                  placeholder={'Nom de famille'}
                />
                <Form.Control.Feedback type="invalid">{errors.familyName}</Form.Control.Feedback>
              </Form.Group>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Date de naissance</Form.Label>
                <Form.Control
                  type="date"
                  name="birthDate"
                  value={values.birthDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.birthDate && !!errors.birthDate}
                />
                <Form.Control.Feedback type="invalid">{errors.birthDate}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            {availableGroups && availableGroups.length > 0 && (
              <Col>
                <Form.Group>
                  <Form.Label>Groupe</Form.Label>
                  <Form.Select
                    name="group"
                    value={values.group}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.group && !!errors.group}
                  >
                    <option value={''}>Aucun</option>
                    {availableGroups
                      .sort((a: StudentGroupType, b: StudentGroupType) => a.name.localeCompare(b.name))
                      .map((item) => (
                        <option key={item['@id']} value={item['@id']}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.group}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Form.Group>
            <Form.Label>Commentaire</Form.Label>
            <Form.Control
              as={'textarea'}
              rows={2}
              type="text"
              name="comment"
              value={values.comment}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.comment && !!errors.comment}
            />
            <Form.Control.Feedback type="invalid">{errors.comment}</Form.Control.Feedback>
          </Form.Group>
          <FormPartTitle>Coordonnées</FormPartTitle>
          <InfoBlock className={'mb-3 mx-3'}>
            Concerne uniquement les <b>coordonnées de l'élève</b>.
            <br />
            Pour ajouter celles des parents, rendez-vous dans l'onglet "Famille".
          </InfoBlock>
          <Form.Group className="mb-2">
            <FormControlWithIcon icon={<FiPhone />}>
              <Form.Control
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phone && !!errors.phone}
                placeholder={'Numéro de téléphone'}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <FormControlWithIcon icon={<FiMail />}>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
                placeholder={'Adresse email'}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <FormControlWithIcon icon={<FiMapPin />}>
              <Form.Control
                rows={3}
                as={'textarea'}
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.address && !!errors.address}
                placeholder={'Adresse\nprincipale'}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label style={{ marginLeft: 40 }}>Assurance</Form.Label>
            <FormControlWithIcon icon={<FiUmbrella />}>
              <Form.Control
                rows={3}
                as={'textarea'}
                type="text"
                name="insurance"
                value={values.insurance}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Coordonnées\nde l'assureur"}
                isInvalid={touched.insurance && !!errors.insurance}
              />
            </FormControlWithIcon>
            <Form.Control.Feedback type="invalid">{errors.insurance}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

export default StudentForm;
