import React, { ComponentType, useState, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProgressiveLoadingComponent, { ProgressiveLoadingProps } from './ProgressiveLoadingComponent';
import { ObservationType } from 'types';
import ObservationItem from './observation/ObservationItem';
import Loader from './Loader';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import LabelDivider from './LabelDivider';
import EmptyMessageWithPicture from './EmptyMessageWithPicture';
import BigLoader from './BigLoader';
import { imgWrite } from 'helpers/illustration';
import { ObservationCategory, resolvObservationType } from 'types/ObservationType';

const CategoryFilter = styled.select`
  background: transparent;
  border: 0;
  padding: 0px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
  margin-right: 30px;
`;

type ObservationListProps = {
  defaultEntities?: any[] | undefined;
  baseURL: string;
  onEdit: (entity: any) => void;
  onDelete: (id: string) => void;
  listRef?: any;
  displaySubject?: boolean;
};

const ObservationList: ComponentType<ObservationListProps> = ({
  baseURL,
  displaySubject,
  listRef,
  onEdit,
  onDelete,
}) => {
  // STATES
  const [typeFilter, setTypeFilter] = useState<string | undefined>();

  // MEMO
  const observationsUrl = useMemo(() => {
    return `${baseURL}${typeFilter ? (typeFilter !== 'all' ? `&type=${typeFilter}` : '') : ''}`;
  }, [baseURL, typeFilter]);

  const observationTypes = useMemo(() => Object.values(ObservationCategory), []);

  return (
    <>
      <Row className={'my-3'}>
        <Col xs={'auto'} className={'ms-auto'}>
          <FontAwesomeIcon icon={'search'} color={'#666'} className={'me-2'} />
          <CategoryFilter
            onChange={(e) => {
              setTypeFilter(e.currentTarget.value);
            }}
          >
            <option value={'all'}>Tout</option>
            {observationTypes.map((item, idx) => (
              <option key={idx} value={item}>
                {resolvObservationType(item)}
              </option>
            ))}
          </CategoryFilter>
        </Col>
      </Row>
      {(observationsUrl && (
        <ProgressiveLoadingComponent
          ref={listRef}
          baseUrl={observationsUrl}
          loaderComponent={<BigLoader />}
          emptyListComponent={
            <EmptyMessageWithPicture
              img={imgWrite}
              title={observationsUrl === baseURL ? 'Prenez des notes' : undefined}
            >
              {observationsUrl === baseURL ? (
                <>
                  <p>
                    Vous retrouverez, ainsi, <strong>facilement</strong> toutes les informations sur vos élèves.
                    <br />
                    <small>(lors du prochain conseil de classe par exemple)</small>
                  </p>
                </>
              ) : (
                <p>Aucune note correspondant aux critères.</p>
              )}
            </EmptyMessageWithPicture>
          }
          autoLoadOnScroll
        >
          {({ entities }: ProgressiveLoadingProps) => {
            const displayedChapters = [];
            return (
              <>
                {entities && entities.length > 0 && (
                  <>
                    {entities.map((observation: ObservationType, idx: number) => {
                      // check if necessary to add header
                      let needToDisplayChapter = false;
                      const chapter = moment(observation.date).format('MMMM YYYY').toUpperCase();
                      if (!displayedChapters.includes(chapter)) {
                        if (idx > 0) {
                          needToDisplayChapter = true;
                        }
                        displayedChapters.push(chapter);
                      }

                      return (
                        <div key={observation.id}>
                          {needToDisplayChapter && <LabelDivider>{chapter}</LabelDivider>}
                          <ObservationItem
                            observation={observation}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            displaySubject={displaySubject}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            );
          }}
        </ProgressiveLoadingComponent>
      )) || <Loader />}
    </>
  );
};

export default ObservationList;
