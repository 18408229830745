import React, { ComponentType, useCallback, useState } from 'react';
import useConnectedUser from 'hooks/useConnectedUser';
import { Badge, Col, Row } from 'react-bootstrap';
import { ClassroomType } from 'types';
import useEntities from 'hooks/useEntities';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import FormModal from 'components/modal/FormModal';
import ClassroomForm from 'views/ClassroomConsult/components/ClassroomForm';
import ClassroomItem from './ClassroomItem';
import EmptyMessageWithPicture from 'components/EmptyMessageWithPicture';
import { useGlobal, useToast } from 'hooks';
import Loader from 'components/Loader';
import { IoThumbsUpSharp } from 'react-icons/io5';

type ClassroomListProps = {
  classrooms: ClassroomType[] | undefined;
};

const ClassroomList: ComponentType<ClassroomListProps> = ({ classrooms }) => {
  // HOOKS
  const [user] = useConnectedUser();
  const { postEntity } = useEntities();
  const [, { refreshClassrooms }] = useGlobal();
  const [{ toast }] = useToast();

  // STATES
  const [addError, setAddError] = useState<ApiError | undefined>();
  const [addVisible, setAddVisible] = useState(false);

  // HANDLERS
  const handleAdd = useCallback(
    async (values, helpers) => {
      if (!user) {
        return;
      }

      try {
        await postEntity(`/classrooms`, { owner: user['@id'], ...values });
        setAddVisible(false);
        await refreshClassrooms();
        helpers.resetForm();
        toast({
          type: 'success',
          title: 'Félicitations',
          message: 'Votre classe a été crée',
          delay: 200,
        });
      } catch (err) {
        setAddError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [postEntity, refreshClassrooms, toast, user]
  );

  if (!classrooms) {
    return <Loader />;
  }

  return (
    <>
      {(classrooms.length > 0 && (
        <>
          <h5 style={{ fontSize: '1.4em', marginBottom: 25 }}>Mes classes</h5>
          <Row className={''}>
            {classrooms
              .sort((a: ClassroomType, b: ClassroomType) =>
                !a.archived && b.archived ? -1 : a.archived && !b.archived ? 1 : a.name > b.name ? 1 : -1
              )
              .map((classroom: ClassroomType) => (
                <Col key={classroom.id} xs={12} sm={6} lg={4} className={'mb-4'}>
                  <ClassroomItem classroom={classroom} />
                </Col>
              ))}
          </Row>
        </>
      )) || (
        <EmptyMessageWithPicture
          title={
            <Row className={'align-items-center g-0 justify-content-center'}>
              <Col xs={'auto'} className={'me-3'}>
                <Badge bg={'success'} style={{ padding: '7px 10px' }}>
                  <IoThumbsUpSharp />
                </Badge>
              </Col>
              <Col xs={'auto'}>Bien commencer ...</Col>
            </Row>
          }
          imgStyle={{ width: 250 }}
        >
          <p>Maintenant que vous êtes inscrit, la première chose à faire est de vous créer une classe.</p>
        </EmptyMessageWithPicture>
      )}

      <Row className={'justify-content-center'}>
        <Col xs={'auto'} className={'ms-auto me-auto'}>
          <ButtonWithIcon variant={'primary'} onClick={() => setAddVisible(true)}>
            Nouvelle classe
          </ButtonWithIcon>
        </Col>
      </Row>

      <FormModal
        title={'Créer une classe'}
        show={addVisible}
        onSubmit={handleAdd}
        error={addError}
        onClose={() => {
          setAddVisible(false);
          setAddError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => <ClassroomForm ref={ref} onSubmit={onSubmit} error={error} />}
      />
    </>
  );
};

export default ClassroomList;
