import { randArrayValueByString } from 'helpers/rand';
import React, { ComponentType, useState } from 'react';
import { Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';

const defaultSize = 64;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  background-color: #dcdcdc;
  width: ${defaultSize}px;
  height: ${defaultSize}px;

  &.clickable:hover {
    cursor: pointer !important;
  }
`;

const TextDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-shadow: none;
`;

const colors = [
  '#4FB286',
  '#8AA29E',
  '#FCAA67',
  '#DB5461',
  '#D56F3E',
  '#ADBABD',
  '#95E06C',
  '#6CBEED',
  '#F5B0CB',
  '#CA3C25',
  '#FFE8E1',
];

export type AppAvatarProps = {
  viewer?: boolean;
  source?: string;
  sourceFullQuality?: string;
  shape: 'rounded' | 'square' | 'round';
  size?: number;
  alt?: string | undefined;
  style?: React.CSSProperties;
  className?: string;
};

const AppAvatar: ComponentType<AppAvatarProps> = ({
  viewer,
  source,
  shape,
  size,
  alt,
  sourceFullQuality,
  style,
  className,
  ...props
}) => {
  /**
   * STATES
   */
  const [viewerOpen, setViewerOpen] = useState(false);

  return (
    <>
      {viewerOpen && (
        <Lightbox mainSrcThumbnail={source} mainSrc={sourceFullQuality} onCloseRequest={() => setViewerOpen(false)} />
      )}

      <Container
        onClick={() => viewer && sourceFullQuality && setViewerOpen(true)}
        style={{
          borderRadius: shape === 'round' ? 1000 : shape === 'rounded' ? `5%` : 0,
          width: size ?? undefined,
          height: size ?? undefined,
          ...style,
        }}
        className={viewer && sourceFullQuality ? `${className} clickable appavatar` : `${className} appavatar`}
        {...props}
      >
        <>
          {(source && (
            <Image
              alt={alt}
              src={source}
              style={{ width: size ?? defaultSize, height: size ?? defaultSize, objectFit: 'cover' }}
              {...props}
            />
          )) || (
            <TextDiv style={{ backgroundColor: randArrayValueByString(colors, alt ?? '') }}>
              <svg height="100%" width="100%" viewBox="0 0 100 100">
                <text
                  x="50%"
                  y="55%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize={alt?.length === 1 ? 45 : 30}
                  fontFamily="Open Sans"
                  fontWeight="600"
                >
                  {alt && alt.length > 0 ? alt.toUpperCase() : '?'}
                </text>
              </svg>
            </TextDiv>
          )}
        </>
      </Container>
    </>
  );
};

export default AppAvatar;
