import React, { ComponentType } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { QuoteType } from 'types';
import { QuoteTextType } from 'types/QuoteType';
import { VscQuote } from 'react-icons/vsc';
import { BsFillHeartFill } from 'react-icons/bs';
import styled from 'styled-components';
import { imgStart } from 'helpers/illustration';
import { useConnectedUser } from 'hooks';

const WelcomeTitle = styled.div`
  font-size: 2.1em;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Text = styled.span`
  &.bold {
    font-weight: 600;
  }
  &.italic {
    font-style: italic;
  }

  &.sm {
    font-size: 14px;

    &.quotetitle {
      font-size: 20px;
    }
  }

  &.md {
    font-size: 16px;

    &.quotetitle {
      font-size: 25px;
    }
  }

  &.lg {
    font-size: 18px;

    &.quotetitle {
      font-size: 30px;
    }
  }

  &.muted {
    color: #666;
  }

  &.handwritetitle {
    font-weight: 500;
    font-family: DancingScript;
  }
`;

const renderText = (t: QuoteTextType, isTitle?: boolean) => {
  if (t.displayMode === 'quote') {
    return (
      <Row className={'align-items-center g-0'}>
        <Col xs={'auto'}>
          <VscQuote size={16} style={{ marginRight: 10 }} color={'#aaa'} />
        </Col>
        <Col>{renderText({ ...t, displayMode: 'normal' })}</Col>
      </Row>
    );
  }

  return (
    <Text
      className={`${isTitle ? 'quotetitle' : ''} ${t?.size ?? ''} ${t?.displayMode ?? ''} ${t.decoration ?? ''} ${
        t?.appearance ?? ''
      }`}
    >
      {t?.text}
    </Text>
  );
};

const renderQuoteTitle = (quote: QuoteType) => (
  <div className={'d-flex align-items-center'}>
    {renderText(quote.title, true)}
    {quote.type === 'welcome' && (
      <BsFillHeartFill size={25} style={{ lineHeight: 1, marginLeft: 10 }} color={'#ea2053'} />
    )}
  </div>
);

type BlockQuoteProps = {
  quote?: QuoteType | undefined;
};

const BlockQuote: ComponentType<BlockQuoteProps> = ({ quote }) => {
  /**
   * HOOKS
   */
  const [user] = useConnectedUser();

  if (!quote) {
    return <></>;
  }

  return (
    <>
      <div className={'py-4 mb-4 bg-light'}>
        <Container>
          <Row className={'align-items-center justify-content-center'}>
            <Col md={8} lg={8} xl={8} xxl={7}>
              <WelcomeTitle>Bienvenue {user?.givenName ?? ''},</WelcomeTitle>
              {quote?.title && renderQuoteTitle(quote)}
              {quote?.content && (
                <div>
                  {quote?.content.map((t, i) => (
                    <div className={'mt-3'} key={i}>
                      {renderText(t)}
                    </div>
                  ))}
                </div>
              )}
            </Col>
            {(quote?.image || quote.type === 'quote') && (
              <Col md={3} lg={3} xl={3} xxl={3} className={'d-none d-md-flex ms-4'}>
                <img
                  src={quote.type === 'quote' ? imgStart : quote?.image.source}
                  style={{ height: '100%', width: 300, maxWidth: '100%' }}
                  alt={''}
                />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BlockQuote;
