import React, { ComponentType } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { UserType } from 'types';
import ButtonWithIcon from 'components/ButtonWithIcon';
import FormError from 'components/form/FormError';
import AvatarInput from 'components/form/input/AvatarInput';
import { ApiError } from 'helpers/apiError';

const FormInitValues = {
  givenName: '',
  familyName: '',
  email: '',
  currentPassword: undefined,
  password: undefined,
  passwordConfirm: undefined,
  avatarData: undefined,
  newsletterAccepted: false,
};

type FormValues = typeof FormInitValues;

const FormSchema = Yup.object().shape({
  givenName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .required('Ne peut être vide'),
  familyName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .required('Ne peut être vide'),
  email: Yup.string().email('Doit être un email valide').required('Ne peut être vide'),
  password: Yup.string().matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
    'Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule et un caractère spécial'
  ),
  passwordConfirm: Yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Les mots de passe doivent être identiques'),
  }),
  currentPassword: Yup.string().nullable(),
  avatarData: Yup.mixed().nullable(),
  newsletterAccepted: Yup.bool(),
});

type AccountFormProps = {
  entity: UserType;
  onSubmit: (arg1: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  error?: ApiError | undefined;
};

const AccountForm: ComponentType<AccountFormProps> = ({ entity, onSubmit, error }) => {
  return (
    <Formik
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={{
        email: entity.email,
        familyName: entity.familyName,
        givenName: entity.givenName,
        currentPassword: undefined,
        password: undefined,
        passwordConfirm: undefined,
        avatarData: undefined,
        newsletterAccepted: entity.newsletterAccepted,
      }}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldError,
        setFieldValue,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}
          <Row className={'align-items-center'}>
            <Col xs={'auto'} className={'pe-4'}>
              <AvatarInput
                defaultValue={entity?.avatar ? entity.avatar.thumbnail : undefined}
                value={values.avatarData}
                onChange={(val) => setFieldValue('avatarData', val)}
                onError={(err) => {
                  setFieldError('avatarData', err);
                }}
                alt={`${values.givenName.substr(0, 1)}`}
                isInvalid={!!errors.avatarData}
              />
              <Form.Control.Feedback type="invalid">{errors.avatarData}</Form.Control.Feedback>
            </Col>
            <Form.Group as={Col}>
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                name="givenName"
                value={values.givenName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.givenName && !!errors.givenName}
              />
              <Form.Control.Feedback type="invalid">{errors.givenName}</Form.Control.Feedback>
              <Form.Label>Nom de famille</Form.Label>
              <Form.Control
                type="text"
                name="familyName"
                value={values.familyName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.familyName && !!errors.familyName}
              />
              <Form.Control.Feedback type="invalid">{errors.familyName}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.email && !!errors.email}
              disabled={true}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <div style={{ fontSize: '0.9em' }}>
            <Form.Group className={'mt-3'} controlId="checkboxnewsletter">
              <Form.Check
                type={'checkbox'}
                required
                name="newsletterAccepted"
                label={<>Je souhaite être informé par email de l'actualité d'Elevapp</>}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.newsletterAccepted && !!errors.newsletterAccepted}
                feedback={errors.newsletterAccepted}
                checked={values.newsletterAccepted}
              />
            </Form.Group>
          </div>

          <Form.Group className="mt-4">
            <h6>Mot de passe</h6>
            <p className="text-muted">
              <small>Laisser vide pour conserver le mot de passe actuel</small>
            </p>
            <Form.Control
              type="password"
              name="currentPassword"
              placeholder={'Mot de passe actuel'}
              value={values.currentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.currentPassword && !!errors.currentPassword}
            />
            <Form.Control.Feedback type="invalid">{errors.currentPassword}</Form.Control.Feedback>
          </Form.Group>
          <Row className={'g-2'}>
            <Form.Group as={Col}>
              <Form.Control
                type="password"
                name="password"
                placeholder={'Nouveau mot de passe'}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
                className={'mt-3'}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                type="password"
                name="passwordConfirm"
                placeholder={'Confirmer le mot de passe'}
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.passwordConfirm && !!errors.passwordConfirm}
                className={'mt-3'}
              />
              <Form.Control.Feedback type="invalid">{errors.passwordConfirm}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Col xs={'auto'} className={'ms-auto me-auto'}>
              <ButtonWithIcon type={'submit'} variant={'primary'} isSubmitting={isSubmitting}>
                Enregistrer
              </ButtonWithIcon>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AccountForm;
