import { useLocation } from 'react-router';
import createProvider from './createProvider';

const useQuery = (): any => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const [withQuery, useProvidedQuery] = createProvider(useQuery);

export { withQuery };

export default useProvidedQuery;
