import React, { useState } from 'react';
import createProvider from './createProvider';

export type ConfirmType = {
  handleConfirm: () => Promise<void>;
  handleCancel?: () => void;
  title?: React.ReactNode;
  body: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmInputLabel?: string;
  confirmInputValue?: string;
  variant?: 'primary' | 'danger' | 'warning' | 'success';
};

const useConfirm = () => {
  const [confirm, setConfirm] = useState<ConfirmType>();

  type ReturnType = [typeof confirm, typeof setConfirm];
  return [confirm, setConfirm] as ReturnType;
};

const [withConfirm, useProvidedConfirm] = createProvider(useConfirm);

export { withConfirm };

export default useProvidedConfirm;
