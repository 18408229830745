import { useMemo, useState } from 'react';
import createProvider from './createProvider';

export type UploadError = {
  filename: string;
  error: string;
};

const useUploadBox = () => {
  /**
   * STATES
   */
  const [visible, setVisible] = useState(false);
  const [filesSent, setFilesSent] = useState<number>(0);
  const [totalFilesToSend, setTotalFilesToSend] = useState<number>(0);
  const [errors, setErrors] = useState<UploadError[] | undefined>();

  const helpers = useMemo(() => {
    return {
      setVisible,
      setFilesSent,
      setTotalFilesToSend,
      setErrors,
    };
  }, []);

  const vars = useMemo(() => {
    return {
      visible,
      filesSent,
      totalFilesToSend,
      errors,
    };
  }, [errors, filesSent, totalFilesToSend, visible]);

  return [helpers, vars] as [typeof helpers, typeof vars];
};

const [withUploadBox, useProvidedUploadBox] = createProvider(useUploadBox);

export { withUploadBox };

export default useProvidedUploadBox;
