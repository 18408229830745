import React, { ComponentType } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type OopsProps = {
  err: Error;
};

const Oops: ComponentType<OopsProps> = ({ err, children }) => {
  return (
    <>
      <div className={'my-5 text-center text-muted'}>
        <FontAwesomeIcon icon={'bomb'} size={'3x'} />
        <h4 className={'mt-3'}>Une erreur est survenue</h4>
        <p>
          <u>Details:</u> <i>{err.message}</i>
        </p>
      </div>
    </>
  );
};

export default Oops;
