export enum ParentCategory {
  father = 'father',
  mother = 'mother',
  brother = 'brother',
  sister = 'sister',
  grandma = 'grandma',
  grandpa = 'grandpa',
  stepfather = 'stepfather',
  stepmother = 'stepmother',
  cousin = 'cousin',
  other = 'other',
}

export type ParentLightType = {
  ['@id']: string;
  id: string;

  type: ParentCategory;
  familyName: string;
  givenName: string;
};

export type ParentType = {
  ['@id']: string;
  id: string;
  createdAt: string;
  updatedAt: string;

  student: {
    ['@id']: string;
    id: string;
  };
  type: ParentCategory;
  familyName: string;
  givenName: string;
  comment?: string;
  email?: string;
  phone?: string;
  address?: string;
  profession?: string;
};

export const resolvParentType = (value: string): string => {
  switch (value) {
    case ParentCategory.father:
      return 'Père';
    case ParentCategory.mother:
      return 'Mère';
    case ParentCategory.brother:
      return 'Frère';
    case ParentCategory.sister:
      return 'Soeur';
    case ParentCategory.grandpa:
      return 'Grand-Père';
    case ParentCategory.grandma:
      return 'Grand-Mère';
    case ParentCategory.stepfather:
      return 'Beau-Père';
    case ParentCategory.stepmother:
      return 'Belle-Mère';
    case ParentCategory.cousin:
      return 'Cousin';
    default:
      return 'Autre';
  }
};

export const resolvParentTypeAsEmoj = (value: string): string => {
  switch (value) {
    case ParentCategory.father:
      return '👨🏻‍🦰';
    case ParentCategory.mother:
      return '👩🏻‍🦰';
    case ParentCategory.brother:
      return '🧒🏻';
    case ParentCategory.sister:
      return '👧🏻';
    case ParentCategory.grandpa:
      return '👨🏼‍🦳';
    case ParentCategory.grandma:
      return '👩🏼‍🦳';
    case ParentCategory.stepfather:
      return '👨🏻';
    case ParentCategory.stepmother:
      return '👱🏻';
    case ParentCategory.cousin:
      return '👱🏻‍♂️';
    default:
      return '🦸🏻';
  }
};
