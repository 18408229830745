import React, { ComponentType, useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ToastFullConfig } from './ToastBox';
import { IoCloseOutline } from 'react-icons/io5';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';

const ToastContainer = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  margin-top: -120px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

  &.visible {
    opacity: 1;
    margin-top: 0;
  }

  &:hover {
    cursor: default;
  }

  &.danger {
    background-color: #eb5552;
    color: white;
  }

  &.success {
    background-color: #3bbf64;
    color: white;
  }

  &.warning {
    background-color: #fbc658;
    color: #333;
  }

  .toast-icon,
  .toast-close {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 1;
    transition: 0.2s ease-in;

    svg {
      vertical-align: inherit;
    }
  }

  .toast-icon {
    margin-right: 15px;
  }

  .toast-title {
    margin-bottom: 2px;
    font-weight: 700;
  }

  .toast-message {
    font-size: 0.9em;
    font-weight: 500;
  }

  .toast-close {
    margin-left: 15px;

    &:hover {
      transform: scale(1.3);
      cursor: pointer;
    }
  }
`;

const Toast: ComponentType<ToastFullConfig> = ({
  index,
  onClose,
  id,
  title,
  message,
  Icon: UserIcon,
  duration: userDuration,
  type,
  noIcon,
  noAutoClose,
  delay,
}) => {
  /**
   * STATES
   */
  const [visible, setVisible] = useState(false);
  const [hover, setHover] = useState(false);

  /**
   * MEMOS
   */
  const duration = useMemo(() => {
    if (userDuration) {
      return userDuration;
    }

    switch (type) {
      case 'danger':
        return 5000;
      case 'warning':
        return 4000;
      default:
        return 3500;
    }
  }, [userDuration, type]);

  const Icon = useMemo(() => {
    if (noIcon) {
      return undefined;
    }

    const RealIcon =
      UserIcon ?? type === 'danger'
        ? FiAlertCircle
        : type === 'warning'
        ? FiAlertCircle
        : type === 'success'
        ? FiCheckCircle
        : undefined;

    if (RealIcon === undefined) {
      return undefined;
    }

    return <RealIcon />;
  }, [UserIcon, noIcon, type]);

  /**
   * EFFECTS
   */
  // Remove toast
  useEffect(() => {
    if (noAutoClose || hover) {
      return;
    }
    const t = setTimeout(() => {
      onClose(id);
    }, duration + 300);

    return () => clearInterval(t);
  }, [duration, hover, id, noAutoClose, onClose]);

  useEffect(() => {
    if (noAutoClose || hover) {
      return;
    }

    const t = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearInterval(t);
  }, [duration, hover, id, noAutoClose]);

  // Set toast as visible (only when load)
  useEffect(() => {
    const t = setTimeout(() => setVisible(true), delay ?? 0);
    return () => clearInterval(t);
  }, [delay]);

  return (
    <ToastContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${type} ${visible ? 'visible' : ''}`}
    >
      <Row className={'align-items-center g-0'}>
        {Icon && (
          <Col xs={'auto'}>
            <div className={'toast-icon'}>{Icon}</div>
          </Col>
        )}
        <Col>
          {title && <h6 className={'toast-title'}>{title}</h6>}
          <div className={'toast-message'}>{message}</div>
        </Col>
        <Col xs={'auto'}>
          <div className={'toast-close'} onClick={() => onClose(id)}>
            <IoCloseOutline />
          </div>
        </Col>
      </Row>
    </ToastContainer>
  );
};

export default Toast;
