import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import useApiClient from 'hooks/useApiClient';
import { useConnectedUser } from 'hooks';
import useConfig from 'hooks/useConfig';
import platform from 'platform';

const DeviceInfoBox: ComponentType = () => {
  /**
   * HOOKS
   */
  const client = useApiClient();
  const [user] = useConnectedUser();
  const appVersion = useConfig((config) => config.appVersion);

  /**
   * HANDLERS
   */
  const handleSendDeviceInfo = useCallback(async () => {
    await client.post(`/device_ping`, {
      appVersion: appVersion ?? 'unknown',
      deviceType: 'web',
      deviceOs: platform.os.family,
      deviceVersion: platform.version,
      deviceId: platform.name ?? 'browser',
      deviceInfo: {
        userAgent: navigator.userAgent ?? '',
      },
    });
  }, [appVersion, client]);

  /**
   * MEMOS
   */
  const userId = useMemo(() => user?.['@id'], [user]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (!userId) {
      return;
    }

    handleSendDeviceInfo();
  }, [handleSendDeviceInfo, userId]);

  return <></>;
};

export default DeviceInfoBox;
