import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { useToast } from 'hooks';
import Toast from './Toast';

export type ToastConfig = {
  id?: string;
  title?: string | undefined;
  Icon?: any | undefined;
  message: string;
  type: 'success' | 'danger' | 'warning';
  duration?: number;
  noIcon?: boolean;
  noAutoClose?: boolean;
  delay?: number;
};

export type ToastInternalConfig = {
  id?: string;
  index?: number;
  onClose?: (id: string) => void;
};

export type ToastFullConfig = ToastConfig & ToastInternalConfig;

const ToastContainer = styled.div`
  position: fixed;
  top: 30px;
  right: 40px;
  width: 80%;
  max-width: 375px;
  z-index: 1051;
`;

const ToastBox: ComponentType = () => {
  // HOOKS
  const [{ hideToast }, { toasts }] = useToast();

  return (
    <ToastContainer className={'d-flex row'}>
      {toasts.map((config: ToastFullConfig) => {
        return <Toast key={config.id} onClose={(id) => hideToast(id)} {...config} />;
      })}
    </ToastContainer>
  );
};

export default ToastBox;
