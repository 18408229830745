import React, { ComponentType, useState, useCallback, useEffect, useRef } from 'react';
import ClassroomAvatar from 'components/ClassroomAvatar';
import { AclType } from 'types';
import UserAvatar from 'components/UserAvatar';
import { useLocation, useNavigate } from 'react-router';
import MainCard, { SBottomItem } from 'components/MainCard';
import styled from 'styled-components';
import { useGlobal, useConfirm, useConnectedUser, useCurrentClassroom, useToast } from 'hooks';
import useApiClient from 'hooks/useApiClient';
import { useMemo } from 'react';
import { HiOutlineShare, HiOutlineMail, HiLogout } from 'react-icons/hi';
import { Button, Card, Col, Row } from 'react-bootstrap';
import ClassroomForm from './ClassroomForm';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArchivedBanner from 'components/ArchivedBanner';
import CardBadge from 'components/CardBadge';

const MainContainer = styled.div`
  transition: 0.2s;
  opacity: 1;

  &.disabled {
    opacity: 0.6;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 10000;
    }
  }
`;

const SectionTitle = styled.h6`
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 15px;
  color: #777;
`;

const ClassroomMainCard: ComponentType = () => {
  /**
   * REF
   */
  const formRef = useRef<any>();

  /**
   * HOOKS
   */
  const client = useApiClient();
  const navigate = useNavigate();
  const [user] = useConnectedUser();
  const [classroom, { acls }, { refreshClassroom }] = useCurrentClassroom();
  const [, setConfirm] = useConfirm();
  const [, { refreshClassrooms }] = useGlobal();
  const { editEntity, deleteEntity } = useEntities();
  const [{ toast }] = useToast();
  const { pathname } = useLocation();

  /**
   * STATES
   */
  const [editVisible, setEditVisible] = useState(false);
  const [isEditSubmitting, setIsEditSubmitting] = useState<boolean>(false);
  const [editError, setEditError] = useState<ApiError | undefined>();

  /**
   * MEMOS
   */
  const classroomIri = useMemo(() => classroom?.['@id'], [classroom]);

  /**
   * REFRESH
   */
  const handleRefresh = useCallback(async () => {
    if (!classroomIri) {
      return;
    }
    await refreshClassroom(classroomIri);
  }, [refreshClassroom, classroomIri]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const handleEdit = useCallback(
    async (values, helpers) => {
      if (!classroom || !user) {
        return;
      }

      setIsEditSubmitting(true);
      try {
        await editEntity(classroom['@id'], { owner: user['@id'], ...values });
        await handleRefresh();
        helpers.resetForm();
        setEditVisible(false);
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
        setIsEditSubmitting(false);
      }
    },
    [classroom, editEntity, handleRefresh, user]
  );

  const handleDelete = useCallback(async () => {
    setConfirm({
      title: 'Supprimer la classe',
      body: (
        <>
          <p>Souhaitez vous réellement supprimer cette classe ?</p>
          <p className={'text-danger'}>
            <b>Attention :</b> cette action est <u>irréversible et immédiate</u> et supprimera votre classe ainsi que
            toutes les données qui lui sont associées (élèves, observations, photos, partages, ...).
          </p>
        </>
      ),
      confirmText: 'Supprimer la classe',
      confirmInputValue: classroom.name,
      handleConfirm: async () => {
        try {
          await deleteEntity(`${classroom?.['@id']}`);
          await refreshClassrooms();
          navigate('/dashboard');
          toast({
            type: 'success',
            title: "C'est fait !",
            message: 'La classe est supprimée',
          });
        } catch (err) {
          toast({
            type: 'danger',
            title: 'Erreur pendant la suppression',
            message: parseFormApiError(err).message,
          });
          console.log(parseFormApiError(err));
        }
      },
    });
  }, [setConfirm, classroom, deleteEntity, refreshClassrooms, navigate, toast]);

  const handleLeaveClassroom = useCallback(() => {
    setConfirm({
      body: <>Souhaitez-vous réellement quitter cette classe ?</>,
      handleConfirm: async () => {
        await client.post(`/classroom_invitations/${classroom?.myAcl.id}/reject`);
        await refreshClassrooms();
        navigate(`/dashboard`);
      },
    });
  }, [setConfirm, client, classroom?.myAcl.id, refreshClassrooms, navigate]);

  // MEMOS
  const validAcls = useMemo(() => acls && acls.filter((a: AclType) => a.acceptedAt !== null), [acls]);

  /**
   * SUBCOMPONENTS
   */
  const renderClassroomAvatar = useCallback(
    ({ ...props }) => <ClassroomAvatar {...props} classroom={classroom} />,
    [classroom]
  );

  const AclList = useMemo(() => {
    if (!validAcls) {
      return undefined;
    }
    return (
      <>
        <SectionTitle>Collaborateurs</SectionTitle>
        <div>
          <UserAvatar user={classroom.owner} height={50} tooltipOnHover shape={'round'} style={{ margin: 3 }} />
          {validAcls.map((acl) => (
            <UserAvatar key={acl.id} user={acl.user} height={50} tooltipOnHover shape={'round'} style={{ margin: 3 }} />
          ))}
        </div>
      </>
    );
  }, [classroom, validAcls]);

  if (editVisible) {
    return (
      <>
        <Card>
          <CardBadge type={'close'} onClick={() => setEditVisible(false)} />
          <Card.Body>
            {classroom && <ClassroomForm error={editError} ref={formRef} entity={classroom} onSubmit={handleEdit} />}
            <Row className={'align-items-center justify-content-end flex-row-reverse'}>
              <Col xs={'auto'} className={'ms-auto me-auto'}>
                <Button
                  className={'d-none d-lg-inline-block'}
                  variant={'link'}
                  size={'sm'}
                  onClick={() => setEditVisible(false)}
                >
                  Annuler
                </Button>
                <ButtonWithIcon
                  isSubmitting={isEditSubmitting}
                  variant="primary"
                  onClick={() => {
                    formRef.current && formRef.current.handleSubmit();
                  }}
                >
                  Enregistrer
                </ButtonWithIcon>
              </Col>
              <Col xs={'auto'} className={'me-auto'}>
                <ButtonWithIcon size={'sm'} variant="danger" onClick={handleDelete}>
                  <FontAwesomeIcon icon={'trash'} />
                </ButtonWithIcon>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }

  return (
    <>
      <MainContainer>
        <MainCard
          onEdit={classroom && classroom?.myAcl.classroomAcl === 'full-access' && (() => setEditVisible(true))}
          title={classroom?.name}
          description={
            classroom?.establishment && classroom?.schoolYear
              ? `${classroom?.establishment} - ${classroom?.schoolYear}`
              : classroom?.establishment
              ? classroom?.establishment
              : classroom?.schoolYear
              ? classroom?.schoolYear.toString()
              : undefined
          }
          Avatar={renderClassroomAvatar}
          banner={classroom.banner.url}
        >
          {classroom?.archived && <ArchivedBanner>Archivée</ArchivedBanner>}
          <div className={'py-1 px-4'}>{AclList}</div>
        </MainCard>
      </MainContainer>
      {classroom && user && (
        <div className={'text-center mt-3'}>
          <SBottomItem
            onClick={() => navigate(`/classrooms/${classroom.id}/communication`)}
            className={pathname === `/classrooms/${classroom.id}/communication` ? 'active' : ''}
          >
            <HiOutlineMail />
            Communication
          </SBottomItem>
          {classroom?.myAcl.classroomAcl === 'full-access' && (
            <>
              <SBottomItem
                onClick={() => navigate(`/classrooms/${classroom.id}/shares`)}
                className={pathname === `/classrooms/${classroom.id}/shares` ? 'active' : ''}
              >
                <HiOutlineShare />
                Gérer les partages
              </SBottomItem>
            </>
          )}
          {classroom.owner.id !== user.id && (
            <SBottomItem onClick={handleLeaveClassroom}>
              <HiLogout /> Quitter la classe
            </SBottomItem>
          )}
        </div>
      )}
    </>
  );
};

export default ClassroomMainCard;
