import { useEffect, useMemo } from 'react';
import axios from 'axios';
import useConfig from './useConfig';
import useAuthentication from './useAuthentication';
// import { useNavigate } from 'react-router';
import { useToast } from 'hooks';
import { FiWifiOff } from 'react-icons/fi';

const useApiClient = () => {
  // HOOKS
  const apiConfig = useConfig((config) => config.api);
  const [, { getFreshAccessToken, logout }] = useAuthentication();
  const [{ toast }] = useToast();

  const client = useMemo(() => {
    return axios.create();
  }, []);

  // EFFECTS
  useEffect(() => {
    const interceptorIndex = client.interceptors.request.use(async (requestConfig) => {
      const token = await getFreshAccessToken();

      // eslint-disable-next-line no-process-env
      if (process.env.NODE_ENV === 'development') {
        console.log(`[API] ${requestConfig.method.toUpperCase()} ${requestConfig.url}`);
      }

      return {
        timeout: 10000,
        baseURL: apiConfig.url,
        ...requestConfig,
        headers: {
          Authorization: token && `Bearer ${token.token}`,
          Accept: 'application/ld+json, application/json, application/problem+json',
          'x-vault-secret': token && token.vaultSecret,
          ...requestConfig.headers,
        },
      };
    });

    return () => {
      client.interceptors.request.eject(interceptorIndex);
    };
  }, [apiConfig.url, client.interceptors.request, getFreshAccessToken]);

  useEffect(() => {
    const interceptorIndex = client.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        console.log('networkError', {
          method: err.request._method,
          responseURL: err.request.responseURL,
          status: err.request.status,
          _response: err.request._response,
        });

        if (err.message === 'Network Error' || err.request.status === 0) {
          toast({
            type: 'danger',
            title: 'Serveur indisponible',
            message: 'Veuillez ré-essayer dans quelques minutes',
            duration: 5000,
            id: 'networknotavailable',
            Icon: FiWifiOff,
          });
        }

        if (err.request.status === 500) {
          toast({
            type: 'warning',
            title: 'Erreur serveur',
            message: 'Veuillez ré-essayer dans quelques minutes',
            duration: 5000,
            id: 'responsestatus500',
          });
        }

        if (err.response && (err.response.status === 401 || err.response.status === 409)) {
          if (
            err.response.data.message !== 'Invalid credentials.' &&
            err.response.data.message !== 'Identifiants invalides.'
          ) {
            console.log('receive 401 : logout');
            toast({
              type: 'warning',
              message: 'Votre session a expirée',
              id: 'userlogout',
            });
            logout();
            // navigate('/');
          }
        }

        return Promise.reject(err);
      }
    );

    return () => {
      client.interceptors.response.eject(interceptorIndex);
    };
  }, [client.interceptors.response, logout, toast]);

  return client;
};

export default useApiClient;
