import moment from 'moment';

export const formatObservationDate = (value: string): string => {
  if (!value) {
    return "Aujourd'hui";
  }

  const m = moment(value);

  return m.calendar({
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain]',
    lastDay: '[Hier]',
    nextWeek: 'D MMM',
    lastWeek: 'D MMM',
    sameElse: () => {
      if (m.isBefore(moment().subtract(9, 'month'))) {
        return 'D MMM YYYY';
      } else {
        return 'D MMM';
      }
    },
  });
};
