import React, { ComponentType } from 'react';
import { Row, Col } from 'react-bootstrap';
import { StudentType } from 'types';
import StudentItem from './StudentItem';

type StudentListProps = {
  students: StudentType[];
};

const StudentList: ComponentType<StudentListProps> = ({ students }) => {
  return (
    <Row className={'flex-wrap g-2'}>
      {students.map((student: StudentType) => (
        <Col key={student.id} xl={'auto'} lg={3} md={4} sm={3} xs={4} className={'my-1'}>
          <StudentItem student={student} />
        </Col>
      ))}
    </Row>
  );
};

export default StudentList;
