import React, { ComponentType, useCallback, useRef, useState, useMemo } from 'react';
import { resizeImage, convertToB64 } from 'helpers/image';
import styled from 'styled-components';
import AppAvatar from 'components/AppAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditButton = styled.div`
  position: absolute;
  bottom: 6px;
  right: 6px;
  text-align: center;

  border-radius: 300px;
  background-color: #eee;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  &:hover {
    background-color: #ddd;
    cursor: pointer;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type AvatarInputProps = {
  value?: string;
  defaultValue?: any;
  onChange: (b64Data: string) => void;
  onError: (err: string) => void;
  isInvalid?: boolean;
  alt?: string;
  avatarStyle?: any;
};

const AvatarInput: ComponentType<AvatarInputProps> = ({
  value,
  defaultValue,
  onChange,
  onError,
  isInvalid,
  alt,
  avatarStyle,
}) => {
  // HOOKS
  const uploadButtonRef = useRef<any>(null);

  // STATE
  const [blobData, setBlobData] = useState<undefined | any>();

  // HANDLERS
  const handleChange = useCallback(
    async (event: any) => {
      try {
        const file = event.target.files[0];

        const reducedFile = await resizeImage(file);

        setBlobData(URL.createObjectURL(reducedFile));
        onChange(await convertToB64(reducedFile));
      } catch (err) {
        onError(err.message);
      }
    },
    [onChange, onError]
  );

  // MEMO
  const preview = useMemo(() => {
    return blobData ?? value ?? defaultValue;
  }, [defaultValue, value, blobData]);

  return (
    <div className={isInvalid ? 'is-invalid' : ''}>
      <input
        ref={uploadButtonRef}
        type={'file'}
        accept={'image/*'}
        multiple={false}
        onChange={handleChange}
        className={'d-none'}
      />

      <AvatarContainer>
        <AppAvatar style={avatarStyle} source={preview} alt={alt} size={120} shape={'round'} />
        <EditButton
          onClick={() => {
            uploadButtonRef.current && uploadButtonRef.current.click();
          }}
        >
          <FontAwesomeIcon icon={'camera'} />
        </EditButton>
      </AvatarContainer>
    </div>
  );
};

export default AvatarInput;
