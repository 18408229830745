import React, { ComponentType } from 'react';
import styled from 'styled-components';

const Banner = styled.img`
  width: 300px;
  margin-bottom: 20px;
`;

type EmptyMessageWithPictureProps = {
  img?: any;
  title?: string | JSX.Element;
  imgStyle?: any;
};

const EmptyMessageWithPicture: ComponentType<EmptyMessageWithPictureProps> = ({ img, title, imgStyle, children }) => {
  return (
    <div className={'text-center my-5'}>
      {img && <Banner src={img} style={imgStyle} />}
      {title && <h4>{title}</h4>}
      {children}
    </div>
  );
};

export default EmptyMessageWithPicture;
