import createProvider from './createProvider';
import { StudentType, ParentType, StudentInformationType } from 'types';
import { useState, useCallback, useMemo } from 'react';
import useEntities from './useEntities';
import deepEqual from 'deep-equal';

const useCurrentStudent = () => {
  // HOOKS
  const { fetchEntity, fetchAll } = useEntities();

  // SATES
  const [student, setStudent] = useState<StudentType | undefined>();
  const [parents, setParents] = useState<ParentType[] | undefined>();
  const [informations, setInformations] = useState<StudentInformationType[] | undefined>();

  const isInit = useCallback((studentIri: string) => student?.['@id'] === studentIri, [student]);

  // REFRESH METHOD
  const refreshStudent = useCallback(
    async (studentIri: string | undefined) => {
      let data: StudentType | undefined;
      if (studentIri) {
        const entity = await fetchEntity(studentIri);
        data = entity;
      } else {
        data = undefined;
      }
      setStudent((state) => (!deepEqual(data, state) ? data : state));
    },
    [fetchEntity]
  );

  const refreshParents = useCallback(
    async (studentIri: string | undefined) => {
      let data: ParentType[] | undefined;
      if (studentIri) {
        const entities = await fetchAll(`/student_parents?student=${studentIri}`);
        data = entities;
      } else {
        data = undefined;
      }
      setParents((state) => (!deepEqual(data, state) ? data : state));
    },
    [fetchAll]
  );

  const refreshInformations = useCallback(
    async (studentIri: string | undefined) => {
      let data: StudentInformationType[] | undefined;
      if (studentIri) {
        const entities = await fetchAll(`/student_informations?student=${studentIri}&order[priority]=ASC`);
        data = entities;
      } else {
        data = undefined;
      }
      setInformations((state) => (!deepEqual(data, state) ? data : state));
    },
    [fetchAll]
  );

  const cleanup = useCallback(() => {
    refreshStudent(undefined);
    refreshParents(undefined);
    refreshInformations(undefined);
  }, [refreshInformations, refreshParents, refreshStudent]);

  const refreshAll = useCallback(
    async (studentIri: string | undefined) => {
      await refreshStudent(studentIri);
      refreshParents(studentIri);
      refreshInformations(studentIri);
    },
    [refreshInformations, refreshParents, refreshStudent]
  );

  const init = useCallback(
    async (studentIri: string | undefined, student?: StudentType) => {
      if (isInit(studentIri)) {
        return;
      }
      if (student) {
        setStudent(student);
      }
      await refreshAll(studentIri);
    },
    [isInit, refreshAll]
  );

  const subEntities = useMemo(() => {
    return {
      parents,
      informations,
    };
  }, [informations, parents]);

  const actions = useMemo(() => {
    return {
      cleanup,
      init,
      setStudent,
      refreshStudent,
      refreshParents,
      refreshInformations,
      refreshAll,
    };
  }, [cleanup, init, refreshAll, refreshInformations, refreshParents, refreshStudent]);

  return [student, subEntities, actions] as [typeof student, typeof subEntities, typeof actions];
};

const [withCurrentStudent, useProvidedCurrentStudent] = createProvider(useCurrentStudent);

export { withCurrentStudent };

export default useProvidedCurrentStudent;
