import React, { ComponentType } from 'react';
import { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserType } from 'types';
import AppAvatar from './AppAvatar';

type UserAvatarProps = {
  viewer?: boolean;
  user?: UserType;
  shape: 'rounded' | 'square' | 'round';
  height?: number;
  tooltipOnHover?: boolean;
  className?: string;
};

const UserAvatar: ComponentType<UserAvatarProps & any> = ({
  user,
  rounded,
  height,
  style,
  tooltipOnHover,
  viewer,
  ...props
}) => {
  const AvatarComponent = useCallback(() => {
    return (
      <AppAvatar
        viewer={viewer}
        source={user?.avatar?.thumbnail}
        sourceFullQuality={user?.avatar?.regular}
        alt={user?.givenName?.substr(0, 1) ?? undefined}
        size={height}
        style={style}
        {...props}
      />
    );
  }, [height, props, style, user?.avatar?.regular, user?.avatar?.thumbnail, user?.givenName, viewer]);

  if (tooltipOnHover) {
    return (
      <OverlayTrigger
        placement={'bottom'}
        overlay={
          <Tooltip id={`tooltip-acl`}>
            {user.givenName} {user.familyName}
          </Tooltip>
        }
      >
        <div style={{ display: 'inline-block' }}>
          <AvatarComponent />
        </div>
      </OverlayTrigger>
    );
  }

  return <AvatarComponent />;
};

export default UserAvatar;
