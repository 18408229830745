import Loader from 'components/Loader';
import useConfig from 'hooks/useConfig';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

type LegalScreenProps = {
  page: string;
};

const LegalScreen: ComponentType<LegalScreenProps> = ({ page }) => {
  /**
   * MEMOS
   */
  const apiConfig = useConfig((config) => config.api);

  /**
   * STATES
   */
  const [htmlContent, setHtmlContent] = useState<string | undefined>();

  /**
   * CALLBACKS
   */
  const handleRefresh = useCallback(async () => {
    const rslt = await fetch(`${apiConfig.url}${page}`);
    let html = await rslt.text();

    html = html.replaceAll('/terms.md', '/terms');
    html = html.replaceAll('/privacy.md', '/privacy');
    html = html.replaceAll('/legal.md', '/legal');

    setHtmlContent(html);
  }, [apiConfig.url, page]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <Container className={'marginauto'}>
      {!htmlContent && <Loader />}
      {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>}
    </Container>
  );
};

export default LegalScreen;
