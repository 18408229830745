import FormError from 'components/form/FormError';
import Loader from 'components/Loader';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { useQuery } from 'hooks';
import useApiClient from 'hooks/useApiClient';
import React, { ComponentType, useCallback, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router';

const CancelDeletion: ComponentType = () => {
  /**
   * HOOKS
   */
  const client = useApiClient();
  const navigate = useNavigate();
  const query = useQuery();

  /**
   * STATES
   */
  const [success, setSuccess] = useState<boolean | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | undefined>();

  /** CALLBACKS */
  const handleAnswer = useCallback(async () => {
    setSubmitting(true);
    setError(undefined);
    setSuccess(undefined);
    try {
      await client.post(`/cancel_deletion`, {
        id: query.get('id'),
        secret: query.get('secret'),
      });
      setSuccess(true);
    } catch (err) {
      setError(parseFormApiError(err));
      setSuccess(false);
    } finally {
      setSubmitting(false);
    }
  }, [client, query]);
  console.log(error);

  return (
    <Container className={'marginauto'}>
      <Row className={'align-items-center'}>
        <Col xs={'auto'} md={8} lg={6} xl={5} className={'ms-auto me-auto mt-3'}>
          <Card>
            <Card.Body>
              {submitting && <Loader />}

              {!submitting && success === undefined && (
                <>
                  <Card.Title>Réactiver mon compte</Card.Title>
                  <Card.Text>
                    Cliquez sur le bouton ci-dessous pour confirmer la réactivation de votre compte.
                  </Card.Text>
                  <div className={'text-center'}>
                    <Button variant="primary" className={'ms-4'} onClick={handleAnswer}>
                      Je réactive mon compte
                    </Button>
                  </div>
                </>
              )}

              {!submitting && success === true && (
                <>
                  <div className="text-center text-success my-3">
                    <FaRegCheckCircle style={{ fontSize: '4em' }} />
                  </div>
                  <Card.Text className="text-center">
                    <strong>Félicitations</strong> : votre compte ne sera pas supprimé.
                  </Card.Text>
                  <div className={'text-center'}>
                    <Button variant="primary" className={'ms-4'} onClick={() => navigate('/signin')}>
                      Je me connecte
                    </Button>
                  </div>
                </>
              )}
              {!submitting && success === false && (
                <>
                  <div className="text-center text-danger my-3">
                    <FaRegTimesCircle style={{ fontSize: '4em' }} />
                  </div>

                  <Card.Text>
                    Nous n'avons pas été en mesure de réactiver votre compte, veuillez nous contacter.
                  </Card.Text>

                  {error && <FormError error={error} />}

                  <div className={'text-center'}>
                    <Card.Text>
                      <Button variant={'secondary'} onClick={() => navigate('/help')}>
                        Contactez nous
                      </Button>
                    </Card.Text>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CancelDeletion;
