import React, { ComponentType } from 'react';
import { Spinner } from 'react-bootstrap';

type LoaderProps = {
  type?: 'border' | 'grow';
  size?: 'sm';
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'warning' | 'light' | 'dark';
};

const Loader: ComponentType<LoaderProps> = ({ variant, size, type, children }) => {
  return (
    <>
      <div className={'text-center m-4'}>
        <Spinner animation={type ?? 'border'} role="status" variant={variant} size={size}>
          <span className="visually-hidden">Chargement ...</span>
        </Spinner>
        <div>{children}</div>
      </div>
    </>
  );
};

export default Loader;
