import React, { ComponentType } from 'react';
import { useMemo } from 'react';
import { StudentType } from 'types';
import AppAvatar from './AppAvatar';

type StudentAvatarProps = {
  viewer?: boolean;
  student?: StudentType;
  shape: 'rounded' | 'square' | 'round';
  className?: string;
};

const StudentAvatar: ComponentType<StudentAvatarProps & any> = ({ student, ...props }) => {
  /** MEMOS */
  const alt = useMemo(
    () => `${student?.givenName.substr(0, 1) ?? ''}${student?.familyName.substr(0, 1) ?? ''}`,
    [student?.familyName, student?.givenName]
  );

  return (
    <AppAvatar source={student?.avatar?.thumbnail} sourceFullQuality={student?.avatar?.regular} alt={alt} {...props} />
  );
};

export default StudentAvatar;
