import React, { ComponentType } from 'react';
import { Route, Routes } from 'react-router';
import ClassroomMedias from '../views/Medias/ClassroomMedias';
import ClassroomObservations from '../views/Observations/ClassroomObservations';
import AclList from '../views/Acls/AclList';
import Students from '../views/Students/Students';
import Communication from '../views/Communication/Communication';

const ClassroomRouter: ComponentType = () => {
  return (
    <Routes>
      <Route path={`/`} element={<Students />} />
      <Route path={`/observations`} element={<ClassroomObservations />} />
      <Route path={`/medias`} element={<ClassroomMedias />} />
      <Route path={`/shares`} element={<AclList />} />
      <Route path={`/communication`} element={<Communication />} />
    </Routes>
  );
};

export default ClassroomRouter;
