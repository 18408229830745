import React, { ComponentType, useState, useCallback } from 'react';
import useApiClient from 'hooks/useApiClient';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import FormError from './form/FormError';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ButtonWithIcon from './ButtonWithIcon';
import useConfig from 'hooks/useConfig';

type ConfirmIdentityProps = {
  emailProp?: string | undefined;
  onSuccess: (identityToken: string) => void;
};

const ConfirmIdentity: ComponentType<ConfirmIdentityProps> = ({ emailProp, onSuccess }) => {
  /**
   * CONFIG
   */
  const defaultEmail = useConfig((config) => config.defaultEmail);

  /**
   * HOOKS
   */
  const client = useApiClient();

  /**
   * STATES
   */
  const [email, setEmail] = useState(emailProp ?? defaultEmail);
  const [code, setCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<ApiError | undefined>();
  const [step, setStep] = useState(0);

  /**
   * HANDLERS
   */
  const handleSendIdentityRequest = useCallback(async () => {
    setSubmitting(true);
    try {
      await client.post(`/identity_ask`, { email });
      setCode('');
      setError(undefined);
      setStep(1);
    } catch (err) {
      setError(parseFormApiError(err));
    } finally {
      setSubmitting(false);
    }
  }, [client, email]);

  const handleConfirmIdentity = useCallback(async () => {
    setSubmitting(true);
    try {
      const resp = await client.post(`/identity_confirm`, { email, code });
      onSuccess(resp.data.token);
    } catch (err) {
      setError(parseFormApiError(err));
    } finally {
      setSubmitting(false);
    }
  }, [client, code, email, onSuccess]);

  return (
    <>
      {error && <FormError error={error} />}
      {step === 0 && (
        <>
          <p>Nous avons besoin de valider votre identité pour effectuer cette action.</p>

          {!emailProp && (
            <Form.Control
              type="text"
              name="email"
              placeholder={'Adresse email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}

          <Row className={'mt-2'}>
            <Col xs={'auto'} className={'ms-auto me-auto'}>
              <ButtonWithIcon
                className={'btn-round'}
                type={'submit'}
                variant={'primary'}
                isSubmitting={submitting}
                onClick={handleSendIdentityRequest}
              >
                Envoyez moi un email
              </ButtonWithIcon>
            </Col>
          </Row>
        </>
      )}
      {step === 1 && (
        <>
          <>
            <p>Nous venons de vous envoyer un email contenant un code afin de valider votre identité.</p>
            <Form.Control
              type="text"
              name="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder={'Saisissez le code'}
            />
            <Row className={'mt-2'}>
              <Col xs={'auto'} className={'ms-auto me-auto text-center'}>
                <ButtonWithIcon
                  className={'btn-round'}
                  type={'submit'}
                  variant={'primary'}
                  isSubmitting={submitting}
                  onClick={handleConfirmIdentity}
                >
                  Confirmer mon identité
                </ButtonWithIcon>
                <Button onClick={handleSendIdentityRequest} variant={'link'} size={'sm'}>
                  J'ai besoin d'un nouveau code
                </Button>
              </Col>
            </Row>
          </>
        </>
      )}
    </>
  );
};

export default ConfirmIdentity;
