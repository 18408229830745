import React, { ComponentType } from 'react';
import { Card } from 'react-bootstrap';

const PageContent: ComponentType = ({ children }) => {
  return (
    <>
      <Card className={'mb-4'}>
        <Card.Body>{children}</Card.Body>
      </Card>
    </>
  );
};

export default PageContent;
