import { useCurrentClassroom } from 'hooks';
import React, { ComponentType } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { StudentType } from 'types';
import StudentList from './StudentList';

const SContainer = styled.div`
  border-radius: 10px;
  border: 2px dashed transparent;
  padding: 0px 5px;

  &.dropactive {
    background-color: #eee;
    border-color: #bbb;
  }
`;

type DraggableStudentListProps = {
  id: string | undefined;
  students: StudentType[];
  handleAddStudent?: () => void;
};

const DraggableStudentList: ComponentType<DraggableStudentListProps> = ({ id, students, handleAddStudent }) => {
  const [classroom] = useCurrentClassroom();
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'student',
    drop: () => ({ id: id ?? 'empty' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <SContainer
      ref={drop}
      className={`row mb-3 ${classroom?.myAcl.studentAcl === 'full-access' && canDrop && isOver ? 'dropactive' : ''}`}
    >
      {(students.length > 0 && <StudentList students={students} />) || (
        <div className="my-4">
          <p style={{ fontSize: '1.2em', fontWeight: 500, marginBottom: 5 }}>
            <span style={{ fontSize: '1.3em' }}>😢</span> Aucun élève dans le groupe ...
          </p>
          {classroom?.myAcl.studentAcl === 'full-access' && handleAddStudent && (
            <p className="text-muted">
              <i>
                Glissez un élève existant ou{' '}
                <a href={'#/'} onClick={handleAddStudent}>
                  ajouter un nouvel élève
                </a>
                .
              </i>
            </p>
          )}
        </div>
      )}
    </SContainer>
  );
};

export default DraggableStudentList;
