import React, { ComponentType, useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import InformationForm from './InformationForm';
import { StudentInformationType } from 'types/StudentInformationType';
import ButtonWithIcon from 'components/ButtonWithIcon';
import Loader from 'components/Loader';
import InformationItem from './InformationItem';
import FormModal from 'components/modal/FormModal';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';
import EmptyMessageWithPicture from 'components/EmptyMessageWithPicture';
import { imgChild } from 'helpers/illustration';

const InformationList: ComponentType = () => {
  // HOOKS
  const [classroom] = useCurrentClassroom();
  const [student, { informations }, { refreshInformations }] = useCurrentStudent();
  const { postEntity } = useEntities();

  // STATES
  const [addError, setAddError] = useState<ApiError | undefined>();
  const [addVisible, setAddVisible] = useState(false);

  // REFRESH
  const handleRefresh = useCallback(async () => {
    if (!student) {
      return;
    }
    await refreshInformations(student['@id']);
  }, [refreshInformations, student]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const handleAdd = useCallback(
    async (values, helpers) => {
      try {
        const postValues = { ...values, ...{ student: student?.['@id'] } };
        await postEntity('/student_informations', postValues);
        await handleRefresh();
        setAddVisible(false);
        helpers.resetForm();
      } catch (err) {
        setAddError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [handleRefresh, postEntity, student]
  );

  const handleEdit = useCallback(
    async (entity: any) => {
      await handleRefresh();
    },
    [handleRefresh]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await handleRefresh();
    },
    [handleRefresh]
  );

  if (!student) {
    return <Loader />;
  }

  return (
    <>
      {(informations && informations.length > 0 && (
        <Row>
          {informations.map((information: StudentInformationType) => (
            <Col md={6} key={information.id}>
              <InformationItem
                information={information}
                onEdit={classroom?.myAcl.studentAcl === 'full-access' && handleEdit}
                onDelete={classroom?.myAcl.studentAcl === 'full-access' && handleDelete}
              />
            </Col>
          ))}
        </Row>
      )) || (
        <EmptyMessageWithPicture img={imgChild} title={"Tout ce qu'il faut savoir"}>
          <p>
            Rassemblez ici <b>tout ce que vous estimez utile</b> pour vous ou vos collégues.
          </p>
          <p className={'text-muted'} style={{ fontSize: '0.9em' }}>
            Contrairement aux notes, ces informations restent toujours à portée de vue ; vous ne jamais passer à côté
            d'une info importante.
          </p>
        </EmptyMessageWithPicture>
      )}

      {classroom?.myAcl.studentAcl === 'full-access' && (
        <Row>
          <Col xs={'auto'} className={'ms-auto me-auto'}>
            <ButtonWithIcon variant={'primary'} onClick={() => setAddVisible(true)}>
              Nouvelle information
            </ButtonWithIcon>
          </Col>
        </Row>
      )}

      <FormModal
        title={'Ajouter une information'}
        show={addVisible}
        onSubmit={handleAdd}
        error={addError}
        onClose={() => {
          setAddVisible(false);
          setAddError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => <InformationForm ref={ref} onSubmit={onSubmit} error={error} />}
      />
    </>
  );
};

export default InformationList;
