import React, { ComponentType, useEffect, useState } from 'react';
import { MediaType } from 'types';
import { Card, Row, Col } from 'react-bootstrap';
import MediaGallery from 'components/media/MediaGallery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useEntities from 'hooks/useEntities';

type MediasPreviewCardProps = {
  baseUrl: string;
  viewAllLink: string;
  nbElements?: number;
};

const MediasPreviewCard: ComponentType<MediasPreviewCardProps> = ({ baseUrl, viewAllLink, nbElements }) => {
  // HOOKS
  const { fetch } = useEntities();

  // STATE
  const [medias, setMedias] = useState<MediaType[] | undefined>();

  // EFFECTS
  useEffect(() => {
    if (!baseUrl) {
      return;
    }
    (async () => {
      const [fetchEntities] = await fetch(baseUrl, 1);
      setMedias(fetchEntities.slice(0, nbElements ?? 9));
    })();
  }, [baseUrl, fetch, nbElements]);

  if (!medias || medias.length === 0) {
    return <></>;
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <Card.Title style={{ fontSize: '1.25em' }}>Photos</Card.Title>
          </Col>
          <Col xs={'auto'} className={'ms-auto'}>
            <div className={'pt-1'}>
              <Link to={viewAllLink}>voir tout</Link>
            </div>
          </Col>
        </Row>
        <MediaGallery
          entities={medias}
          xs={4}
          caption={(media: MediaType) => (
            <>
              {(media?.student && (
                <>
                  {media.student.givenName} {media.student.familyName} -{' '}
                </>
              )) ||
                (media?.classroom && <>{media.classroom.name} - </>)}
              {moment(media.date).format('LLL')}
            </>
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default MediasPreviewCard;
