import React, { ComponentType } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { AppAvatarProps } from './AppAvatar';
import CardBadge from './CardBadge';

const SBanner = styled.div`
  width: 100%;
  height: 130px;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ddd;
`;

const SDescription = styled.div`
  font-size: 0.9em;
  color: #999;
  text-align: center;
  margin-bottom: 5px;
`;

export const SBottomItem = styled.div`
  display: block;
  margin: 10px 7px;
  text-align: left;
  font-size: 0.9em;
  font-weight: 500;
  color: #555;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: center;

  svg {
    font-size: 1.4em;
    margin-right: 10px;
    color: #999;
  }

  &:hover,
  &.active {
    opacity: 0.9;
    cursor: pointer;
    color: #3092e3;
    svg {
      color: #3092e3;
    }
  }
`;

type MainCardProps = {
  banner?: string;
  Avatar?: (props: AppAvatarProps) => JSX.Element;
  onEdit?: () => void;
  title?: string | JSX.Element;
  description?: string | JSX.Element;
};

const MainCard: ComponentType<MainCardProps> = ({ banner, Avatar, onEdit, title, description, children }) => {
  return (
    <Card className={'card-main'}>
      {onEdit && <CardBadge type={'edit'} onClick={onEdit} />}
      <SBanner style={{ backgroundImage: `url(${banner})` }} />
      <Card.Body>
        <div className="card-main-title">
          <Avatar className="avatar" size={124} viewer={true} shape={'round'} />
          <h4>{title}</h4>
          {description && <SDescription>{description}</SDescription>}
        </div>

        {children}
      </Card.Body>
    </Card>
  );
};

export default MainCard;
