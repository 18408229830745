import Loader from 'components/Loader';
import StudentAvatar from 'components/StudentAvatar';
import deepmerge from 'deepmerge';
import { useCurrentClassroom, useToast } from 'hooks';
import useEntities from 'hooks/useEntities';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StudentType, ParentType } from 'types';

type emailByStudents = {
  [key: string]: {
    student: string[];
    parents: string[];
  };
};

const StudentSelector = styled.div`
  display: inline-block;
  margin: 5px;
  font-size: 0.75em;

  .appavatar {
    opacity: 0.5;
  }
  &.active .appavatar {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    &.notclickable {
      cursor: default;
    }
  }
`;

const EmailList = styled.pre`
  width: 100%;
  white-space: pre-wrap;
  background-color: #eee;
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 10px;
  max-height: 150px;
  line-height: 1.4;
`;

const Communication = () => {
  /**
   * HOOKS
   */
  const [classroom, { students }, { refreshClassroom, refreshStudents }] = useCurrentClassroom();
  const { fetchAll } = useEntities();
  const [{ toast }] = useToast();

  /**
   * STATES
   */
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [emailsByStudents, setEmailByStudents] = useState<emailByStudents | undefined>(undefined);
  const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);

  /**
   * REFRESH
   */
  const handleRefresh = useCallback(async () => {
    await refreshClassroom(classroom['@id']);
    await refreshStudents(classroom['@id']);

    // refresh emails
    const emailByStudents: emailByStudents[] = await Promise.all(
      students.map(async (student: StudentType): Promise<emailByStudents> => {
        // fetch parents
        const parents = await fetchAll(`/student_parents?student=${student['@id']}`);
        const parentsEmails: string[] = [];
        parents.map((parent: ParentType) => {
          if (parent.email) {
            parentsEmails.push(parent.email);
          }
          return undefined;
        }, []);

        return {
          [student['@id']]: {
            student: student.email ? [student.email] : [],
            parents: parentsEmails,
          },
        };
      })
    );
    const consolidedData: any = deepmerge.all(emailByStudents);
    setEmailByStudents(consolidedData);
  }, [classroom, fetchAll, refreshClassroom, refreshStudents, students]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  /**
   * MEMOS
   */
  const emailsToSend = useMemo(() => {
    const emails: string[] = [];
    selectedStudents.map((student: string) => {
      const e = emailsByStudents[student];
      if (e.parents.length > 0) {
        e.parents.map((v) => {
          emails.push(v);
          return undefined;
        });
        return undefined;
      }

      if (e.student.length > 0) {
        e.student.map((v) => {
          emails.push(v);
          return undefined;
        });
      }

      return undefined;
    });
    return emails;
  }, [emailsByStudents, selectedStudents]);

  /**
   * HANDLERS
   */
  const handleSendMailToParents = useCallback(async () => {
    window.open('mailto:' + emailsToSend.join(';'), '_blank');
  }, [emailsToSend]);

  const handleSelectStudent = useCallback(
    (studentId: string) => {
      const newSelectedStudent: string[] = [];
      if (!selectedStudents.includes(studentId)) {
        selectedStudents.map((studentId: string) => {
          newSelectedStudent.push(studentId);
          return undefined;
        });
        newSelectedStudent.push(studentId);
      } else {
        selectedStudents.map((s: string) => {
          if (studentId !== s) {
            newSelectedStudent.push(s);
          }
          return undefined;
        });
      }
      setSelectedStudents(newSelectedStudent);
    },
    [selectedStudents]
  );

  const handleSelectAll = useCallback(() => {
    const newSelectedStudent: string[] = [];
    students.map((student: StudentType) => {
      if (
        emailsByStudents[student['@id']].parents.length === 0 &&
        emailsByStudents[student['@id']].student.length === 0
      ) {
        return undefined;
      }
      newSelectedStudent.push(student['@id']);
      return undefined;
    });
    setSelectedStudents(newSelectedStudent);
  }, [emailsByStudents, students]);

  const handleDeselectAll = useCallback(() => {
    setSelectedStudents([]);
  }, []);

  const handleCopyToClipBoard = useCallback(() => {
    navigator.clipboard
      .writeText(emailsToSend.join(' ; '))
      .then(() => {
        toast({
          message: 'La liste a été copiée dans le presse-papier',
          type: 'success',
        });
      })
      .catch(() => {
        toast({
          message: 'Impossible de copier dans le presse-papier',
          type: 'danger',
        });
      });
  }, [emailsToSend, toast]);

  /**
   * RENDER
   */
  if (!classroom || !students || !emailsByStudents) {
    return <Loader />;
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Communication</Card.Title>
          <Card.Text className="mt-3">
            Cette fonctionnalité vous permet d'envoyer un message à tous les parents.
          </Card.Text>
          <Card.Text>Si vous n'avez pas rempli les coordonnées des parents, l'email de l'élève sera utilisé.</Card.Text>
          <Card.Text>
            {students &&
              students.map((student: StudentType) => (
                <StudentSelector
                  key={student['@id']}
                  className={`${
                    emailsByStudents[student['@id']].parents.length === 0 &&
                    emailsByStudents[student['@id']].student.length === 0
                      ? 'notclickable'
                      : ''
                  } ${selectedStudents.includes(student['@id']) ? 'active' : ''}`}
                  onClick={() => {
                    if (
                      emailsByStudents[student['@id']].parents.length === 0 &&
                      emailsByStudents[student['@id']].student.length === 0
                    ) {
                      return;
                    }
                    handleSelectStudent(student['@id']);
                  }}
                >
                  <StudentAvatar student={student} size={100} shape={'rounded'} />
                  <div className="text-center">
                    {(emailsByStudents[student['@id']].parents.length > 0 && (
                      <>{emailsByStudents[student['@id']].parents.length} email(s) parent</>
                    )) ||
                      (emailsByStudents[student['@id']].student.length > 0 && (
                        <>{emailsByStudents[student['@id']].student.length} email élève</>
                      )) || <>Aucun email</>}
                  </div>
                </StudentSelector>
              ))}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Row className="justify-content-between align-items-center">
            <Col xs={'auto'}>
              <div style={{ fontWeight: 500 }}>
                {(selectedStudents.length > 0 && `${selectedStudents.length} élève(s) sélectionné(s)`) ||
                  'Aucun élève sélectionné'}{' '}
              </div>
              <div className="mt-2 small">
                <a onClick={handleSelectAll} href="#top">
                  Sélectionner tout
                </a>{' '}
                /{' '}
                <a onClick={handleDeselectAll} href="#top">
                  Désélectionner tout
                </a>
              </div>
            </Col>
            <Col xs={'auto'} className="ml-auto">
              <Button disabled={selectedStudents.length === 0} onClick={() => setIsEmailListModalOpen(true)}>
                Voir la liste des emails
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>

      <Modal show={isEmailListModalOpen} onHide={() => setIsEmailListModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Liste des emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            La liste ci-dessous contient les emails correspondant aux élèves sélectionnés, vous n'avez plus qu'à la
            copier/coller dans votre client email.
          </p>
          <EmailList>{emailsToSend.join(' ; ')}</EmailList>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleCopyToClipBoard}>
            Copier dans le presse-papier
          </Button>
          <Button disabled={selectedStudents.length === 0} onClick={handleSendMailToParents}>
            Envoyer un email
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Communication;
