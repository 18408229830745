import React, { ComponentType, useState, useCallback, useRef, useMemo } from 'react';
import { useCurrentClassroom } from 'hooks';
import { MediaType } from 'types';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import useEntities from 'hooks/useEntities';
import { convertToB64 } from 'helpers/image';
import { MediaInputItem } from 'components/form/input/MediaInput';
import ObservationInput from 'components/ObservationInput';
import ObservationList from 'components/ObservationList';
import { ProgressiveLoadingRef } from 'components/ProgressiveLoadingComponent';

const ClassroomObservations: ComponentType = () => {
  // HOOKS
  const [classroom] = useCurrentClassroom();
  const { postEntity } = useEntities();

  // REF
  const observationListRef = useRef<ProgressiveLoadingRef>();

  // STATES
  const [addError, setAddError] = useState<ApiError | undefined>();

  // HANDLERS
  const handleAdd = useCallback(
    async (values, helpers) => {
      if (!classroom) {
        return;
      }

      try {
        const entity = await postEntity(`/observations`, {
          classroom: classroom['@id'],
          ...values,
        });

        observationListRef.current.appendEntity(entity);
        observationListRef.current.refreshAllBackground();
        helpers.resetForm();
      } catch (err) {
        setAddError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [classroom, postEntity]
  );

  const handleEdit = useCallback((entity: any) => {
    observationListRef.current.refreshEntity(entity);
    observationListRef.current.refreshAllBackground();
  }, []);

  const handleDelete = useCallback((id: string) => {
    observationListRef.current.deleteEntity(id);
  }, []);

  const handleAddMedia = useCallback(
    async (media: MediaInputItem) => {
      if (!classroom) {
        throw new Error('Classroom must be defined to add media');
      }
      if (!media.blob) {
        throw new Error('Cannot add media without blob');
      }
      return await postEntity<MediaType>(`/media`, {
        classroom: classroom ? classroom['@id'] : undefined,
        type: 'image',
        mediaData: await convertToB64(media.blob),
      });
    },
    [classroom, postEntity]
  );

  const handleCancel = useCallback(() => {
    setAddError(undefined);
  }, []);

  // MEMO
  const observationsUrl = useMemo(() => {
    if (!classroom) {
      return;
    }
    return `/observations?classroom=${classroom['@id']}&order[date]=desc`;
  }, [classroom]);

  return (
    <>
      {classroom?.myAcl.observationAcl === 'full-access' && (
        <ObservationInput onCancel={handleCancel} onSubmit={handleAdd} onAddMedia={handleAddMedia} error={addError} />
      )}

      {observationsUrl && (
        <ObservationList
          listRef={observationListRef}
          baseURL={observationsUrl}
          onEdit={classroom?.myAcl.observationAcl === 'full-access' && handleEdit}
          onDelete={classroom?.myAcl.observationAcl === 'full-access' && handleDelete}
        />
      )}
    </>
  );
};

export default ClassroomObservations;
