import React, { ComponentType } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Form, Col, Row } from 'react-bootstrap';
import FormError from 'components/form/FormError';
import ButtonWithIcon from 'components/ButtonWithIcon';
import useConfig from 'hooks/useConfig';
import { Link } from 'react-router-dom';
import { ApiError } from 'helpers/apiError';

const FormInitValues = {
  email: '',
  password: '',
};

type FormValues = typeof FormInitValues;

const FormSchema = Yup.object().shape({
  email: Yup.string().email().required('Ne peut être vide'),
  password: Yup.string().required('Ne peut être vide'),
});

type LogInFormProps = {
  onSubmit: (arg1: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  error?: ApiError | undefined;
};

const LogInForm: ComponentType<LogInFormProps> = ({ onSubmit, error }) => {
  /**
   * CONFIG
   */
  const defaultEmail = useConfig((config) => config.defaultEmail);
  const defaultPassword = useConfig((config) => config.defaultPassword);

  return (
    <Formik
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={{
        ...FormInitValues,
        ...{
          email: defaultEmail,
          password: defaultPassword,
        },
      }}
      validationSchema={FormSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}
          <Form.Group>
            <Form.Label>Adresse email</Form.Label>
            <Form.Control
              tabIndex={1}
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ width: '100%' }}>
              <Row className={'g-0'}>
                <Col>Mot de passe</Col>
                <Col xs={'auto'}>
                  <Link to={`/password_lost`} tabIndex={4}>
                    <small className="text-secondary">Mot de passé oublié ?</small>
                  </Link>
                </Col>
              </Row>
            </Form.Label>
            <Form.Control
              tabIndex={2}
              type="password"
              name="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mt-3 d-grid gap-2">
            <ButtonWithIcon
              tabIndex={3}
              type={'submit'}
              disabled={isSubmitting}
              variant={'primary'}
              isSubmitting={isSubmitting}
            >
              Je me connecte
            </ButtonWithIcon>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LogInForm;
