import EXIF from 'exif-js';
import moment from 'moment';

export const getExifDate = (file: File) => {
  return new Promise<Date | undefined>((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (x) => {
      const image = document.createElement('img');
      image.src = (x.target as any).result;
      image.onload = () => {
        //@ts-ignore
        EXIF.getData(image, () => {
          const date = EXIF.getTag(image, 'DateTimeOriginal');
          if (date) {
            resolve(moment(date, 'YYYY:MM:DD HH:mm:ss').toDate());
          }
          resolve(undefined);
        });
      };
      image.onerror = (e) => {
        resolve(undefined);
      };
    };
    fr.onerror = (e) => {
      resolve(undefined);
    };
    fr.readAsDataURL(file);
  });
};

export const resizeImage = async (file: File, maxSize = 1280): Promise<Blob> => {
  const reducedFile = await ((file2: Blob): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (x) => {
        const img = new Image();
        img.src = (x.target as any).result;
        img.onload = async () => {
          let scaleFactor = 1;
          const elem = document.createElement('canvas');
          if (img.height > img.width) {
            elem.height = Math.min(maxSize, img.height);
            scaleFactor = elem.height / img.height;
            elem.width = img.width * scaleFactor;
          } else {
            elem.width = Math.min(maxSize, img.width);
            scaleFactor = elem.width / img.width;
            elem.height = img.height * scaleFactor;
          }
          const ctx = elem.getContext('2d');
          if (!ctx) {
            throw new Error('Unable to instanciate context');
          }
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob(
            async (blob: any) => {
              resolve(blob);
            },
            'image/jpeg',
            1
          );
        };
        // on error : fallback to real file
        img.onerror = (err) => {
          resolve(file);
        };
      };
      fr.readAsDataURL(file2);
    });
  })(file);

  return reducedFile;
};

export const convertToB64 = async (file: Blob) => {
  const base64data = await ((file2: Blob) => {
    return new Promise<any>((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (x) => resolve(fr.result);
      fr.readAsDataURL(file2);
    });
  })(file);

  if (!base64data) {
    throw new Error('Unable to transform to base64');
  }

  return base64data;
};

export const resizeImageAndConvertToB64 = async (file: File, maxSize = 2000): Promise<string> => {
  const reducedFile = await resizeImage(file, maxSize);
  const base64data = await convertToB64(reducedFile);
  return base64data;
};
