import React, { ComponentType } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ButtonWithIcon from 'components/ButtonWithIcon';
import FormError from 'components/form/FormError';
import PartTitle from 'components/PartTitle';
import { ApiError } from 'helpers/apiError';

const FormInitValues = {
  givenName: '',
  familyName: '',
  email: '',
  password: '',
  passwordconfirm: '',
  terms: false,
  newsletterAccepted: false,
};

type FormValues = typeof FormInitValues;

const FormSchema = Yup.object().shape({
  givenName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .required('Ne peut être vide'),
  familyName: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 2 caractères')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .required('Ne peut être vide'),
  email: Yup.string().email('Doit être un email valide').required('Ne peut être vide'),
  password: Yup.string()
    .required('Ne peut être vide')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
      'Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule et un caractère spécial'
    ),
  passwordconfirm: Yup.string()
    .required('Ne peut être vide')
    .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent être identiques'),
  terms: Yup.bool().oneOf([true], 'Vous devez accepter les conditions pour pousuivre'),
  newsletterAccepted: Yup.bool(),
});

type RegisterFormProps = {
  onSubmit: (arg1: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  error?: ApiError | undefined;
};

const RegisterForm: ComponentType<RegisterFormProps> = ({ onSubmit, error }) => {
  return (
    <Formik
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={FormInitValues}
      validationSchema={FormSchema}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}

          <PartTitle title="Identifiants" />
          <Form.Group>
            <Form.Control
              type="email"
              name="email"
              placeholder={'Adresse email'}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.email && !errors.email}
              isInvalid={touched.email && !!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>

          <Row className={'mt-1 mb-4 g-2'}>
            <Form.Group as={Col}>
              <Form.Control
                type="password"
                name="password"
                placeholder={'Mot de passe'}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Control
                type="password"
                name="passwordconfirm"
                placeholder={'Confirmer mot de passe'}
                value={values.passwordconfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.passwordconfirm && !errors.passwordconfirm}
                isInvalid={touched.passwordconfirm && !!errors.passwordconfirm}
              />
              <Form.Control.Feedback type="invalid">{errors.passwordconfirm}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <PartTitle title="Informations personnelles" />
          <Form.Group className={'mb-2'}>
            <Form.Control
              type="text"
              name="givenName"
              placeholder={'Prénom'}
              value={values.givenName}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.givenName && !errors.givenName}
              isInvalid={touched.givenName && !!errors.givenName}
            />
            <Form.Control.Feedback type="invalid">{errors.givenName}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              name="familyName"
              placeholder={'Nom de famille'}
              value={values.familyName}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.familyName && !errors.familyName}
              isInvalid={touched.familyName && !!errors.familyName}
            />
            <Form.Control.Feedback type="invalid">{errors.familyName}</Form.Control.Feedback>
          </Form.Group>

          <div style={{ fontSize: '0.9em' }}>
            <Form.Group className={'mt-4'} controlId="checkboxnewsletter">
              <Form.Check
                type={'checkbox'}
                required
                name="newsletterAccepted"
                label={<>Je souhaite être informé par email de l'actualité d'Elevapp</>}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.newsletterAccepted && !!errors.newsletterAccepted}
                feedback={errors.newsletterAccepted}
              />
            </Form.Group>
            <Form.Group className={'mt-2'} controlId="checkboxterms">
              <Form.Check
                type={'checkbox'}
                required
                name="terms"
                label={
                  <>
                    J'ai lu et j'accepte les{' '}
                    <Link to="/terms" target="_blank">
                      conditions générales d'utilisation
                    </Link>
                  </>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.terms && !!errors.terms}
                feedback={errors.terms}
              />
            </Form.Group>
          </div>

          <div className="mt-3 d-grid gap-2">
            <ButtonWithIcon type={'submit'} variant={'primary'} isSubmitting={isSubmitting}>
              Créer mon compte
            </ButtonWithIcon>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
