import React, { ComponentType } from 'react';
import ProgressiveMediasGallery from 'components/ProgressiveMediasGallery';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';

const StudentMedias: ComponentType = () => {
  /**
   * HOOKS
   */
  const [classroom] = useCurrentClassroom();
  const [student] = useCurrentStudent();

  return (
    <>
      {student && (
        <ProgressiveMediasGallery
          classroom={classroom}
          student={student}
          baseurl={`/media?student=${student?.['@id']}&order[date]=desc`}
          enableEditAndDelete={classroom && classroom.myAcl.mediaAcl === 'full-access'}
        />
      )}
    </>
  );
};

export default StudentMedias;
