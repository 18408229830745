import React, { ComponentType, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const animationDuration = 200;

const SOverlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
  padding-top: 40%;
  border-radius: 3px;
  background-color: rgba(230, 230, 230, 0.3);
  transition: all ${animationDuration}ms ease-in-out;

  &.v-dark {
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
  }

  &.show {
    opacity: 1;
  }
`;

type LoadingOverlayProps = {
  visible: boolean;
  variant?: 'light' | 'dark';
  loader?: boolean;
};

const LoadingOverlay: ComponentType<LoadingOverlayProps> = ({ visible, variant, loader }) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [cssVisibilty, setCssVisibility] = useState<'hide' | 'show'>('hide');

  useEffect(() => {
    if (visible) {
      setIsVisible(true);
      const v = setTimeout(() => setCssVisibility('show'), 10);
      return () => {
        clearTimeout(v);
      };
    } else {
      setCssVisibility('hide');

      const v = setTimeout(() => setIsVisible(false), animationDuration + 10);
      return () => {
        clearTimeout(v);
      };
    }
  }, [visible]);

  return (
    <>
      {isVisible && (
        <SOverlay className={`${variant === 'dark' ? 'v-dark' : 'v-light'} ${cssVisibilty}`}>
          {loader && <Loader />}
        </SOverlay>
      )}
    </>
  );
};

export default LoadingOverlay;
