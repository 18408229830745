import React, { ComponentType, useState, useCallback } from 'react';
import useApiClient from 'hooks/useApiClient';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router';
import { useToast } from 'hooks';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { Row, Col, Card, Container } from 'react-bootstrap';
import PasswordLostConfirmForm from './components/PasswordLostConfirmForm';
import ConfirmIdentity from 'components/ConfirmIdentity';
import { Link } from 'react-router-dom';

const PasswordLost: ComponentType = () => {
  // HOOKS
  const client = useApiClient();
  const navigate = useNavigate();
  const [{ toast }] = useToast();

  // STATES
  const [identityRequestToken, setIdentityRequestToken] = useState('');
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<ApiError | undefined>();

  // HANDLERS
  const handleSubmitNewPassword = useCallback(
    async (values: any, helpers: FormikHelpers<any>) => {
      try {
        await client.post(`/password_reset`, {
          identityRequestToken,
          vaultRecoverSecret: values.vaultRecoverSecret,
          password: values.password,
        });
        toast({
          type: 'success',
          title: 'Votre mot de passe a été modifié',
          message: 'Vous pouvez maintenant vous connecter.',
        });
        navigate(`/signin`);
      } catch (err) {
        setError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [client, identityRequestToken, toast, navigate]
  );

  return (
    <Container className={'marginauto'}>
      <Row className={'mt-5 justify-content-center'}>
        <Col sm={10} md={8} lg={6} xl={5} xxl={4}>
          <Card>
            <Card.Body>
              {step === 0 && (
                <>
                  <Card.Title>Mot de passe perdu</Card.Title>
                  <ConfirmIdentity
                    onSuccess={(identityToken) => {
                      setIdentityRequestToken(identityToken);
                      setStep(1);
                    }}
                  />
                </>
              )}
              {step === 1 && (
                <>
                  <Card.Title>Nouveau mot de passe</Card.Title>
                  <PasswordLostConfirmForm error={error} onSubmit={handleSubmitNewPassword} />
                </>
              )}
            </Card.Body>
          </Card>

          <p className={'text-center text-muted'}>
            <Link to={'/signin'}>Retour à l'identification</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordLost;
