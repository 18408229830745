import CardItem from 'components/CardItem';
import StudentAvatar from 'components/StudentAvatar';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';
import useEntities from 'hooks/useEntities';
import React, { ComponentType } from 'react';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { StudentType } from 'types';

const SCArdItem = styled(CardItem)`
  opacity: 1;

  &.isDragging {
    opacity: 0.5;
  }
`;

type StudentItemProps = {
  student?: StudentType;
};

const StudentItem: ComponentType<StudentItemProps> = ({ student }) => {
  /** HOOKS */
  const navigate = useNavigate();
  const [, , { init: initStudent }] = useCurrentStudent();
  const [classroom, , { refreshStudents, setIsStudentReloading }] = useCurrentClassroom();
  const { editEntity } = useEntities();

  /**
   * DRAG N DROP
   */
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'student',
    item: { id: student['@id'] },
    end: async (item, monitor) => {
      // ignore if insuffisant acl
      if (classroom?.myAcl.studentAcl !== 'full-access') {
        return;
      }
      const dropResult = monitor.getDropResult<{ id: string }>();
      if (item && dropResult) {
        if (student.group === dropResult.id) {
          return;
        }
        setIsStudentReloading(true);
        await editEntity(student['@id'], { group: dropResult.id !== 'empty' ? dropResult.id : null });
        await refreshStudents(student.classroom['@id']);
        setIsStudentReloading(false);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <SCArdItem
      ref={drag}
      role="Box"
      data-testid={`box-${student['@id']}`}
      onClick={async () => {
        initStudent(student['@id'], student);
        navigate(`/classrooms/${student?.classroom?.id}/students/${student.id}`);
      }}
      image={<StudentAvatar student={student} size={'100%'} shape={'rounded'} />}
      title={`${student.givenName} ${student.familyName.substr(0, 1)}.`}
      className={`${isDragging ? 'isDragging' : ''} `}
    />
  );
};

export default StudentItem;
