import React, { ComponentType, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import useEntities from 'hooks/useEntities';
import { resizeImageAndConvertToB64, getExifDate } from 'helpers/image';
import { ClassroomType, StudentType } from 'types';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { useUploadBox } from 'hooks';
import { UploadError } from 'hooks/useUploadBox';

type UploadMediaProps = {
  handleRefresh: () => void;
  onAdd?: (entity: any) => void;
  hideAddButton?: boolean;
  ref?: any;
  student?: StudentType;
  classroom?: ClassroomType;
  size?: 'sm' | 'lg';
  variant?: 'primary' | 'danger' | 'secondary' | 'success' | 'warning' | 'link';
};

const UploadMedia: ComponentType<UploadMediaProps> = forwardRef(
  ({ handleRefresh, hideAddButton, variant, onAdd, student, classroom, size }, ref) => {
    useImperativeHandle(ref, () => ({
      refreshAll: handleRefresh,
      handleAdd: () => uploadButtonRef.current.click(),
    }));

    // HOOKS
    const { postEntity } = useEntities();
    const uploadButtonRef = useRef<any>(null);
    const [{ setVisible: setIsUploadingCardVisible, setFilesSent, setTotalFilesToSend, setErrors }] = useUploadBox();

    // STATES
    const [isUploading, setIsUploading] = useState<boolean>(false);

    // HANDLERS
    const handleUploadMedia = useCallback(
      async (file: File) => {
        const base64data = await resizeImageAndConvertToB64(file);

        try {
          const entity = await postEntity(`/media`, {
            classroom: student ? student.classroom['@id'] : classroom?.['@id'],
            type: 'image',
            mediaData: base64data,
            student: student?.['@id'],
            date: await getExifDate(file),
          });

          onAdd && onAdd(entity);
        } catch (err) {
          try {
            throw new Error(err.response.data['hydra:description']);
          } catch (e) {
            throw new Error('Erreur inconnue');
          }
        }
      },
      [classroom, onAdd, postEntity, student]
    );

    const handleAdd = useCallback(
      async (event: any) => {
        const files = event.target.files;
        setIsUploading(true);
        setErrors([]);
        setFilesSent(0);
        setTotalFilesToSend(files.length);
        setIsUploadingCardVisible(true);

        const uploadErrors: UploadError[] = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          try {
            await handleUploadMedia(file);
          } catch (err) {
            uploadErrors.push({
              filename: file.name,
              error: err.message,
            });
            setErrors(uploadErrors);
          }
          setFilesSent(i + 1);
        }

        await handleRefresh();
        setIsUploading(false);
      },
      [setErrors, setFilesSent, setTotalFilesToSend, setIsUploadingCardVisible, handleRefresh, handleUploadMedia]
    );

    return (
      <>
        <input
          ref={uploadButtonRef}
          type={'file'}
          accept={'image/*'}
          multiple={true}
          onChange={handleAdd}
          onAbort={() => setIsUploading(false)}
          className={'d-none'}
          disabled={isUploading}
        />

        {!hideAddButton && (
          <div className={'mb-3'}>
            <ButtonWithIcon
              onClick={() => {
                uploadButtonRef.current && uploadButtonRef.current.click();
              }}
              disabled={isUploading}
              className={'my-0'}
              size={size}
              variant={variant}
            >
              Ajouter des photos
            </ButtonWithIcon>
          </div>
        )}
      </>
    );
  }
);

export default UploadMedia;
