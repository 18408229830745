import { useUploadBox } from 'hooks';
import React, { ComponentType, useEffect, useState } from 'react';
import { Card, Row, Col, ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';

const autoCloseDelay = 3000;

const UploadingCard = styled(Card)`
  position: fixed;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  background-color: white !important;
  opacity: 0;

  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15) !important;
  width: 350px;
  max-width: 90%;

  .card-title {
    font-size: 1.03em;
    margin-bottom: 5px;
    font-weight: 500;
    text-align: left;
  }

  transition: 0.3s ease-in-out;
  bottom: -200px;

  &.visible {
    opacity: 1;
    bottom: 10px;
  }
`;

const UploadBox: ComponentType = () => {
  /**
   * HOOKS
   */
  const [
    { setFilesSent, setTotalFilesToSend, setErrors, setVisible },
    { visible, filesSent, totalFilesToSend, errors },
  ] = useUploadBox();

  /**
   * STATES
   */
  const [hover, setHover] = useState(false);

  /**
   * EFFECTS
   */
  // Reset counters if not display uploading card
  useEffect(() => {
    if (!visible) {
      setFilesSent(0);
      setTotalFilesToSend(0);
      setErrors(undefined);
    }
  }, [setErrors, setFilesSent, setTotalFilesToSend, visible]);

  // Close uploading card if not uploading
  useEffect(() => {
    if (!hover && visible && filesSent === totalFilesToSend && (!errors || errors.length === 0)) {
      const t = setTimeout(() => {
        setVisible(false);
      }, autoCloseDelay);
      return () => clearInterval(t);
    }
  }, [errors, filesSent, hover, setVisible, totalFilesToSend, visible]);

  return (
    <UploadingCard
      className={visible ? ' visible' : ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>{filesSent === totalFilesToSend ? 'Envoi terminé' : 'Envoi en cours ...'}</Col>
            <Col xs={'auto'}>
              <small>
                {Math.min(filesSent + 1, totalFilesToSend)} / {totalFilesToSend}
              </small>
            </Col>
          </Row>
        </Card.Title>
        <Card.Text as={'div'} className={'mb-2 text-center'}>
          {totalFilesToSend > 0 && (
            <ProgressBar
              variant={
                filesSent === totalFilesToSend ? (errors && errors.length > 0 ? 'danger' : 'success') : undefined
              }
              now={(filesSent / totalFilesToSend) * 100}
              style={{ height: '0.5rem' }}
            />
          )}
        </Card.Text>
        {errors && errors.length > 0 && (
          <Card.Text as={'div'} className={'small'}>
            <strong className={'text-danger'}>{errors.length} erreur(s) :</strong>
            <ul>
              {errors?.map((error, idx) => (
                <li key={idx}>
                  <strong>{error.filename}</strong> : {error.error}
                </li>
              ))}
            </ul>
          </Card.Text>
        )}
      </Card.Body>
    </UploadingCard>
  );
};

export default UploadBox;
