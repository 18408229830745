import React, { useMemo, forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { ClassroomType } from 'types';
import FormError from 'components/form/FormError';
import AvatarInput from 'components/form/input/AvatarInput';
import styled from 'styled-components';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/Loader';
import { ApiError } from 'helpers/apiError';
import { useGlobal } from 'hooks';

const BannerContainer = styled.div`
  position: relative;
  background-size: cover;
  height: 150px;
  width: 100%;
  background-color: #eee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  margin-bottom: 10px;
`;

const ChangeBannerButton = styled.div`
  font-size: 0.6em;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
`;

const SFormCheck = styled(Form.Check)`
  padding-left: 0;
`;
const SFormCheckInput = styled(Form.Check.Input)`
  position: absolute;
  top: 7px;
  left: 25px;
  z-index: 100;
  display: none;
`;

const SFormCheckLabel = styled(Form.Check.Label)`
  width: 100%;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-color: #eee;
  border-radius: 10px;
  transition: 0.2s;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const availableInterval = 15;
const currDate = new Date();
const currYear = currDate.getFullYear();

const FormInitValues = {
  name: '',
  banner: undefined,
  schoolYear: currDate.getMonth() > 6 ? currYear : currYear - 1,
  establishment: undefined,
  archived: false,
};

type FormValues = {
  name: string;
  establishment?: string | undefined;
  schoolYear?: number | undefined;
  avatarData?: string | undefined;
  banner: number;
  archived: boolean;
};

const FormSchema = Yup.object().shape({
  name: Yup.string().trim().max(255, 'Doit contenir au maximum 32 caractères'),
  establishment: Yup.string()
    .max(40, 'Doit contenir au maximum 40 caractères')
    .trim()
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  schoolYear: Yup.number().required('Ne peut être vide'),
  archived: Yup.bool(),
  banner: Yup.number().required('Ne peut être vide'),
});

type ClassroomFormModalProps = {
  entity?: ClassroomType;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  onCancel?: () => void;
  error?: ApiError | undefined;
};

const ClassroomForm = forwardRef(({ entity, onSubmit, onCancel, error }: ClassroomFormModalProps, ref: any) => {
  // REF
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
  }));

  // HOOKS
  const formRef = useRef<any | null>(null);
  const [{ banners }] = useGlobal();

  // STATES
  const [bannerChoiceVisible, setBannerChoiceVisible] = useState(false);

  // MEMO
  const defaultBanner = useMemo(() => Math.floor(Math.random() * 12), []);

  const initvalues = useMemo(() => {
    return {
      ...FormInitValues,
      ...{
        banner: defaultBanner,
      },
      ...(entity
        ? {
            name: entity.name,
            banner: entity?.banner?.id,
            establishment: entity.establishment,
            schoolYear: entity.schoolYear,
            archived: entity.archived,
          }
        : {}),
    };
  }, [defaultBanner, entity]);

  return (
    <Formik
      innerRef={(ref) => {
        formRef.current = ref;
      }}
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={initvalues}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldValue,
        setFieldError,
      }: FormikProps<FormValues>) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            {error && <FormError error={error} />}
            <div className={!bannerChoiceVisible && 'd-none'}>
              <Button
                variant={'link'}
                style={{ marginTop: 0 }}
                size={'sm'}
                onClick={() => setBannerChoiceVisible(false)}
              >
                <FontAwesomeIcon icon={'chevron-left'} /> Retour
              </Button>
              <Row className={'g-0'}>
                {!banners && <Loader />}
                {banners?.map((v) => (
                  <Col xs={4} key={v.id} className={'px-1'}>
                    <SFormCheck type={'radio'} id={`banner-check-${v.id}`}>
                      <SFormCheckInput
                        type={'radio'}
                        name={'banner'}
                        isValid
                        onChange={(e) => {
                          setFieldValue('banner', v.id);
                          setBannerChoiceVisible(false);
                        }}
                        value={v.id}
                        checked={v.id === values.banner}
                      />
                      <SFormCheckLabel style={{ backgroundImage: `url(${v.url})` }} />
                    </SFormCheck>
                  </Col>
                ))}
              </Row>
            </div>
            <div className={bannerChoiceVisible && 'd-none'}>
              <BannerContainer
                style={{
                  backgroundImage: `url(${banners?.[values.banner]?.url})`,
                }}
              >
                <div style={{ position: 'relative', marginBottom: 30, marginTop: 10 }}>
                  <AvatarInput
                    avatarStyle={{ border: '2px solid white', height: 110, width: 110 }}
                    alt={values?.name?.substr(0, 1)}
                    value={entity?.avatar && entity.avatar.thumbnail}
                    onChange={(val) => setFieldValue('avatarData', val)}
                    onError={(err) => {
                      setFieldError('avatarData', err);
                    }}
                    isInvalid={!!errors.avatarData}
                  />
                </div>
                <ChangeBannerButton onClick={() => setBannerChoiceVisible(true)}>
                  Changer la couverture
                </ChangeBannerButton>
              </BannerContainer>
              <Form.Control.Feedback type="invalid">{errors.avatarData}</Form.Control.Feedback>

              <Row className={'align-items-center g-2'}>
                <Form.Group as={Col} xs={8}>
                  <Form.Label className={'text-truncate w-100'}>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder={'ex : CM1 Dupont'}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={4}>
                  <Form.Label className={'text-truncate w-100'}>Année scolaire</Form.Label>
                  <Form.Select
                    name="schoolYear"
                    value={values.schoolYear}
                    onChange={(e) => setFieldValue('schoolYear', parseInt(e.target.value))}
                    onBlur={handleBlur}
                    isInvalid={touched.schoolYear && !!errors.schoolYear}
                  >
                    {[...new Array(availableInterval)].map((v, idx) => (
                      <option key={idx} value={currYear - availableInterval + idx}>
                        {currYear - availableInterval + idx}
                      </option>
                    ))}
                    {[...new Array(availableInterval)].map((v, idx) => (
                      <option key={idx} value={currYear + idx}>
                        {currYear + idx}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.schoolYear}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className={'mt-2'}>
                <Form.Label>Établissement</Form.Label>
                <Form.Control
                  type="text"
                  name="establishment"
                  maxLength={40}
                  placeholder={'ex : École Jean Jaurés, Lomme'}
                  value={values.establishment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.establishment && !!errors.establishment}
                />
                <Form.Control.Feedback type="invalid">{errors.establishment}</Form.Control.Feedback>
              </Form.Group>
              {entity && (
                <Form.Group controlId="checkboxarchived" className={'px-2 mt-4'}>
                  <Form.Check
                    name={'archived'}
                    type={'switch'}
                    label={`Archivée`}
                    checked={values.archived}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.archived && !!errors.archived}
                    feedback={errors.archived}
                  />
                </Form.Group>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
});

export default ClassroomForm;
