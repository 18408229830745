import React, { forwardRef } from 'react';
import styled from 'styled-components';

const SCard = styled.div`
  @media (min-width: 1200px) {
    width: 140px;
  }
  max-width: 140px;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const SImage = styled.div.attrs((props) => ({
  className: props.className + ' card-img-top',
}))`
  position: relative;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6%;
  aspect-ratio: 1 / 1;
  overflow: hidden;

  img {
    border-radius: 6%;
    width: 100%;
    height: 100%;
  }
`;

const SBody = styled.div`
  padding: 0;
  position: relative;
`;

const STitle = styled.div`
  text-align: center;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0;
  font-size: 0.9rem;
  font-weight: 600;
`;

const SubTitle = styled.div`
  text-align: center;
  margin-top: -0.2rem;
  padding-bottom: 0;
  font-size: 0.6rem;
`;

type CardItemProps = {
  ref: any;
  role?: any;
  image: JSX.Element;
  title: string;
  subtitle?: string;
  onClick?: () => void;
  style?: any;
  className?: string | undefined;
};

const CardItem = forwardRef(({ role, onClick, title, subtitle, image, style, className }: CardItemProps, ref: any) => {
  return (
    <SCard ref={ref} role={role} onClick={onClick} style={style} className={className}>
      <SImage>{image}</SImage>
      <SBody>
        <STitle className={'text-truncate'}>{title}</STitle>
        {subtitle && <SubTitle className={'text-muted'}>{subtitle}</SubTitle>}
      </SBody>
    </SCard>
  );
});

export default CardItem;
