import { useCurrentClassroom, useCurrentStudent } from 'hooks';
import React, { ComponentType } from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';

const StudentNavBar: ComponentType = () => {
  /** HOOKS */
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [classroom] = useCurrentClassroom();
  const [student] = useCurrentStudent();

  return (
    <Nav className={'tab-nav'} activeKey={pathname}>
      <Nav.Item>
        <Nav.Link
          eventKey={`/classrooms/${classroom.id}/students/${student.id}/informations`}
          onClick={() => navigate(`/classrooms/${classroom.id}/students/${student.id}/informations`)}
        >
          <span className={'d-none d-sm-inline'}>Informations</span>
          <span className={'d-sm-none'}>Infos</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey={`/classrooms/${classroom.id}/students/${student.id}/parents`}
          onClick={() => navigate(`/classrooms/${classroom.id}/students/${student.id}/parents`)}
        >
          Famille
        </Nav.Link>
      </Nav.Item>
      {classroom && classroom.myAcl.observationAcl !== 'forbidden' && (
        <Nav.Item>
          <Nav.Link
            eventKey={`/classrooms/${classroom.id}/students/${student.id}/observations`}
            onClick={() => navigate(`/classrooms/${classroom.id}/students/${student.id}`)}
          >
            <span className={'d-none d-sm-inline'}>Observations</span>
            <span className={'d-sm-none'}>Obs.</span>
          </Nav.Link>
        </Nav.Item>
      )}
      {classroom && classroom.myAcl.mediaAcl !== 'forbidden' && (
        <Nav.Item>
          <Nav.Link
            eventKey={`/classrooms/${classroom.id}/students/${student.id}/medias`}
            onClick={() => navigate(`/classrooms/${classroom.id}/students/${student.id}/medias`)}
          >
            Photos
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default StudentNavBar;
