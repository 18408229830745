import React, { ComponentType } from 'react';
import styled from 'styled-components';

const Title = styled.h6`
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 600;
`;

const FormPartTitle: ComponentType = ({ children, ...props }) => {
  return <Title {...props}>{children}</Title>;
};

export default FormPartTitle;
