import { useCurrentClassroom } from 'hooks';
import React, { ComponentType } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';

const ClassroomNavBar: ComponentType = () => {
  /** HOOKS */
  const { pathname } = useLocation();
  const [classroom] = useCurrentClassroom();
  const navigate = useNavigate();

  return (
    <Nav className={'tab-nav'} activeKey={pathname} id={'nav'}>
      <Nav.Item>
        <Nav.Link eventKey={`/classrooms/${classroom?.id}`} onClick={() => navigate(`/classrooms/${classroom?.id}`)}>
          Élèves
        </Nav.Link>
      </Nav.Item>
      {classroom && classroom.myAcl.observationAcl !== 'forbidden' && (
        <Nav.Item>
          <Nav.Link
            eventKey={`/classrooms/${classroom?.id}/observations`}
            onClick={() => navigate(`/classrooms/${classroom?.id}/observations`)}
          >
            <span className={'d-none d-sm-inline'}>Observations</span>
            <span className={'d-sm-none'}>Obs.</span>
          </Nav.Link>
        </Nav.Item>
      )}
      {classroom && classroom.myAcl.mediaAcl !== 'forbidden' && (
        <Nav.Item>
          <Nav.Link
            eventKey={`/classrooms/${classroom?.id}/medias`}
            onClick={() => navigate(`/classrooms/${classroom?.id}/medias`)}
          >
            Photos
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default ClassroomNavBar;
