import useApiClient from 'hooks/useApiClient';
import useConfig from 'hooks/useConfig';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components';

const Copyright = styled.p`
  font-size: 0.8em;
  cursor: default;
  user-select: none;
`;

const Footer: ComponentType = () => {
  /**
   * HOOKS
   */
  const appVersion = useConfig((config) => config.appVersion);
  const api = useApiClient();

  /**
   * STATES
   */
  const [displayVersion, setDisplayVersion] = useState(false);
  const [apiVersion, setApiVersion] = useState(undefined);

  /**
   * HANDLERS
   */
  const handleRefresh = useCallback(async () => {
    const response = await api.get('/status');
    setApiVersion(response?.data?.version ?? 'unknown');
  }, [api]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <footer className="mt-5 pt-5 small">
      <Container>
        <Nav className="justify-content-center">
          <Nav.Item>
            <LinkContainer to={`/terms`}>
              <a className="nav-link" href={`/terms`}>
                Conditions d'utilisation
              </a>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={`/legal`}>
              <a className="nav-link" href={`/legal`}>
                Mentions légales
              </a>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={`/privacy`}>
              <a className="nav-link" href={`/privacy`}>
                Politique de confidentialité
              </a>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={`/help`}>
              <a className="nav-link" href={`/help`}>
                Contactez-nous
              </a>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <a className="nav-link" href={`https://stats.uptimerobot.com/D8A1WFoOKR`}>
              Status
            </a>
          </Nav.Item>
        </Nav>
      </Container>
      <Container>
        <Copyright className={'mt-2 pb-4 text-muted text-center'} onDoubleClick={() => setDisplayVersion(true)}>
          © 2022 Thomas Glachant
          <br />
          {displayVersion && `app ${appVersion ?? 'unknown'} | api ${apiVersion ?? 'unknown'}`}
        </Copyright>
      </Container>
    </footer>
  );
};

export default Footer;
