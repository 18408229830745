import React, { ComponentType } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Loader from './Loader';

const SContainer = styled(Container)`
  padding-top: 20%;
  padding-bottom: 20%;
`;

const BigLoader: ComponentType = () => {
  return (
    <SContainer>
      <Loader variant={'primary'} />
    </SContainer>
  );
};

export default BigLoader;
