import React, { ComponentType, useState, useCallback, useRef } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { FormikHelpers } from 'formik';
import { useConfirm } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { FormRef } from 'helpers/form';
import { ApiError } from 'helpers/apiError';
import styled from 'styled-components';

const FormContainer = styled.div`
  .form-error {
    margin-top: -10px !important;
  }
`;

type FormModalProps = {
  show: boolean;
  onClose?: () => void;
  onSubmit: (values: any, helpers: FormikHelpers<any>) => Promise<void>;
  onDelete?: () => Promise<void>;
  entity?: any;
  error?: ApiError | undefined;
  title: string;
  size?: 'sm' | 'lg' | 'xl';
  form: (
    ref: any,
    onSubmit: (values: any, helpers: FormikHelpers<any>) => Promise<void>,
    error: ApiError | undefined,
    entity: any | undefined
  ) => JSX.Element;
  modalVisibilityHandler?: (arg1: boolean) => void;
};
const FormModal: ComponentType<FormModalProps> = ({
  show,
  onClose,
  onDelete,
  onSubmit,
  form,
  size,
  title,
  entity,
  error,
  modalVisibilityHandler,
}) => {
  // HOOKS
  const formRef = useRef<FormRef>();
  const [, setConfirm] = useConfirm();

  // STATES
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // HANDLERS
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    formRef && formRef.current && (await formRef.current.handleSubmit());
    setIsSubmitting(false);
  }, []);

  const handleDelete = useCallback(async () => {
    modalVisibilityHandler && modalVisibilityHandler(false);
    setConfirm({
      body: <>Souhaitez-vous réellement supprimer cet élément ? Cette action est irréversible.</>,
      handleConfirm: async () => {
        onDelete && onDelete();
      },
      handleCancel: () => {
        modalVisibilityHandler && modalVisibilityHandler(true);
      },
    });
  }, [modalVisibilityHandler, onDelete, setConfirm]);

  return (
    <Modal show={show} onHide={onClose} scrollable size={size}>
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormContainer>{form(formRef, onSubmit, error, entity)}</FormContainer>
      </Modal.Body>
      <Modal.Footer>
        {onDelete && (
          <Col xs={'auto'} className={'me-auto'}>
            <ButtonWithIcon variant="danger" onClick={handleDelete} disabled={isSubmitting}>
              <FontAwesomeIcon icon={'trash'} />
            </ButtonWithIcon>
          </Col>
        )}
        <Col xs={'auto'}>
          <ButtonWithIcon variant="link" className={'d-none d-sm-inline'} onClick={onClose} disabled={isSubmitting}>
            Annuler
          </ButtonWithIcon>
          <ButtonWithIcon
            className={'round'}
            type={'submit'}
            onClick={handleSubmit}
            variant="primary"
            isSubmitting={isSubmitting}
          >
            Enregistrer
          </ButtonWithIcon>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default FormModal;
