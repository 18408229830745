import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import ButtonWithIcon from 'components/ButtonWithIcon';
import Loader from 'components/Loader';
import FormModal from 'components/modal/FormModal';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { useCurrentClassroom, useToast } from 'hooks';
import useEntities from 'hooks/useEntities';
import { Row, Col } from 'react-bootstrap';
import { AclType } from 'types';
import AclForm from 'views/ClassroomConsult/views/Acls/components/AclForm';
import AclItem from './components/AclItem';
import EmptyMessageWithPicture from 'components/EmptyMessageWithPicture';
import { imgShares } from 'helpers/illustration';

const AclList: ComponentType = () => {
  // HOOKS
  const { postEntity } = useEntities();
  const [classroom, { acls }, { refreshAcls }] = useCurrentClassroom();
  const [{ toast }] = useToast();

  // STATES
  const [addVisible, setAddVisible] = useState(false);
  const [addError, setAddError] = useState<ApiError | undefined>();

  // REFRESH
  const handleRefresh = useCallback(async () => {
    if (!classroom) {
      return;
    }
    await refreshAcls(classroom['@id']);
  }, [refreshAcls, classroom]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  /**
   * HANDLERS
   */
  const handleAdd = useCallback(
    async (values, helpers) => {
      try {
        await postEntity(`/classroom_acls`, { classroom: classroom['@id'], ...values });

        await handleRefresh();
        setAddVisible(false);
        helpers.resetForm();
        toast({
          type: 'success',
          title: "C'est parti !",
          message: "Nous lui avons envoyé un email pour qu'il/elle puisse confirmer le partage.",
        });
      } catch (err) {
        setAddError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [classroom, handleRefresh, postEntity, toast]
  );

  const handleEdit = useCallback(
    async (entity: any) => {
      await refreshAcls(classroom.id);
    },
    [classroom.id, refreshAcls]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await refreshAcls(classroom.id);
    },
    [classroom.id, refreshAcls]
  );

  if (!classroom) {
    return <Loader />;
  }

  return (
    <>
      {(acls && acls.length > 0 && (
        <>
          <h5 style={{ marginBottom: 25 }}>Partages</h5>
          {acls.map((acl: AclType) => (
            <AclItem
              key={acl.id}
              acl={acl}
              onEdit={classroom?.myAcl.classroomAcl === 'full-access' && handleEdit}
              onDelete={classroom?.myAcl.classroomAcl === 'full-access' && handleDelete}
            />
          ))}
        </>
      )) || (
        <EmptyMessageWithPicture img={imgShares} title={'Partagez votre classe'}>
          <p>Invitez vos collègues, limitez les informations auquelles ils accédent.</p>
        </EmptyMessageWithPicture>
      )}
      <Row>
        <Col xs={'auto'} className={'ms-auto me-auto'}>
          <ButtonWithIcon onClick={() => setAddVisible(true)}>Inviter un collègue</ButtonWithIcon>
        </Col>
      </Row>

      <FormModal
        title={'Partager la classe'}
        show={addVisible}
        onSubmit={handleAdd}
        error={addError}
        onClose={() => {
          setAddVisible(false);
          setAddError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => <AclForm ref={ref} onSubmit={onSubmit} error={error} />}
      />
    </>
  );
};

export default AclList;
