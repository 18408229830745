import React, { ComponentType } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ConfirmBox from './components/ConfirmBox';
import ToastBox from './components/toast/ToastBox';
import 'react-image-lightbox/style.css';
import 'assets/scss/app.scss';
import moment from 'moment';
import 'moment/locale/fr';
import ScrollToTop from 'components/ScrollToTop';
import UploadBox from 'components/UploadBox';
import MainRouter from 'MainRouter';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { withCommonProviders, withRouterProviders } from 'providers';
import DeviceInfoBox from 'components/DeviceInfoBox';
moment.locale('fr');
library.add(fab, fas);

const MainComponent = withRouterProviders(() => <MainRouter />);

const App: ComponentType = withCommonProviders(() => {
  return (
    <>
      <DeviceInfoBox />
      <ToastBox />
      <UploadBox />
      <ConfirmBox />
      <DndProvider backend={HTML5Backend}>
        <Router>
          <ScrollToTop />
          <MainComponent />
        </Router>
      </DndProvider>
    </>
  );
});

export default App;
