import React, { ComponentType, useCallback, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useConnectedUser, useConfirm, useAuthentication, useToast } from 'hooks';
import PageContent from 'components/PageContent';
import AccountForm from 'views/Account/components/AccountForm';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import ButtonWithIcon from 'components/ButtonWithIcon';
import useApiClient from 'hooks/useApiClient';
import { useEffect } from 'react';

const Account: ComponentType = () => {
  // HOOKS
  const client = useApiClient();
  const { editEntity, deleteEntity } = useEntities();
  const [, setConfirm] = useConfirm();
  const [user, refreshUser] = useConnectedUser();
  const [{ toast }] = useToast();
  const [, { setToken }] = useAuthentication();

  // STATES
  const [editError, setEditError] = useState<ApiError | undefined>();
  const [isExportGenerating, setIsExportGenerating] = useState<boolean>(false);
  const [lastDateCheckExportGenerating, setLastDateCheckExportGenerating] = useState<number>(0);

  // REFRESH
  const handleRefresh = useCallback(async () => {
    await refreshUser();
  }, [refreshUser]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const handleRefreshExportStatus = useCallback(async () => {
    const resp = await client.get('grpd_export_requests?state[]=pending&state[]=processing');
    setIsExportGenerating(resp.data['hydra:member'].length > 0);
  }, [client]);

  const handleExportPersonalData = useCallback(async () => {
    setIsExportGenerating(true);
    try {
      await client.post('/grpd_export_requests', {
        owner: user['@id'],
      });

      toast({
        type: 'success',
        title: "C'est parti !",
        message: 'Un email vous sera envoyé une fois que vos données seront prêtes.',
      });
    } catch (error) {
      let errorMsg = error.response.data['hydra:description'] ?? 'Une erreur est survenue';
      if (errorMsg === 'owner: Vous avez déja un export en cours') {
        errorMsg = 'Vous avez déja un export en cours, veuillez ressayer plus tard';
      }

      toast({
        type: 'danger',
        title: 'Export impossible',
        message: errorMsg,
      });
    }
  }, [toast, client, user]);

  const handleEdit = useCallback(
    async (values, helpers) => {
      if (!user) {
        return;
      }

      try {
        await editEntity(user['@id'], {
          givenName: values.givenName,
          familyName: values.familyName,
          email: values.email,
          currentPassword: values.currentPassword,
          plainPassword: values.password,
          avatarData: values.avatarData,
          newsletterAccepted: values.newsletterAccepted,
        });

        // Email special case
        // if (values.email !== user.email) {
        //   addAlert({
        //     type: 'success',
        //     content: "Un email vous a été envoyé pour valider votre changement d'email.",
        //   });
        //   helpers.setFieldValue('email', user.email);
        // } else {
        //   addAlert({
        //     type: 'success',
        //     content: 'Vos données ont été mises à jour.',
        //   });
        // }

        if (values.password) {
          // get new auth token
          const resp = await client.post('/token', {
            email: values.email,
            password: values.password,
          });

          setToken({
            token: resp.data.token,
            refreshToken: resp.data.refresh_token,
            vaultSecret: resp.data.vault_secret,
          });
        }

        await refreshUser();
        helpers.resetForm();

        toast({
          type: 'success',
          message: 'Vos données ont été enregistrées',
          id: 'datasaved',
        });
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [toast, client, editEntity, refreshUser, setToken, user]
  );

  const handleConfirmDelete = useCallback(() => {
    setConfirm({
      body: (
        <>
          <p>Souhaitez vous réellement supprimer votre compte ?</p>
          <p className={'text-danger'}>Cette suppression est immédiatement et définitive !</p>
        </>
      ),
      confirmInputValue: user.email,
      confirmText: 'Je veux supprimer mon compte',
      handleConfirm: async () => {
        if (!user) {
          throw new Error('invalid current user');
        }
        try {
          await deleteEntity(user?.['@id']);
          refreshUser();
        } catch (err) {
          console.log('Error during delete user');
        }
      },
    });
  }, [deleteEntity, user, refreshUser, setConfirm]);

  useEffect(() => {
    const delay = 10 * 1000; // ten minutes
    if (isExportGenerating === false) {
      return;
    }

    const timer = setTimeout(() => {
      handleRefreshExportStatus();
      setLastDateCheckExportGenerating(new Date().getTime());
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [handleRefreshExportStatus, isExportGenerating, lastDateCheckExportGenerating]);

  return (
    <Container className={'marginauto'}>
      <Row className={'justify-content-md-center'}>
        <Col md={10} lg={8} xl={7}>
          <h4>Mes informations</h4>
          <PageContent>{user && <AccountForm entity={user} onSubmit={handleEdit} error={editError} />}</PageContent>

          <h4 className={'mt-5'}>Exporter mes données</h4>
          <PageContent>
            <p>Vous pouvez à tout moment demander à recevoir une copie de vos données personnelles.</p>
            <p>
              Même si vos données sont chiffrées dans nos bases de données, nous utiliserons un jeton d'authentification
              unique afin que vous récupéreriez des données lisibles et exploitables.
            </p>
            <p>Vous recevrez un email avec les informations de récupération dès que celles-ci seront prêtes.</p>
            <Row>
              <Col xs={'auto'} className={'ms-auto me-auto'}>
                <ButtonWithIcon variant={'info'} onClick={handleExportPersonalData} disabled={isExportGenerating}>
                  {(isExportGenerating && 'Export en cours ...') || 'Exporter mes données'}
                </ButtonWithIcon>
              </Col>
            </Row>
          </PageContent>

          <h4 className={'mt-5'}>Supprimer mon compte</h4>
          <PageContent>
            <p>
              Vous pouvez à tout moment décider de supprimer votre compte et toutes vos données personnelles via le lien
              ci-dessous.
            </p>
            <p>
              Attention : toute suppression de compte est définitive en entrainement la suppression immédiate de la
              totalité de vos données.
            </p>
            <Row>
              <Col xs={'auto'} className={'ms-auto me-auto'}>
                <ButtonWithIcon variant={'danger'} onClick={handleConfirmDelete}>
                  Supprimer mon compte
                </ButtonWithIcon>
              </Col>
            </Row>
          </PageContent>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
