/* eslint-disable no-process-env */
const staticConfig = {};

const envConfig = {
  appVersion: process.env.REACT_APP_APP_VERSION ?? undefined,
  api: {
    url: process.env.REACT_APP_API_URL,
    maxItemPerPage: 30,
  },
  defaultEmail: process.env?.REACT_APP_DEFAULT_EMAIL,
  defaultPassword: process.env?.REACT_APP_DEFAULT_PASSWORD,
};

type ConfigType = typeof envConfig & typeof staticConfig;
type SelectorType<T = any> = (config: ConfigType) => T;
type UseConfigType = <T = ConfigType>(selector?: SelectorType<T>) => T;

const defaultSelector: SelectorType = (config) => config;
const useConfig: UseConfigType = (selector = defaultSelector) => {
  return selector({ ...envConfig, ...staticConfig });
};

export default useConfig;
