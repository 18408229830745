import React, { ComponentType, useState, useCallback, useEffect } from 'react';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';
import Loader from 'components/Loader';
import nl2br from 'helpers/nl2br';
import moment from 'moment';
import StudentAvatar from 'components/StudentAvatar';
import { useNavigate } from 'react-router';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import FormModal from 'components/modal/FormModal';
import StudentForm from './StudentForm';
import MainCard from 'components/MainCard';
import { RiUmbrellaFill } from 'react-icons/ri';
import { useMemo } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { RiMapPin2Fill } from 'react-icons/ri';
import { AiFillMail } from 'react-icons/ai';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import ExportPdfModal from './ExportPdfModal';

const ContactIcon = styled.div`
  font-size 16px;
  margin-right: 15px;
`;

const StudentMainCard: ComponentType = () => {
  // HOOKS
  const [classroom, { groups }, { refreshStudents }] = useCurrentClassroom();
  const [student, , { refreshStudent }] = useCurrentStudent();
  const navigate = useNavigate();
  const { deleteEntity, editEntity } = useEntities();

  // STATES
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [editError, setEditError] = useState<ApiError | undefined>();
  const [pdfModalVisible, setPdfModalVisible] = useState<boolean>(false);

  // MEMOS
  const studentIri = useMemo(() => student?.['@id'], [student]);

  // REFRESH
  const handleRefresh = useCallback(async () => {
    if (!studentIri) {
      return;
    }
    await refreshStudent(studentIri);
  }, [refreshStudent, studentIri]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const handleEdit = useCallback(
    async (values, helpers) => {
      if (!student) {
        return;
      }

      try {
        await editEntity(student['@id'], {
          ...values,
          birthDate: values?.birthDate ? moment(values.birthDate).format('YYYY-MM-DD') : null,
          group: values?.group !== '' ? values.group : null,
        });
        await handleRefresh();
        setEditVisible(false);
        refreshStudents(student.classroom['@id']);
        helpers.resetForm();
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [student, editEntity, handleRefresh, refreshStudents]
  );

  const handleDelete = useCallback(async () => {
    const classroomId = student?.classroom.id;
    await deleteEntity(`${student?.['@id']}`);
    navigate(`/classrooms/${classroomId}`);
  }, [student, deleteEntity, navigate]);

  /**
   * SUBCOMPONENTS
   */
  const renderStudentAvatar = useCallback(({ ...props }) => <StudentAvatar {...props} student={student} />, [student]);

  return (
    <>
      <MainCard
        onEdit={classroom && classroom?.myAcl.studentAcl === 'full-access' && (() => setEditVisible(true))}
        title={student && <>{(student?.givenName ?? '') + ' ' + (student?.familyName ?? '')}</>}
        description={student && student?.comment && <>{nl2br(student.comment)}</>}
        Avatar={renderStudentAvatar}
        banner={classroom?.banner?.url}
      >
        <div className={'m-3'}>
          {(student && (
            <>
              {student.birthDate && (
                <div className={'mb-3'}>
                  {student.gender === 'male' ? 'Né' : 'Née'} le {moment(student.birthDate).format('LL')}
                  <span className={'d-inline-block ms-2 text-muted'}>
                    ({moment().diff(student.birthDate, 'years')} ans)
                  </span>
                </div>
              )}
              {student.phone && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ContactIcon className="text-success">
                      <FaPhoneAlt />
                    </ContactIcon>
                  </Col>
                  <Col>
                    <a href={`tel:${student.phone}`}>{student.phone}</a>
                  </Col>
                </Row>
              )}

              {student.email && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ContactIcon className="text-primary">
                      <AiFillMail />
                    </ContactIcon>
                  </Col>
                  <Col>
                    <a href={`mailto:${student.email}`}>{student.email}</a>
                  </Col>
                </Row>
              )}

              {student.address && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ContactIcon className="text-warning">
                      <RiMapPin2Fill />
                    </ContactIcon>
                  </Col>
                  <Col>{nl2br(student.address)}</Col>
                </Row>
              )}

              {student.insurance && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ContactIcon style={{ color: '#5716c7' }}>
                      <RiUmbrellaFill />
                    </ContactIcon>
                  </Col>
                  <Col>{nl2br(student.insurance)}</Col>
                </Row>
              )}
            </>
          )) || <Loader />}
        </div>
        <Row className={'justify-content-center'}>
          <Col xs={'auto'}>
            <Button variant="light" size={'sm'} onClick={() => setPdfModalVisible(true)}>
              Télécharger la fiche PDF
            </Button>
          </Col>
        </Row>
      </MainCard>
      <ExportPdfModal student={student} show={pdfModalVisible} onHide={() => setPdfModalVisible(false)} />
      <FormModal
        title={"Modifier l'élève"}
        show={editVisible}
        onSubmit={handleEdit}
        onDelete={handleDelete}
        error={editError}
        entity={student}
        onClose={() => {
          setEditVisible(false);
          setEditError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => (
          <StudentForm ref={ref} onSubmit={onSubmit} entity={entity} availableGroups={groups} error={error} />
        )}
        modalVisibilityHandler={setEditVisible}
      />
    </>
  );
};

export default StudentMainCard;
