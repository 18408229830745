import React, { ComponentType, useCallback, useRef } from 'react';
import MediaGallery from 'components/media/MediaGallery';
import ProgressiveLoadingComponent, { ProgressiveLoadingRef } from 'components/ProgressiveLoadingComponent';
import { ClassroomType, MediaType, StudentType } from 'types';
import moment from 'moment';
import EmptyMessageWithPicture from './EmptyMessageWithPicture';
import UploadMedia from './media/UploadMedia';
import BigLoader from './BigLoader';
import { imgPhotos } from 'helpers/illustration';
import { Col, Row } from 'react-bootstrap';

type ProgressiveMediasGalleryProps = {
  baseurl?: string;
  enableEditAndDelete?: boolean;
  classroom: ClassroomType;
  student?: StudentType;
};

const ProgressiveMediasGallery: ComponentType<ProgressiveMediasGalleryProps> = ({
  baseurl,
  enableEditAndDelete: editAndDeleteEnable,
  classroom,
  student,
}) => {
  // REFS
  const galleryRef = useRef<ProgressiveLoadingRef>();

  // HANDLERS
  const handleDelete = useCallback(
    (id) => {
      galleryRef.current.deleteEntity(id);
    },
    [galleryRef]
  );

  const handleRefresh = useCallback(async () => {
    await galleryRef.current.refreshAllBackground();
  }, [galleryRef]);

  return (
    <>
      {(baseurl && (
        <ProgressiveLoadingComponent
          ref={galleryRef}
          baseUrl={baseurl}
          maxPageInALoad={9}
          loaderComponent={<BigLoader />}
          emptyListComponent={
            <EmptyMessageWithPicture img={imgPhotos} title={'Une photo vaut mille mots'}>
              <p className={'mb-5'}>Rassemblez toutes les photos de vos élèves.</p>
              {classroom?.myAcl.mediaAcl === 'full-access' && (
                <UploadMedia handleRefresh={handleRefresh} classroom={classroom} student={student} />
              )}
            </EmptyMessageWithPicture>
          }
          autoLoadOnScroll
        >
          {({ entities }: { entities: MediaType[] }) => (
            <>
              {entities && entities.length > 0 && (
                <>
                  {classroom?.myAcl.mediaAcl === 'full-access' && (
                    <Row>
                      <Col className={'ms-auto'} xs={'auto'}>
                        <UploadMedia
                          variant={'secondary'}
                          handleRefresh={handleRefresh}
                          classroom={classroom}
                          student={student}
                          size={'sm'}
                        />
                      </Col>
                    </Row>
                  )}
                  <MediaGallery
                    entities={entities}
                    onDelete={editAndDeleteEnable && handleDelete}
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={2}
                    xxl={'auto'}
                    caption={(media: MediaType) => (
                      <>
                        {(media?.student && (
                          <>
                            {media.student.givenName} {media.student.familyName} -{' '}
                          </>
                        )) ||
                          (media?.classroom && <>{media.classroom.name} - </>)}
                        {moment(media.date).format('LLL')}
                      </>
                    )}
                  />
                </>
              )}
            </>
          )}
        </ProgressiveLoadingComponent>
      )) || <BigLoader />}
    </>
  );
};

export default ProgressiveMediasGallery;
