import { imgLost } from 'helpers/illustration';
import React, { ComponentType } from 'react';
import ErrorScreen from 'views/ErrorScreen/ErrorScreen';

const NotFound: ComponentType = () => {
  return (
    <ErrorScreen title={'Page introuvable :('} description={"La page demandée n'existe pas/plus."} img={imgLost} />
  );
};

export default NotFound;
