import React, { forwardRef, useImperativeHandle, useRef, useMemo } from 'react';
import { FormikHelpers, Formik, FormikProps } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import FormError from 'components/form/FormError';
import * as Yup from 'yup';
import { StudentInformationType } from 'types';
import styled from 'styled-components';
import { priorityToColor, priorityToString } from 'types/StudentInformationType';
import { ApiError } from 'helpers/apiError';

const ColorBadge = styled.div`
  position: relative;
  padding: 0.3rem 1rem;
  font-size: 0.85em;
  font-weight: 600;
  color: white;
  border-radius: 5px;
  border: 1px solid;
  overflow: hidden;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    .color-badge-title {
      color: white !important;
    }
    .color-badge-background {
      opacity: 1;
    }
  }

  .color-badge-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    transition: 0.2s;
  }

  .color-badge-title {
    position: relative;
    color: #555;
  }
`;

const FormInitValues = {
  title: '',
  description: undefined,
  priority: 2,
};

type FormValues = {
  title: string;
  description: string | undefined;
  priority: number;
};

const FormSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 1 caractère')
    .max(255, 'Doit contenir au maximum 100 caractères')
    .required('Ne peut être vide'),
  description: Yup.string()
    .trim()
    .min(2, 'Doit contenir au moins 1 caractère')
    .max(255, 'Doit contenir au maximum 255 caractères')
    .transform((v) => (v === '' ? null : v))
    .nullable(),
  priority: Yup.number()
    .oneOf([1, 2, 3])
    .transform((v) => (v === '' ? null : v))
    .nullable(),
});

type InformationFormProps = {
  entity?: StudentInformationType;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  onCancel?: () => void;
  error?: ApiError | undefined;
};

const InformationForm = forwardRef(({ entity, onSubmit, error, onCancel }: InformationFormProps, ref: any) => {
  // REF
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
  }));

  // HOOKS
  const formRef = useRef<any | null>(null);

  // MEMO
  const initvalues = useMemo(() => {
    return {
      ...FormInitValues,
      ...(entity
        ? {
            title: entity.title,
            description: entity.description,
            priority: entity.priority,
          }
        : {}),
    };
  }, [entity]);

  return (
    <Formik
      innerRef={(ref) => {
        formRef.current = ref;
      }}
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={initvalues}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldValue,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}

          <Row className={'align-items-center mb-2 g-0'}>
            {[1, 2, 3].map((i) => (
              <Col key={i} xs={4} className={'px-2'}>
                <ColorBadge
                  className={values.priority === i ? 'selected' : ''}
                  style={{ borderColor: priorityToColor(i) }}
                  onClick={() => setFieldValue('priority', i)}
                >
                  <div className={'color-badge-background'} style={{ backgroundColor: priorityToColor(i) }} />
                  <div className={'color-badge-title text-truncate'}>{priorityToString(i)}</div>
                </ColorBadge>
              </Col>
            ))}
          </Row>

          <Form.Group>
            <Form.Label>Sujet</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder={'Que faut-il savoir ?'}
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.title && !!errors.title}
            />
            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              as={'textarea'}
              rows={6}
              name="description"
              placeholder={'Vous pouvez en dire un peu plus ?'}
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.description && !!errors.description}
              style={{ minHeight: 150 }}
            />
            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

export default InformationForm;
