import React, { ComponentType, useState, useCallback } from 'react';
import { Row, Col, ButtonGroup, Dropdown } from 'react-bootstrap';
import { useCurrentClassroom, useToast } from 'hooks';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import useEntities from 'hooks/useEntities';
import styled from 'styled-components';
import FormModal from 'components/modal/FormModal';
import { StudentType } from 'types';
import StudentImportForm from './components/StudentImportForm';
import FormError from 'components/form/FormError';
import { useEffect } from 'react';
import StudentForm from 'views/StudentConsult/components/StudentForm';
import EmptyMessageWithPicture from 'components/EmptyMessageWithPicture';
import Loader from 'components/Loader';
import { imgTeach } from 'helpers/illustration';
import moment from 'moment';
import StudentGroupForm from './components/StudentGroupForm';
import StudentGroup from './components/StudentGroup';
import DraggableStudentList from './components/DraggableStudentList';
import StudentGroupTitle from './components/StudentGroupTitle';
import LoadingOverlay from 'components/LoadingOverlay';
import StudentList from './components/StudentList';

const StudentListMainDiv = styled.div`
  min-height: 10rem;
  position: relative;
`;

const Students: ComponentType = () => {
  /**
   * HOOKS
   */
  const { postEntity, fetchEntity } = useEntities();
  const [classroom, { studentsGroupByGroup, isStudentsReloading, groups }, { refreshStudents }] = useCurrentClassroom();
  const [{ toast }] = useToast();

  /**
   * STATES
   */
  const [addStudentModalVisible, setAddStudentModalVisible] = useState<boolean>(false);
  const [addStudentError, setAddStudentError] = useState<ApiError | undefined>();

  const [importStudentsModalVisible, setImportStudentsModalVisible] = useState<boolean>(false);
  const [importStudentErrors, setImportStudentErrors] = useState<ApiError[] | undefined>();

  const [addGroupModalVisible, setAddGroupModalVisible] = useState<boolean>(false);
  const [addGroupError, setAddGroupError] = useState<ApiError | undefined>();

  /**
   * REFRESH
   */
  const handleRefresh = useCallback(async () => {
    if (!classroom) {
      return;
    }
    await refreshStudents(classroom['@id']);
  }, [refreshStudents, classroom]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const handleAddStudent = useCallback(
    async (values, helpers) => {
      if (!classroom) {
        return;
      }

      try {
        await postEntity(`/students`, {
          ...values,
          classroom: classroom['@id'],
          birthDate: values?.birthDate ? moment(values.birthDate).format('YYYY-MM-DD') : null,
          group: values?.group !== '' ? values.group : null,
        });

        await handleRefresh();
        setAddStudentModalVisible(false);
        helpers.resetForm();

        toast({
          type: 'success',
          title: "C'est fait !",
          message: 'Votre élève a bien été crée.',
          delay: 200,
        });
      } catch (err) {
        setAddStudentError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [classroom, postEntity, handleRefresh, toast]
  );

  const handleImportStudents = useCallback(
    async (values, helpers) => {
      if (!classroom) {
        return;
      }

      const errors: ApiError[] = [];

      await Promise.all(
        values.studentIds?.map(async (studentId: string) => {
          try {
            await postEntity(`/students_import`, {
              classroomId: classroom.id,
              studentId: studentId,
            });

            await handleRefresh();
          } catch (err) {
            const student: StudentType = await fetchEntity(`/students/${studentId}`);
            const error = parseFormApiError(err);
            errors.push({
              ...error,
              title: `Import impossible`,
              message: `${student?.givenName} ${student?.familyName} : ${error.message}`,
            });
          }
        })
      );

      helpers.setSubmitting(false);

      if (errors.length === 0) {
        setImportStudentsModalVisible(false);
        helpers.resetForm();
        setImportStudentErrors(undefined);
      } else {
        setImportStudentErrors(errors);
      }
    },
    [classroom, fetchEntity, handleRefresh, postEntity]
  );

  const handleAddGroup = useCallback(
    async (values, helpers) => {
      if (!classroom) {
        return;
      }

      try {
        await postEntity(`/student_groups`, {
          ...values,
          classroom: classroom['@id'],
        });

        await handleRefresh();
        setAddGroupModalVisible(false);
        helpers.resetForm();

        toast({
          type: 'success',
          title: "C'est fait !",
          message: 'Vous pouvez dès maintenant glisser vos élèves dans le groupe.',
          delay: 200,
        });
      } catch (err) {
        setAddGroupError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [classroom, handleRefresh, postEntity, toast]
  );

  /**
   * EFFECTS
   */
  useEffect(() => {
    setImportStudentErrors(undefined);
  }, [importStudentsModalVisible]);

  if (!studentsGroupByGroup || !classroom) {
    return <Loader />;
  }

  return (
    <>
      <StudentListMainDiv>
        <Row style={{ position: 'relative' }} className={'mx-1'}>
          <Col>
            <LoadingOverlay visible={isStudentsReloading} loader />
            {((studentsGroupByGroup.nbStudents > 0 || studentsGroupByGroup.groups.length > 0) && (
              <>
                {(studentsGroupByGroup.groups.length > 0 && (
                  <>
                    {studentsGroupByGroup.groups.map((group) => (
                      <StudentGroup
                        key={group['@id']}
                        group={group}
                        handleAddStudent={() => setAddStudentModalVisible(true)}
                      />
                    ))}
                    {studentsGroupByGroup.groups.length > 0 && studentsGroupByGroup.emptyGroup.length > 0 && (
                      <>
                        <StudentGroupTitle name={'Autres'} />
                        <DraggableStudentList
                          id={undefined}
                          students={studentsGroupByGroup.emptyGroup}
                          handleAddStudent={() => setAddStudentModalVisible(true)}
                        />
                      </>
                    )}
                  </>
                )) || <StudentList students={studentsGroupByGroup.emptyGroup} />}
              </>
            )) || (
              <EmptyMessageWithPicture img={imgTeach} title={'Annuaire des élèves'}>
                <p>Visualisez et selectionnez vos élèves en un coup d'oeil.</p>
              </EmptyMessageWithPicture>
            )}
          </Col>
        </Row>

        {classroom?.myAcl.studentAcl === 'full-access' && (
          <Row className="mt-3">
            <Col xs={'auto'} className={'ms-auto me-auto'}>
              <Row>
                <Col>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle variant={'primary'}>Ajouter</Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item onClick={() => setAddStudentModalVisible(true)}>Nouvel élève</Dropdown.Item>
                      <Dropdown.Item onClick={() => setAddGroupModalVisible(true)}>Nouveau groupe</Dropdown.Item>

                      <Dropdown.Item onClick={() => setImportStudentsModalVisible(true)}>
                        Importer des élèves
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </StudentListMainDiv>

      {/* Add student */}
      <FormModal
        title={'Nouvel élève'}
        show={addStudentModalVisible}
        onClose={() => {
          setAddStudentModalVisible(false);
          setAddStudentError(undefined);
        }}
        onSubmit={handleAddStudent}
        error={addStudentError}
        form={(ref, onSubmit, addStudentError, entity) => (
          <StudentForm ref={ref} onSubmit={onSubmit} error={addStudentError} availableGroups={groups} />
        )}
      />
      <FormModal
        title={'Importer des élèves'}
        show={importStudentsModalVisible}
        onClose={() => {
          setImportStudentsModalVisible(false);
          setImportStudentErrors(undefined);
        }}
        onSubmit={handleImportStudents}
        form={(ref, onSubmit, error, entity) => (
          <>
            {importStudentErrors && <FormError error={importStudentErrors} />}
            <StudentImportForm ref={ref} onSubmit={onSubmit} onCancel={() => setImportStudentsModalVisible(false)} />
          </>
        )}
      />

      {/* Manage groups */}
      <FormModal
        title={"Nouveau groupe d'élèves"}
        size={'sm'}
        show={addGroupModalVisible}
        onClose={() => {
          setAddGroupModalVisible(false);
          setAddGroupError(undefined);
        }}
        onSubmit={handleAddGroup}
        form={(ref, onSubmit, error, entity) => (
          <StudentGroupForm ref={ref} onSubmit={onSubmit} error={addGroupError} />
        )}
        modalVisibilityHandler={setAddGroupModalVisible}
      />
    </>
  );
};

export default Students;
