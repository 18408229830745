import React, { ComponentType } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

type InfoBlockProps = {
  className?: string;
};

const InfoBlock: ComponentType<InfoBlockProps> = ({ className, children }) => {
  return (
    <div className={`info-block ${className ?? ''}`}>
      <Row className={'justify-content-center align-items-center g-3'}>
        <Col xs={'auto'} className={'info-block-icon'}>
          <FaInfoCircle />
        </Col>
        <Col>{children}</Col>
      </Row>
    </div>
  );
};

export default InfoBlock;
