import React, { ComponentType, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Row, Col, Container } from 'react-bootstrap';
import ClassroomMainCard from './components/ClassroomMainCard';
import BackButton from 'components/BackButton';
import useCurrentClassroom from 'hooks/useCurrentClassroom';
import { useCurrentStudent } from 'hooks';
import ClassroomNavBar from './components/ClassroomNavBar';
import ClassroomRouter from './components/ClassroomRouter';
import { useCallback } from 'react';
import BigLoader from 'components/BigLoader';
import ErrorScreen from 'views/ErrorScreen/ErrorScreen';
import { imgLost } from 'helpers/illustration';

const ClassroomConsult: ComponentType = () => {
  // HOOKS
  const navigate = useNavigate();
  const { classroomId } = useParams<{ classroomId: string }>();
  const [classroom, , { init }] = useCurrentClassroom();
  const [, , { cleanup: cleanupStudent }] = useCurrentStudent();

  // STATES
  const [err, setErr] = useState<string | undefined>(undefined);

  // REFRESH
  const handleRefresh = useCallback(async () => {
    cleanupStudent();
    try {
      await init(`/classrooms/${classroomId}`);
    } catch (erro: any) {
      if (erro?.request?.status === 404) {
        setErr('not_found');
      } else {
        setErr('unknown');
      }
    }
  }, [classroomId, cleanupStudent, init]);
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  // HANDLERS
  const goToDashboard = useCallback(() => navigate(`/dashboard`), [navigate]);

  // RENDER
  if (err) {
    if (err === 'not_found') {
      return (
        <ErrorScreen
          title={'Classe introuvable'}
          description={"Nous n'avons pu trouver cette classe, peut-être a-t-elle été supprimée ?"}
          img={imgLost}
        />
      );
    } else {
      return <ErrorScreen />;
    }
  }

  if (!classroom) {
    return <BigLoader />;
  }

  return (
    <Container>
      <Row>
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 0 }} lg={4} xl={4} xxl={3}>
          <Row>
            <Col>
              <BackButton onClick={goToDashboard}>Mes classes</BackButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <ClassroomMainCard />
            </Col>
          </Row>
        </Col>
        <Col>
          <ClassroomNavBar />
          <ClassroomRouter />
        </Col>
      </Row>
    </Container>
  );
};

export default ClassroomConsult;
