import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { FiEdit, FiX } from 'react-icons/fi';

const InnerButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 30px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.95);
  height: 32px;
  width: 32px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: #3092e3;
  }

  svg {
    font-size: 14px;
    position: relative;
    left: 1px;
  }
`;

type CardBadgeProps = {
  onClick: () => void;
  type: 'edit' | 'close';
};

const CardBadge: ComponentType<CardBadgeProps> = ({ onClick, type }) => {
  return (
    <>
      <InnerButton onClick={onClick}>{type === 'edit' ? <FiEdit /> : <FiX />}</InnerButton>
    </>
  );
};

export default CardBadge;
