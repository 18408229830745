export type StudentInformationLightType = {
  ['@id']: string;
  id: string;

  priority: 1 | 2 | 3;
  title: string;
};

export type StudentInformationType = {
  ['@id']: string;
  id: string;
  createdAt: string;
  updatedAt: string;

  student?: {
    ['@id']: string;
    id: string;
  };

  title: string;
  description: string | undefined;
  priority: 1 | 2 | 3;
};

export const priorityToColor = (priority: number): string => {
  switch (priority) {
    case 1:
      return '#e76f51';
    case 2:
      return '#2a9d8f';
    case 3:
      return '#F6B12D';
    default:
      return '#97999B';
  }
};

export const priorityToString = (priority: number): string => {
  switch (priority) {
    case 1:
      return 'Importante';
    case 2:
      return 'Normale';
    case 3:
      return 'Peu importante';
    default:
      return '#bbb';
  }
};
