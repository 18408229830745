import React, { ComponentType } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  background-color: rgba(224, 17, 65, 0.9);
  color: white;
  z-index: 1000;
  font-weight: 600;
  text-align: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);

  font-size: 12px;
  transform: rotate(-38deg);
  padding-top: 3px;
  padding-bottom: 3px;
  width: 150px;
  top: 18px;
  left: -38px;

  &.size-sm {
    font-size: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100px;
    top: 23px;
    left: -15px;
  }
`;

type ArchivedBannerProps = {
  size?: 'sm';
};

const ArchivedBanner: ComponentType<ArchivedBannerProps> = ({ size, children }) => {
  return <Container className={`size-${size}`}>{children || 'Archivée'}</Container>;
};

export default ArchivedBanner;
