import { FormikHelpers } from 'formik';

export type ApiError = {
  title?: string;
  message: string;
  type?: 'error' | 'servercritical';
};

export const parseFormApiError = (
  err: any,
  helpers: FormikHelpers<any> | undefined = undefined
): ApiError | undefined => {
  if (!err.response) {
    return { message: err.message, type: 'servercritical' };
  }

  if (helpers) {
    if (err.response && err.response.data.violations) {
      let finalMessage = 'Un ou plusieurs champs sont invalides';
      err.response.data.violations.map((error: { propertyPath: string; message: string }) => {
        if (error.propertyPath === '') {
          finalMessage = error.message;
        }
        return helpers.setFieldError(error.propertyPath, error.message);
      });
      return { message: finalMessage };
    }
  }

  console.log('API Error', err.response.data);

  if (err.request.status === 0) {
    return {
      type: 'servercritical',
      title: 'Server indisponible',
      message: 'Veuillez re-essayer dans quelques minutes',
    };
  }

  if (err.response.data.message) {
    if (err.response.data.message === 'Invalid credentials.') {
      return { message: 'Identifiants invalides' };
    }
    if (err.response.data.message === 'User account must be validated.') {
      return { message: "Le compte n'a pas encore été validé" };
    }
    return { message: err.response.data.message };
  }

  if (err.response.data.detail) {
    if (err.response.data.detail === 'Internal Server Error') {
      return { type: 'servercritical', title: 'Erreur serveur', message: 'Veuillez re-essayer dans quelques minutes' };
    }

    return { message: err.response.data.detail };
  }

  if (err.response.data?.['hydra:description']) {
    return { message: err.response.data?.['hydra:description'] };
  }

  return err.response.data ? { message: err.response.data } : { message: 'Erreur inconnue' };
};
