import React, { ComponentType, useMemo } from 'react';
import styled from 'styled-components';
import { ObservationCategory, resolvObservationType, resolvObservationTypeColor } from 'types/ObservationType';

const Container = styled.div`
  position: relative;
  padding: 0.5rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  display: inline-block;
  border-radius: 5px;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 0.92em;

  overflow: hidden;
  background-color: transparent;

  &:not(.outline) {
    border: 1px solid transparent;
  }

  .observaption-type-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    transition: 0.2s;
  }

  &.outline {
    border: 1px solid;
    color: #555;

    .observaption-type-bg {
      opacity: 0.2;
    }
  }

  .observaption-type-title {
    z-index: 1;
    position: relative;
  }
`;

type ObservationTypeNameProps = {
  type: ObservationCategory;
  layout?: 'full' | 'icon' | 'text';
  style?: any;
  variant?: 'outline' | 'regular';
};

const ObservationTypeName: ComponentType<ObservationTypeNameProps> = ({ type, layout, variant, style }) => {
  const title = useMemo(() => resolvObservationType(type), [type]);
  const bgColor = useMemo(() => resolvObservationTypeColor(type), [type]);

  return (
    <Container
      style={{
        borderColor: bgColor,
        ...style,
      }}
      className={`${variant === 'outline' ? 'outline' : ''}`}
    >
      <div
        style={{
          backgroundColor: bgColor,
        }}
        className={'observaption-type-bg'}
      />
      <div className={'observaption-type-title text-truncate'}>
        {(!layout || layout === 'full' || layout === 'text') && <>{title}</>}
      </div>
    </Container>
  );
};

export default ObservationTypeName;
