import React, { ComponentType, useCallback, useMemo } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavbarBrand, Container, Row, Col } from 'react-bootstrap';
import { useAuthentication, useGlobal, useConnectedUser } from 'hooks';
import { LinkContainer } from 'react-router-bootstrap';
import imgLogo from 'assets/icon.png';

const Header: ComponentType = () => {
  // HOOKS
  const [, { logout }] = useAuthentication();
  const [user, , userLoaded] = useConnectedUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, { cleanup: cleanupClassrooms }] = useGlobal();

  // MEMOS
  const isTransparent = useMemo(() => {
    const urls = [
      '/',
      `/signin`,
      '/password_lost',
      '/confirm_account',
      `/register`,
      `/terms`,
      `/legal`,
      `/privacy`,
      `/help`,
    ];
    if (urls.includes(pathname)) {
      return true;
    }
    return false;
  }, [pathname]);

  // HANDLERS
  const handleLogout = useCallback(() => {
    cleanupClassrooms();
    logout();
    // temporize to be sure user is cleaned before redirect
    setTimeout(() => navigate('/'), 100);
  }, [cleanupClassrooms, navigate, logout]);

  return (
    <Navbar
      collapseOnSelect
      expand={'sm'}
      variant={!isTransparent ? 'dark' : 'light'}
      className={isTransparent && 'navbar-transparent'}
    >
      <Container>
        <NavbarBrand>
          <Link to={user ? `/dashboard` : `/`}>
            <Row className={'align-items-center g-0'}>
              <Col xs={'auto'} className={'me-1'}>
                <img src={imgLogo} alt={''} />
              </Col>
              <Col xs={'auto'}>Elevapp</Col>
            </Row>
          </Link>
        </NavbarBrand>
        {pathname !== `/terms` && pathname !== `/privacy` && pathname !== `/legal` && userLoaded && (
          <>
            <Navbar.Toggle />
            <Navbar.Collapse className={'justify-content-end'}>
              <Nav className={'ms-auto '}>
                {(user && (
                  <>
                    <Nav.Item>
                      <LinkContainer to={`/account`}>
                        <Nav.Link href={`account`} active={false}>
                          Mon compte
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={handleLogout} active={false}>
                        Déconnexion
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )) || (
                  <>
                    <Nav.Item>
                      <LinkContainer to={`/signin?register=0`}>
                        <Nav.Link href={`/signin`} active={false}>
                          Connexion
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                      <LinkContainer to={`/register`}>
                        <Link className={'btn btn-primary'} to={`/register`} role="button">
                          Rejoignez-nous !
                        </Link>
                      </LinkContainer>
                    </Nav.Item>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
