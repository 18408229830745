import React, { ComponentType, useRef, useCallback, useState, useMemo } from 'react';
import { MediaType } from 'types';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import useEntities from 'hooks/useEntities';
import { MediaInputItem } from 'components/form/input/MediaInput';
import { convertToB64 } from 'helpers/image';
import ObservationInput from 'components/ObservationInput';
import ObservationList from 'components/ObservationList';
import { ProgressiveLoadingRef } from 'components/ProgressiveLoadingComponent';
import { useCurrentClassroom, useCurrentStudent } from 'hooks';

const StudentObservations: ComponentType = () => {
  // HOOKS
  const { postEntity } = useEntities();
  const [classroom] = useCurrentClassroom();
  const [student] = useCurrentStudent();

  // REF
  const observationListRef = useRef<ProgressiveLoadingRef>();

  // STATES
  const [addError, setAddError] = useState<ApiError | undefined>();

  // HANDLERS
  const handleAdd = useCallback(
    async (values, helpers) => {
      if (!student) {
        return;
      }

      try {
        const entity = await postEntity(`/observations`, {
          student: student['@id'],
          classroom: student.classroom['@id'],
          ...values,
        });
        observationListRef.current.appendEntity(entity);
        observationListRef.current.refreshAllBackground();
        helpers.resetForm();
      } catch (err) {
        setAddError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [postEntity, student]
  );

  const handleEdit = useCallback((entity: any) => {
    observationListRef.current.refreshEntity(entity);
    observationListRef.current.refreshAllBackground();
  }, []);

  const handleDelete = useCallback((id: string) => {
    observationListRef.current.deleteEntity(id);
  }, []);

  const handleAddMedia = useCallback(
    async (media: MediaInputItem) => {
      if (!student) {
        throw new Error('Student must be defined to add media');
      }
      if (!media.blob) {
        throw new Error('Cannot add media without blob');
      }
      return await postEntity<MediaType>(`/media`, {
        classroom: student.classroom ? student.classroom['@id'] : undefined,
        student: student ? student['@id'] : undefined,
        type: 'image',
        mediaData: await convertToB64(media.blob),
      });
    },
    [student, postEntity]
  );

  const handleCancel = useCallback(() => {
    setAddError(undefined);
  }, []);

  // MEMO
  const observationsUrl = useMemo(() => {
    if (!student) {
      return;
    }
    return `/observations?student=${student['@id']}&order[date]=desc`;
  }, [student]);

  return (
    <>
      {classroom?.myAcl.observationAcl === 'full-access' && (
        <ObservationInput onCancel={handleCancel} onSubmit={handleAdd} onAddMedia={handleAddMedia} error={addError} />
      )}

      {observationsUrl && (
        <ObservationList
          listRef={observationListRef}
          baseURL={observationsUrl}
          onEdit={classroom?.myAcl.observationAcl === 'full-access' && handleEdit}
          onDelete={classroom?.myAcl.observationAcl === 'full-access' && handleDelete}
          displaySubject={false}
        />
      )}
    </>
  );
};

export default StudentObservations;
