import React, { ComponentType } from 'react';
import styled from 'styled-components';

const Title = styled.h6`
  font-size: 0.8em;
  color: #999;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weigt: 600;
`;

const LabelDivider: ComponentType = ({ children }) => {
  return <Title>{children}</Title>;
};

export default LabelDivider;
