import React, { ComponentType } from 'react';
import ButtonWithIcon from './ButtonWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const SButtonWithIcon = styled(ButtonWithIcon)`
  text-transform: initial;
  font-size: 1.02em;
`;

type BackButtonProps = {
  onClick: () => void;
  icon?: JSX.Element;
};

const BackButton: ComponentType<BackButtonProps> = ({ children, icon, onClick }) => {
  return (
    <SButtonWithIcon
      className={''}
      icon={icon ?? <FontAwesomeIcon icon={'chevron-left'} />}
      variant={'link'}
      onClick={onClick}
    >
      {children}
    </SButtonWithIcon>
  );
};

export default BackButton;
