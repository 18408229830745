import React, { ComponentType, useState, useCallback } from 'react';
import nl2br from 'helpers/nl2br';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { Card, Row, Col } from 'react-bootstrap';
import EditButton from 'components/EditButton';
import FormModal from 'components/modal/FormModal';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import { StudentInformationType } from 'types';
import InformationForm from './InformationForm';
import { priorityToColor } from 'types/StudentInformationType';

const InformationName = styled.h4`
  font-weight: 600;
  font-size: 1.05em;
  margin-bottom: 0;
  vertical-align: center;
`;

const ColorBadge = styled(Badge)`
  height: 1.1em;
  width: 1.1em;
  border-radius: 100px;
  margin-right: 5px;
`;

type InformationItemProps = {
  information: StudentInformationType;
  onEdit: (entity: any) => void;
  onDelete: (id: string) => void;
};

const InformationItem: ComponentType<InformationItemProps> = ({ information, onEdit, onDelete, children }) => {
  // HOOKS
  const { deleteEntity, editEntity } = useEntities();

  // STATES
  const [editError, setEditError] = useState<ApiError | undefined>();
  const [editVisible, setEditVisible] = useState(false);

  //HANDLERS
  const handleEdit = useCallback(
    async (values, helpers) => {
      if (!information) {
        return;
      }
      try {
        const newinformation = await editEntity(information['@id'], values);
        onEdit(newinformation);
        setEditVisible(false);
        helpers.resetForm();
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [editEntity, information, onEdit]
  );

  const handleDelete = useCallback(async () => {
    await deleteEntity(`${information?.['@id']}`);
    onDelete(information.id);
  }, [deleteEntity, information, onDelete]);

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <InformationName>
                <ColorBadge style={{ backgroundColor: priorityToColor(information.priority) }}> </ColorBadge>{' '}
                {information.title}
              </InformationName>
            </Col>
            {onEdit && (
              <Col xs={'auto'} className={'ms-auto'}>
                <EditButton size={'sm'} onClick={() => setEditVisible(true)} />
              </Col>
            )}
          </Row>
          {information.description && (
            <>
              <p className={'mt-3'}>{nl2br(information.description)}</p>
            </>
          )}
        </Card.Body>
      </Card>
      <FormModal
        title={'Modifier une information'}
        show={editVisible}
        onSubmit={handleEdit}
        onDelete={handleDelete}
        error={editError}
        entity={information}
        onClose={() => {
          setEditVisible(false);
          setEditError(undefined);
        }}
        modalVisibilityHandler={setEditVisible}
        form={(ref, onSubmit, error, entity) => (
          <InformationForm ref={ref} onSubmit={onSubmit} entity={entity} error={error} />
        )}
      />
    </>
  );
};

export default InformationItem;
