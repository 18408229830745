import React, { ComponentType, useState, useCallback } from 'react';
import { ObservationType, MediaType } from 'types';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import nl2br from 'helpers/nl2br';
import useEntities from 'hooks/useEntities';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { convertToB64 } from 'helpers/image';
import ObservationForm from 'components/form/ObservationForm';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import EditButton from 'components/EditButton';
import ObservationTypeName from 'components/ObservationTypeName';
import FormModal from 'components/modal/FormModal';
import MediaGallery from 'components/media/MediaGallery';
import { MediaInputItem } from 'components/form/input/MediaInput';
import UserAvatar from 'components/UserAvatar';
import { useConnectedUser, useCurrentClassroom } from 'hooks';
import { formatObservationDate } from 'helpers/date';

const ObservationContent = styled.div`
  font-size: 1.05em;
  line-height: 1.3;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const ObservationSubject = styled.div`
  font-size: 1em;
`;

const ObservationHeader = styled.div`
  font-size: 0.8em;
  font-weight: 500;
  color: #777;
  a {
    color: #777;
  }
`;

type ObservationItemProps = {
  observation: ObservationType;
  onEdit?: (arg?: ObservationType) => void;
  onDelete?: (arg: string) => void;
  displaySubject?: boolean;
};

const ObservationItem: ComponentType<ObservationItemProps> = ({ displaySubject, observation, onEdit, onDelete }) => {
  // HOOKS
  const { deleteEntity, editEntity, postEntity } = useEntities();
  const [user] = useConnectedUser();
  const [classroom] = useCurrentClassroom();

  // STATES
  const [editError, setEditError] = useState<ApiError | undefined>();

  // HANDLERS
  const handleAddMedia = useCallback(
    async (media: MediaInputItem) => {
      if (!media.blob) {
        throw new Error('Cannot add media without blob');
      }
      return await postEntity<MediaType>(`/media`, {
        classroom: observation.classroom ? observation.classroom['@id'] : undefined,
        student: observation.student ? observation.student['@id'] : undefined,
        type: 'image',
        mediaData: await convertToB64(media.blob),
      });
    },
    [observation, postEntity]
  );

  const handleEdit = useCallback(
    async (values, helpers) => {
      if (!onEdit) {
        return;
      }
      try {
        const entity = await editEntity(observation['@id'], values);

        setEditVisible(false);
        await onEdit(entity);
        helpers.resetForm();
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [editEntity, observation, onEdit]
  );

  const handleDelete = useCallback(async () => {
    if (!onDelete) {
      return;
    }
    await deleteEntity(`${observation?.['@id']}`);
    await onDelete(observation.id);
  }, [deleteEntity, onDelete, observation]);

  const handleMediaDelete = useCallback(async () => {
    await onEdit(observation);
  }, [observation, onEdit]);

  // STATES
  const [editVisible, setEditVisible] = useState<boolean>(false);

  return (
    <>
      <Card>
        <Card.Body>
          <ObservationHeader>
            <Row className={'g-0'}>
              <Col>
                <Row className={'g-0 align-items-center me-2'}>
                  <Col xs={'auto'} className={'me-2'}>
                    <UserAvatar user={observation?.createdBy} shape={'round'} size={'32px'} />
                  </Col>
                  <Col className={'text-truncate'} style={{ fontSize: '1.1em' }}>
                    {observation.createdBy
                      ? observation.createdBy.id === user.id
                        ? 'Vous'
                        : `${observation.createdBy.givenName}`
                      : `Utilisateur Inconnu`}
                    <div className={'text-muted'}>
                      <small>{formatObservationDate(observation.date)}</small>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={7} sm={'auto'} className={'ms-auto'}>
                <Row className={'justify-content-end'}>
                  {false !== displaySubject && (
                    <Col xs={'6'} sm={'auto'}>
                      <ObservationSubject className={'text-truncate'}>
                        <small className={'text-muted'}>À propos de :</small>
                        <br />
                        <Link
                          to={
                            observation.student
                              ? `/classrooms/${observation.classroom.id}/students/${observation.student?.id}`
                              : `/classrooms/${observation.classroom.id}`
                          }
                        >
                          <u>
                            {(observation.student && (
                              <>
                                {observation.student.givenName} {observation.student.familyName.substr(0, 1)}.
                              </>
                            )) ||
                              'la classe'}
                          </u>
                        </Link>
                      </ObservationSubject>
                    </Col>
                  )}
                  <Col xs={'5'} sm={'auto'}>
                    <ObservationTypeName style={{ maxWidth: '100%' }} type={observation.type} />
                  </Col>
                  {(onDelete || onEdit) && (
                    <Col xs={'1'} sm={'auto'} className={'ms-0 ps-0'}>
                      <EditButton className={'p-0'} onClick={() => setEditVisible(true)} />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </ObservationHeader>
          <Row className={'my-2 align-items-center'}>
            <Col>
              <ObservationContent>{nl2br(observation.content)}</ObservationContent>
            </Col>
          </Row>
          <Row>
            {observation.medias.length > 0 && (
              <Col className={'mt-3 mt-lg-0'}>
                <MediaGallery
                  previewLimit={6}
                  xs={3}
                  lg={2}
                  entities={observation.medias}
                  onDelete={classroom?.myAcl?.mediaAcl === 'full-access' && handleMediaDelete}
                  caption={() => (
                    <>
                      {observation.content.substr(0, 30)}
                      {observation.content.length > 30 && '...'} - {moment(observation.date).format('LLL')}
                    </>
                  )}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <FormModal
        title={'Modifier une note'}
        show={editVisible}
        onSubmit={handleEdit}
        onDelete={handleDelete}
        error={editError}
        entity={observation}
        onClose={() => {
          setEditVisible(false);
          setEditError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => (
          <ObservationForm
            ref={ref}
            isMinimized={false}
            onSubmit={onSubmit}
            entity={entity}
            error={error}
            onAddMedia={handleAddMedia}
          />
        )}
        modalVisibilityHandler={setEditVisible}
      />
    </>
  );
};

export default ObservationItem;
