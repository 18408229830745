export type AclType = {
  ['@id']: string;
  id: string;
  createdAt: string;
  updatedAt: string;

  acceptedAt: string | undefined;
  classroom: string;
  user?: {
    ['@id']: string;
    id: string;
    familyName?: string;
    givenName?: string;
    email?: string;
    avatar?: {
      regular: string;
      thumbnail: string;
    };
  };

  classroomAcl: 'full-access' | 'read-only';
  studentAcl: 'full-access' | 'read-only';
  observationAcl: 'full-access' | 'read-only' | 'forbidden';
  mediaAcl: 'full-access' | 'read-only' | 'forbidden';
};

export const aclToString = (acl: string) => {
  switch (acl) {
    case 'full-access':
      return 'Lecture et écriture';
    case 'read-only':
      return 'Lecture seule';
    case 'forbidden':
      return 'Aucun accès';
    default:
      return 'Inconnu';
  }
};

export const aclToColor = (acl: string) => {
  switch (acl) {
    case 'full-access':
      return 'success';
    case 'read-only':
      return 'warning';
    case 'forbidden':
      return 'danger';
    default:
      return 'secondary';
  }
};

export const resolvAclName = (scope: string) => {
  switch (scope) {
    case 'classroomAcl':
      return 'Infos. et partages';
    case 'studentAcl':
      return 'Données des élèves';
    case 'observationAcl':
      return 'Observations';
    case 'mediaAcl':
      return 'Photos';
    default:
      return 'Inconnu';
  }
};

export const resolvAclDescription = (scope: string) => {
  switch (scope) {
    case 'classroomAcl':
      return 'Concerne les données de la classe et ses partages.';
    case 'studentAcl':
      return "Concerne les toutes les données de l'élève, tel que les informations personnelles ainsi que les parents et les informations.";
    case 'observationAcl':
      return 'Concerne les observations et les photos qui leur sont liées.';
    case 'mediaAcl':
      return 'Concerne les photos associées à cette classe.\nAttention : les medias associés à des observations restent accessibles dans celles-ci.';
    default:
      return '';
  }
};
