import React, { ComponentType, useState, useCallback } from 'react';
import { ParentType } from 'types';
import nl2br from 'helpers/nl2br';
import { Card, Row, Col } from 'react-bootstrap';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import useEntities from 'hooks/useEntities';
import ParentForm from './ParentForm';
import styled from 'styled-components';
import FormModal from 'components/modal/FormModal';
import EditButton from 'components/EditButton';
import { FaPhoneAlt } from 'react-icons/fa';
import { RiMapPin2Fill } from 'react-icons/ri';
import { AiFillMail } from 'react-icons/ai';
import { resolvParentType, resolvParentTypeAsEmoj } from 'types/ParentType';

const ParentEmoj = styled.div`
  font-size: 36px;
  margin-right: 15px;
`;

const ParentName = styled.div`
  font-weight: 600;
  font-size: 1.07em;
  line-height: 1em;

  small {
    font-size: 0.7em;
    line-height: 1em;
    display: block;
  }
`;

const ParentInfoIcon = styled.div`
  font-size: 16px;
  margin-right: 15px;
`;

type ParentItemProps = {
  parent: ParentType;
  onEdit: (entity: any) => void;
  onDelete: (id: string) => void;
};

const ParentItem: ComponentType<ParentItemProps> = ({ onEdit, onDelete, parent }) => {
  // HOOKS
  const { editEntity, deleteEntity } = useEntities();

  // STATES
  const [editVisible, setEditVisible] = useState(false);
  const [editError, setEditError] = useState<ApiError | undefined>();

  // HANDLERS
  const handleEdit = useCallback(
    async (values, helpers) => {
      try {
        const newParent = await editEntity(parent['@id'], values);
        await onEdit(newParent);
        setEditVisible(false);
        helpers.resetForm();
      } catch (err) {
        setEditError(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [editEntity, parent, onEdit]
  );

  const handleDelete = useCallback(async () => {
    await deleteEntity(`${parent?.['@id']}`);
    onDelete(parent.id);
  }, [deleteEntity, parent, onDelete]);

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Row className={'align-items-center mx-1 g-0'}>
                <Col xs={'auto'}>
                  <ParentEmoj>{resolvParentTypeAsEmoj(parent.type)}</ParentEmoj>
                </Col>
                <Col>
                  <ParentName>{`${parent.givenName} ${parent.familyName}`}</ParentName>
                  <div>
                    <small className={'text-muted'}>{resolvParentType(parent.type)}</small>
                  </div>
                </Col>
              </Row>
            </Col>
            {onEdit && (
              <Col xs={'auto'} className={'ms-auto d-flex'}>
                <EditButton size={'sm'} onClick={() => setEditVisible(true)} />
              </Col>
            )}
          </Row>
        </Card.Header>
        <Card.Body>
          {parent?.comment && (
            <div className={'mx-2 mb-3'}>
              <i>{nl2br(parent.comment)}</i>
            </div>
          )}

          {parent?.profession && (
            <div className={'mx-2 mb-3'}>
              Travaille en tant que <strong>{parent.profession}</strong>.
            </div>
          )}

          <div className={'mt-3'}>
            <div className={'ms-2'}>
              {parent.phone && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ParentInfoIcon className="text-success">
                      <FaPhoneAlt />
                    </ParentInfoIcon>
                  </Col>
                  <Col>
                    <a href={`tel:${parent.phone}`}>{parent.phone}</a>
                  </Col>
                </Row>
              )}

              {parent.email && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ParentInfoIcon className="text-primary">
                      <AiFillMail />
                    </ParentInfoIcon>
                  </Col>
                  <Col>
                    <a href={`mailto:${parent.email}`}>{parent.email}</a>
                  </Col>
                </Row>
              )}

              {parent.address && (
                <Row className={'align-items-center mb-2 g-0'}>
                  <Col xs={'auto'}>
                    <ParentInfoIcon className="text-warning">
                      <RiMapPin2Fill />
                    </ParentInfoIcon>
                  </Col>
                  <Col>{nl2br(parent.address)}</Col>
                </Row>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <FormModal
        title={'Modifier le parent'}
        show={editVisible}
        onSubmit={handleEdit}
        onDelete={handleDelete}
        error={editError}
        entity={parent}
        onClose={() => {
          setEditVisible(false);
          setEditError(undefined);
        }}
        form={(ref, onSubmit, error, entity) => (
          <ParentForm ref={ref} onSubmit={onSubmit} entity={entity} error={error} />
        )}
        modalVisibilityHandler={setEditVisible}
      />
    </>
  );
};

export default ParentItem;
