import { useState, useCallback, useMemo } from 'react';
import { AclType, ClassroomType, StudentGroupType, StudentType } from 'types';
import createProvider from './createProvider';
import useEntities from './useEntities';
import deepEqual from 'deep-equal';

export type StudentsGroupByGroupType = {
  groups: StudentGroupEnhanced[];
  emptyGroup: StudentType[];
  nbStudents: number;
};
export interface StudentGroupEnhanced extends StudentGroupType {
  students: StudentType[];
}

export const sortStudentsByName = (students: StudentType[]) => {
  return students.sort((a: StudentType, b: StudentType) => {
    const valueA = `${a.familyName} ${a.givenName}`;
    const valueB = `${b.familyName} ${b.givenName}`;
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  });
};

const useCurrentClassroom = () => {
  /**
   * HOOKS
   */
  const { fetchEntity, fetchAll } = useEntities();

  /**
   * STATES
   */
  const [classroom, setClassroom] = useState<ClassroomType | undefined>();
  const [students, setStudents] = useState<StudentType[] | undefined>();
  const [groups, setGroups] = useState<StudentGroupType[] | undefined>();
  const [acls, setAcls] = useState<AclType[] | undefined>();

  const [isStudentsReloading, setIsStudentReloading] = useState(false);

  /**
   * HANDLERS
   */
  const isInit = useCallback(
    (classroomIri: string) => {
      return classroom?.['@id'] === classroomIri;
    },
    [classroom]
  );

  // REFRESH METHOD
  const refreshClassroom = useCallback(
    async (classroomIri: string | undefined) => {
      let entity: ClassroomType | undefined;
      if (classroomIri) {
        entity = await fetchEntity(`${classroomIri}`);
      } else {
        entity = undefined;
      }
      setClassroom((state) => (!deepEqual(entity, state) ? entity : state));
    },
    [fetchEntity]
  );

  const refreshAcls = useCallback(
    async (classroomIri: string | undefined) => {
      let entities: AclType[] | undefined;
      if (classroomIri) {
        entities = await fetchAll(`/classroom_acls?classroom=${classroomIri}`);
      } else {
        entities = undefined;
      }
      setAcls((state) => (!deepEqual(entities, state) ? entities : state));
    },
    [fetchAll]
  );

  const refreshStudents = useCallback(
    async (classroomIri: string | undefined) => {
      let studentsData: StudentType[] | undefined;
      let groupsData: StudentGroupType[] | undefined;
      if (classroomIri) {
        studentsData = await fetchAll(`/students?classroom=${classroomIri}`);
        groupsData = await fetchAll(`/student_groups?classroom=${classroomIri}`);

        setStudents((state) => (!deepEqual(studentsData, state) ? studentsData : state));
        setGroups((state) => (!deepEqual(groupsData, state) ? groupsData : state));
      } else {
        setGroups(undefined);
        setStudents(undefined);
      }
    },
    [fetchAll]
  );

  // MEMOS
  const studentsGroupByGroup = useMemo(() => {
    if (!groups || !students) {
      return undefined;
    }

    // get all students by groups
    const tmpData = groups
      .map((group): StudentGroupEnhanced => {
        const out = Object.assign(group);
        // add group student's sorted by name and given name
        out.students = sortStudentsByName(students.filter((v) => v?.group === group['@id']));
        return out;
      })
      .sort((groupA, groupB) => {
        if (groupA.name === groupB.name) {
          return 0;
        }
        return groupA.name < groupB.name ? -1 : 1;
      });

    return {
      groups: tmpData,
      // Special case : student without group
      emptyGroup: sortStudentsByName(students.filter((v) => !v?.group)),
      nbStudents: students.length,
    };
  }, [groups, students]);

  const cleanup = useCallback(() => {
    refreshClassroom(undefined);
    refreshStudents(undefined);
    refreshAcls(undefined);
  }, [refreshAcls, refreshClassroom, refreshStudents]);

  const refreshAll = useCallback(
    async (classroomIri: string | undefined) => {
      await refreshClassroom(classroomIri);
      refreshStudents(classroomIri);
      refreshAcls(classroomIri);
    },
    [refreshAcls, refreshClassroom, refreshStudents]
  );

  // INIT
  const init = useCallback(
    async (
      classroomIri: string | undefined,
      classroom?: ClassroomType,
      students?: StudentType[],
      groups?: StudentGroupType[]
    ) => {
      if (isInit(classroomIri)) {
        return;
      }
      if (classroom) {
        setClassroom(classroom);
      }
      if (students) {
        setStudents(students);
      }
      if (groups) {
        setGroups(groups);
      }
      await refreshAll(classroomIri);
    },
    [isInit, refreshAll]
  );

  /**
   * RETURN
   */
  const subEntities = useMemo(
    () => ({
      students,
      studentsGroupByGroup,
      acls,
      isStudentsReloading,
      groups,
    }),
    [acls, groups, isStudentsReloading, students, studentsGroupByGroup]
  );

  const actions = useMemo(() => {
    return {
      setClassroom,
      setIsStudentReloading,
      refreshClassroom,
      refreshStudents,
      refreshAcls,
      refreshAll,
      init,
      cleanup,
    };
  }, [cleanup, init, refreshAcls, refreshAll, refreshClassroom, refreshStudents]);

  return [classroom, subEntities, actions] as [typeof classroom, typeof subEntities, typeof actions];
};

const [withCurrentClassroom, useProvidedCurrentClassroom] = createProvider(useCurrentClassroom);

export { withCurrentClassroom };

export default useProvidedCurrentClassroom;
