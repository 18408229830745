import React, { ComponentType } from 'react';
import * as Yup from 'yup';
import { FormikHelpers, Formik, FormikProps } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import ButtonWithIcon from 'components/ButtonWithIcon';
import FormError from 'components/form/FormError';
import { ApiError } from 'helpers/apiError';

const FormInitValues = {
  vaultRecoverSecret: '',
  password: '',
  passwordConfirm: '',
};

type FormValues = typeof FormInitValues;

const FormSchema = Yup.object().shape({
  vaultRecoverSecret: Yup.string().required('Ne peut être vide'),
  password: Yup.string()
    .required('Ne peut être vide')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
      'Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule et un caractère spécial'
    ),
  passwordConfirm: Yup.string()
    .required('Ne peut être vide')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Les mots de passe doivent être identiques'),
    }),
});

type PasswordLostConfirmFormProps = {
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  error?: ApiError | undefined;
};
const PasswordLostConfirmForm: ComponentType<PasswordLostConfirmFormProps> = ({ onSubmit, error }) => {
  return (
    <Formik
      onSubmit={(values, helpers) => onSubmit(FormSchema.cast(values), helpers)}
      initialValues={FormInitValues}
      validationSchema={FormSchema}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}
          <Form.Text className="d-block mt-4 text-center">
            Le changement de mot de passe nécessite de fournir le code de récupération qui vous a été envoyé lors de
            votre inscription.
          </Form.Text>
          <Form.Group className={'mt-3'}>
            <Form.Control
              name="vaultRecoverSecret"
              placeholder={'Code de récupération'}
              value={values.vaultRecoverSecret}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.vaultRecoverSecret && !errors.vaultRecoverSecret}
              isInvalid={touched.vaultRecoverSecret && !!errors.vaultRecoverSecret}
            />
            <Form.Control.Feedback type="invalid">{errors.vaultRecoverSecret}</Form.Control.Feedback>
          </Form.Group>
          <p className={'mt-4 mb-2'}>Veuillez saisir votre nouveau mot de passe :</p>
          <Form.Group>
            <Form.Control
              type="password"
              name="password"
              placeholder={'Mot de passe'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.password && !errors.password}
              isInvalid={touched.password && !!errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Control
              type="password"
              name="passwordConfirm"
              placeholder={'Confirmer le mot de passe'}
              value={values.passwordConfirm}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.passwordConfirm && !errors.passwordConfirm}
              isInvalid={touched.passwordConfirm && !!errors.passwordConfirm}
            />
            <Form.Control.Feedback type="invalid">{errors.passwordConfirm}</Form.Control.Feedback>
          </Form.Group>
          <Row className="mt-3">
            <Col xs={'auto'} className={'ms-auto me-auto'}>
              <ButtonWithIcon className={'btn-round'} type={'submit'} variant={'primary'} isSubmitting={isSubmitting}>
                Valider
              </ButtonWithIcon>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordLostConfirmForm;
