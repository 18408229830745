import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef, ComponentType } from 'react';
import useEntities from 'hooks/useEntities';
import { Button } from 'react-bootstrap';
import Loader from './Loader';
import Oops from './Oops';
import EmptyListMessage from './EmptyListMessage';

export type ProgressiveLoadingProps = {
  entities: any[] | undefined;
  isLoading: boolean;
};

export type ProgressiveLoadingRef = {
  refreshAllBackground: () => Promise<void>;
  refreshAll: () => void;
  appendEntity: (newEntity: any) => void;
  prependEntity: (newEntity: any) => void;
  refreshEntity: (entityToRefresh: any) => void;
  deleteEntity: (entityId: string) => void;
};

export type ProgressiveLoadingComponentProps = {
  baseUrl: string;
  maxPageInALoad?: number;
  maxElementsToDisplay?: number;
  loaderComponent?: JSX.Element;
  loadNextComponent?: (onClick: () => void) => JSX.Element;
  emptyListComponent?: JSX.Element;
  emptyMessage?: JSX.Element | string;
  onChangeRefreshMethod?: 'reload' | 'background';
  ref?: any;
  autoLoadOnScroll?: boolean;
};

const ProgressiveLoadingComponent: ComponentType<ProgressiveLoadingComponentProps> = forwardRef(
  (
    {
      baseUrl,
      maxPageInALoad,
      loadNextComponent,
      loaderComponent,
      emptyListComponent,
      emptyMessage,
      onChangeRefreshMethod,
      maxElementsToDisplay,
      autoLoadOnScroll,
      children,
    },
    ref
  ) => {
    // REF
    useImperativeHandle(ref, () => ({
      refreshAllBackground: handleRefreshBackground,
      refreshAll: handleRefresh,
      appendEntity: handleAppendEntity,
      prependEntity: handlePrependEntity,
      refreshEntity: handleRefreshEntity,
      deleteEntity: handleDeleteEntity,
    }));

    // HOOKS
    const { fetch } = useEntities();

    // STATES
    const [entities, setEntities] = useState<any[] | undefined>();
    const [nextPageToLoad, setNextPageToLoad] = useState<number | undefined>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastLoadedPage, setLastLoadedPage] = useState<undefined | number>();
    const [previousBaseUrl, setPreviousBaseUrl] = useState<undefined | string>();
    const [networkError, setNetworkError] = useState<undefined | Error>();

    const [paused, setPaused] = useState<boolean>(false);

    // HANDLERS
    const handleRefreshBackground = useCallback(async () => {
      if (!lastLoadedPage) {
        return;
      }
      const [items, meta] = await fetch(baseUrl, 1, lastLoadedPage);
      setEntities(items);
      setNextPageToLoad(meta.isLastPage ? undefined : lastLoadedPage + 1);
    }, [lastLoadedPage, fetch, baseUrl]);

    const handleRefresh = useCallback(() => {
      setEntities(undefined);
      setPaused(false);
      setLastLoadedPage(undefined);
    }, []);

    const handleAppendEntity = useCallback(
      (newEntity: any) => {
        setEntities([newEntity, ...entities]);
      },
      [entities]
    );

    const handlePrependEntity = useCallback(
      (newEntity: any) => {
        setEntities([...entities, newEntity]);
      },
      [entities]
    );

    const handleRefreshEntity = useCallback(
      (entityToRefresh: any) => {
        if (!entities) {
          return;
        }
        const newEntities: any[] = [];
        entities.map((entity) => {
          newEntities.push(entityToRefresh.id === entity.id ? entityToRefresh : entity);
          return undefined;
        });
        setEntities(newEntities);
      },
      [entities]
    );

    const handleDeleteEntity = useCallback(
      (id: string) => {
        if (!entities) {
          return;
        }
        const newEntities: any[] = [];
        entities.map((entity) => {
          if (id !== entity.id) {
            newEntities.push(entity);
          }
          return undefined;
        });
        setEntities(newEntities);
      },
      [entities]
    );

    const onScroll = useCallback(() => {
      const currentScroll = window.pageYOffset + window.innerHeight;
      const maxScroll = document.scrollingElement.scrollHeight;

      if (maxScroll - currentScroll < window.innerHeight * 2 && !isLoading && paused && nextPageToLoad) {
        setPaused(false);
        setIsLoading(true);
      }
    }, [isLoading, nextPageToLoad, paused]);

    // EFFECTS
    // progressive load for all data
    useEffect(() => {
      if (isLoading || !nextPageToLoad || paused || networkError) {
        return;
      }

      setIsLoading(true);

      (async () => {
        try {
          const [fetchEntities, metadatas] = await fetch(baseUrl, nextPageToLoad);
          let mergedEntities = entities ? [...entities, ...fetchEntities] : fetchEntities;
          setLastLoadedPage(nextPageToLoad);

          // special case : max items
          if (maxElementsToDisplay && mergedEntities.length > maxElementsToDisplay) {
            mergedEntities = mergedEntities.slice(0, maxElementsToDisplay);
            setNextPageToLoad(undefined);
          } else if (!metadatas.isLastPage) {
            if (maxPageInALoad === 1 || nextPageToLoad % (maxPageInALoad ?? 1) === 0) {
              setPaused(true);
            }
            setNextPageToLoad(nextPageToLoad + 1);
          } else {
            setNextPageToLoad(undefined);
          }
          setEntities(mergedEntities);
        } catch (err) {
          console.log(err);
          setNetworkError(err);
        } finally {
          setIsLoading(false);
        }
      })();
    }, [
      fetch,
      nextPageToLoad,
      entities,
      isLoading,
      baseUrl,
      maxPageInALoad,
      paused,
      networkError,
      maxElementsToDisplay,
    ]);

    // if no entities (reset case) : set nextpage to load to one
    useEffect(() => {
      !networkError && !entities && setNextPageToLoad(1);
    }, [entities, networkError]);

    // Refresh when url change
    useEffect(() => {
      if (previousBaseUrl === baseUrl) {
        return;
      }
      setNextPageToLoad(1);
      if (onChangeRefreshMethod === 'background') {
        handleRefreshBackground();
      } else {
        handleRefresh();
      }
      setPreviousBaseUrl(baseUrl);
    }, [baseUrl, handleRefresh, handleRefreshBackground, onChangeRefreshMethod, previousBaseUrl]);

    if (typeof children !== 'function') {
      throw new Error('ProgressiveLoadingComponent child must be callable');
    }

    // autoload on scroll
    useEffect(() => {
      if (!autoLoadOnScroll) {
        return;
      }
      function watchScroll() {
        window.addEventListener('scroll', onScroll);
      }
      watchScroll();
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, [autoLoadOnScroll, onScroll]);

    //@ts-ignore
    return (
      <>
        {/* Childs  */}
        {children({ entities, isLoading })}

        {/* Error */}
        {networkError && <Oops err={networkError} />}

        {/* Empty  */}
        {!isLoading &&
          entities &&
          entities.length === 0 &&
          (emptyListComponent ?? (
            <EmptyListMessage>{emptyMessage ?? 'Rien à afficher pour le moment'}</EmptyListMessage>
          ))}

        {/* Loader  */}
        {isLoading && !entities && (loaderComponent ?? <Loader />)}
        {isLoading && entities && <Loader size={'sm'} type={'grow'} />}

        {/* Load more  */}
        {paused &&
          nextPageToLoad &&
          (loadNextComponent ? (
            loadNextComponent(() => setPaused(false))
          ) : (
            <div className={'text-center my-3'}>
              <Button
                variant={'outline-secondary'}
                onClick={() => {
                  setPaused(false);
                }}
              >
                Charger les suivants
              </Button>
            </div>
          ))}
      </>
    );
  }
);

export default ProgressiveLoadingComponent;
