import { useCallback } from 'react';
import createProvider from './createProvider';
import createPersistedState from 'use-persisted-state';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import useConfig from './useConfig';
import Cookies from 'js-cookie';
import useToast from './useToast';

type AuthToken = {
  token?: string;
  refreshToken?: string;
  vaultSecret?: string;
};

export const getIsAccessTokenValid = (accessToken?: string, secondsBeforeRefresh = 300) => {
  if (typeof accessToken !== 'string') return false;

  const jwtContent = jwtDecode<{ exp: number; iat: string; email: string }>(accessToken);

  const now = Math.floor(Date.now() / 1000);
  const remainingLifetime = jwtContent.exp - now - secondsBeforeRefresh;
  return remainingLifetime > 0;
};

const useAuthState = createPersistedState('auth', {
  getItem: (k: string): string | undefined => {
    return Cookies.get(k);
  },
  setItem: (k: string, v) => {
    Cookies.set(k, v, {
      expires: 31,
      sameSite: 'strict',
      // eslint-disable-next-line
      secure: !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
    });
  },
});

const useAuthenticationState = () => {
  const [token, setToken] = useAuthState<AuthToken>({
    token: undefined,
    refreshToken: undefined,
    vaultSecret: undefined,
  });
  const apiConfig = useConfig((config) => config.api);
  const [{ toast }] = useToast();

  const getFreshAccessToken = async () => {
    const refreshToken = token?.refreshToken;
    const isTokenValid = token?.token && getIsAccessTokenValid(token.token);

    if (isTokenValid) {
      return token;
    }

    if (refreshToken) {
      try {
        console.log('refresh auth token');
        const client = axios.create({
          baseURL: apiConfig.url,
          timeout: 10000,
        });
        const resp = await client.post('/token_refresh', {
          refresh_token: refreshToken,
        });
        const newToken = {
          token: resp.data.token,
          refreshToken: resp.data.refresh_token,
          vaultSecret: token.vaultSecret,
        };
        setToken(newToken);
        return newToken;
      } catch (err) {
        toast({
          type: 'danger',
          message: 'Votre session a expirée',
          id: 'userlogout',
        });
        console.log(err);
      }
    }
  };

  const logout = useCallback(() => {
    setToken({
      token: undefined,
      refreshToken: undefined,
      vaultSecret: undefined,
    });
  }, [setToken]);

  const actions = {
    getFreshAccessToken,
    setToken,
    logout,
  };

  type ReturnType = [typeof token, typeof actions];
  return [token, actions] as ReturnType;
};

const [withAuthentication, useAuthentication] = createProvider(useAuthenticationState);

export { withAuthentication };

export default useAuthentication;
