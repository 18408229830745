import React, { ComponentType } from 'react';
import { Row, Col, Container, Carousel } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { LinkContainer } from 'react-router-bootstrap';
import {
  imgFree,
  imgSecurity,
  imgFamily,
  imgShares,
  imgExampleClassroom,
  imgExampleStudent,
  imgExamplePhotos,
  imgWrite,
} from 'helpers/illustration';
import { useConnectedUser } from 'hooks';
import imgAppStore from 'assets/appstore.svg';
import imgPlayStore from 'assets/google-play.png';
import BigLoader from 'components/BigLoader';

const MainContainer = styled.div`
  h4 {
    font-weight: 500;
  }

  .badge {
    padding: 8px 10px;
    margin: 7px;
  }
`;

const StoreLink = styled.a`
  display: block;
  overflow: hidden;
  border-radius: 8px;
  height: 42px;
  max-width: 150px;
  border: none;
  margin-bottom: 10px;
  margin-top: 10px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const RegisterLink = styled(Link)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  height: 40px;
  font-size: 1.05em;
  border: none;

  justify-content: center;
`;

const Discover: ComponentType = () => {
  const [user, , userLoaded] = useConnectedUser();

  if (!userLoaded) {
    return <BigLoader />;
  }

  if (user) {
    return <Navigate to={`/dashboard`} replace={true} />;
  }

  return (
    <MainContainer>
      <div className={'p-4 mb-5 jumbotron'}>
        <Container>
          <Row className={'align-items-center justify-content-center'}>
            <Col xl={{ offset: 1 }}>
              <h3>Gagnez du temps et simplifiez votre quotidien</h3>
              <h6 className="mt-4">
                <strong>Elevapp</strong> est conçue pour simplifier votre{' '}
                <b className={'text-primary'}>gestion de classe</b> pour tous les niveaux, de la maternelle au lycée.
              </h6>
              <Row className={'align-items-center justify-content-center mt-4'}>
                <Col xs={'auto'}>
                  <LinkContainer to={`/register`}>
                    <RegisterLink className={'btn  btn-primary'} to={`/register`} role="button">
                      Je m'inscris !
                    </RegisterLink>
                  </LinkContainer>
                </Col>
                <Col xs={'auto'}>
                  <StoreLink
                    href={'https://apps.apple.com/fr/app/elevapp/id1569443390?itsct=apps_box_badge&amp;itscg=30200'}
                  >
                    <img src={imgAppStore} alt="Télécharger sur l'App Store" />
                  </StoreLink>
                </Col>
                <Col xs={'auto'}>
                  <StoreLink href={'https://play.google.com/store/apps/details?id=fr.glachant.elevapp&gl=FR'}>
                    <img src={imgPlayStore} alt="Disponible sur le PlayStore" />
                  </StoreLink>
                </Col>
              </Row>
              <p className="mt-2 text-center">
                <small>
                  <i>Par des enseignants, pour des enseignants.</i>
                </small>
              </p>
            </Col>
            <Col md={5} lg={5} className={'ms-auto d-none d-md-block'}>
              <div className={'d-flex'} style={{ width: '100%' }}>
                <Carousel
                  controls={false}
                  indicators={false}
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <Carousel.Item>
                    <img
                      style={{ maxHeight: 250, border: '1px solid #e4e4e4', borderRadius: 6 }}
                      src={imgExampleClassroom}
                      alt={''}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      style={{ maxHeight: 250, border: '1px solid #e4e4e4', borderRadius: 6 }}
                      src={imgExampleStudent}
                      alt={''}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      style={{ maxHeight: 250, border: '1px solid #e4e4e4', borderRadius: 6 }}
                      src={imgExamplePhotos}
                      alt={''}
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container id={'more'}>
        <Row className={'align-items-center justify-content-center'}>
          <Col md={6} lg={4}>
            <img src={imgFamily} alt={''} />
          </Col>
          <Col md={6} lg={8}>
            <h4 className={'mt-4'}>Un annuaire toujours à portée de main</h4>
            <p>Besoin de contacter un parent en urgence ? Un doute sur le PAI de votre élève ?</p>

            <p style={{ fontWeight: 600 }}>
              L'annuaire des élèves permet d'accéder instanément aux informations de vos élèves, où que vous soyez.
            </p>
            <ul>
              <li>Accès simple et rapide aux coordonnées de l'élève / de la famille</li>
              <li>Intuitif et complet (informations générales, parents, ...)</li>
              <li>
                Disponible sur <u>iOS, Android et Web</u>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className={'align-items-center justify-content-center'}>
          <Col sm={12} style={{ marginTop: 60 }}>
            <Row className={'align-items-center justify-content-center'}>
              <Col className={'order-12 order-lg-1'}>
                <h4 className={'mt-4'}>Fini les post-it qui s'envolent et la mémoire qui flanche</h4>
                <p>
                  <strong className={'text-primary'}>Prenez des notes</strong> sur un élève ou sur une classe,
                  classez-les par <strong className={'text-primary'}>categorie</strong> et{' '}
                  <strong className={'text-primary'}>ajoutez des photos</strong>.
                </p>
                <p style={{ fontSize: '1.05em', fontWeight: 500 }}>
                  Lors d'un conseil de classe ou durant la prochaine réunion avec les parents, retrouvez rapidement les
                  observations de l'élève concerné.
                </p>

                <p className={'text-muted mb-0'}>
                  <small style={{ fontStyle: 'italic' }}>Quelques exemples :</small>
                </p>
                <ul className={'text-muted'} style={{ fontSize: '0.92em', fontStyle: 'italic' }}>
                  <li>Sophie a un gros problème avec l'orthographe</li>
                  <li>Stéphane a encore séché le cours de Philo</li>
                  <li>Nicolas a mordu son camarade</li>
                  <li>Eden semble triste et reste seul</li>
                  <li>Les efforts de Sarah en maths commencent à porter leurs fruits</li>
                </ul>
              </Col>
              <Col lg={'auto'} className={'order-1 order-lg-12'}>
                <img src={imgWrite} alt={''} style={{ maxHeight: 200, width: 'auto' }} />
              </Col>
            </Row>
          </Col>

          <Col xs={'auto'} style={{ marginTop: 80 }}>
            <Row className={'align-items-center justify-content-center'}>
              <Col lg={'auto'}>
                <img src={imgShares} alt={''} style={{ maxHeight: 200, width: 'auto' }} />
              </Col>
              <Col>
                <h4 className={'mt-4'}>Invitez vos collégues et travaillez ensemble</h4>
                <p>
                  <span style={{ fontWeight: 600 }}>Vous partagez votre classe avec d'autres enseignants ?</span>
                  <br />
                  Invitez-les à consulter ou participer à la classe tout en limitant leur accès aux informations dont
                  ils ont besoin.
                </p>

                <p>
                  <span style={{ fontWeight: 600 }}>Vous devez vous faire remplacer ?</span>
                  <br />
                  Grâce à elevapp, vous retrouvez les évenements survenus pendant votre absence, ainsi vous pourrez tout
                  savoir sur vos élèves à votre retour.
                </p>

                <p>
                  <span style={{ fontWeight: 600 }}>C'est la rentrée ?</span>
                  <br />
                  Vous pouvez transmettre les informations générales et les contacts de vos élèves à vos collégues{' '}
                  <span>(évidemment ça fonctionne de l'autre sens aussi)</span>.
                </p>
              </Col>
            </Row>
          </Col>

          <Col sm={5} style={{ marginTop: 80 }}>
            <p className={'text-center'}>
              <img src={imgFree} alt={''} style={{ maxHeight: 120, width: 'auto' }} />
            </p>
            <h4 className={'mt-4'}>C'est gratuit !</h4>
            <p className={'mb-1'}>
              L'utilisation de cette application est <b>gratuite et le restera</b>.
            </p>
            <p style={{ fontSize: '0.8em' }}>
              Vous pouvez garder votre argent pour acheter la nouvelle méthode de Grammaire (ou partir en weekend).
            </p>
            <p>Soyez rassurés, ce site n'utilise aucun tracking ou autre système de flicage.</p>
          </Col>

          <Col sm={{ span: 5, offset: 1 }} style={{ marginTop: 70 }}>
            <p className={'text-center'}>
              <img src={imgSecurity} alt={''} style={{ maxHeight: 120, width: 'auto' }} />
            </p>
            <h4 className={'mt-4'}>Sécurité maximale</h4>
            <p style={{ fontWeight: 500 }}>Les données personnelles de vos élèves méritent la meilleure protection.</p>
            <p style={{ fontSize: '0.95em' }}>
              Pour stocker ces données, nous utilisons un algorithme de chiffrement, ainsi nous vous offrons une
              protection maximale et <u>personne</u> ne peut y accéder{' '}
              <small className={'text-muted'}>(pas même nous)</small>.
            </p>
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
};

export default Discover;
