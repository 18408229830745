import { ApiError } from 'helpers/apiError';
import React, { ComponentType, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FiAlertCircle } from 'react-icons/fi';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  border: 1px solid #eb5552;
  background-color: rgba(235, 85, 82, 0.07);
  border-radius: 8px;
  color: #eb5552;

  .error-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;

    margin-right: 15px;
    line-height: 1;
  }

  .error-title {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .error-message {
    font-size: 0.9em;
  }

  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
`;

type FormErrorProps = {
  error: ApiError | ApiError[] | undefined;
};

const FormError: ComponentType<FormErrorProps> = ({ error }) => {
  const title = useMemo(() => (Array.isArray(error) ? error[0]?.title : error?.title), [error]);

  // Ignore criticals because already display by toast */
  if (!error || (!Array.isArray(error) && error.type === 'servercritical')) {
    return <></>;
  }

  return (
    <ErrorContainer className={'form-error mx-4 my-3 pe-4 ps-2 py-2'}>
      <Row className={'align-items-center g-0'}>
        <Col xs={'auto'}>
          <span className={'error-icon'}>
            <FiAlertCircle />
          </span>
        </Col>
        <Col>
          {title && <div className={'error-title'}>{title}</div>}
          <div className={'error-message'}>
            {((Array.isArray(error) && (
              <ul>
                {error.map((err, idx) => (
                  <li key={idx}>{err.message}</li>
                ))}
              </ul>
            )) ||
              (error as ApiError)?.message) ??
              'Erreur inconnue'}
          </div>
        </Col>
      </Row>
    </ErrorContainer>
  );
};

export default FormError;
