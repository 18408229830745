import ButtonWithIcon from 'components/ButtonWithIcon';
import React, { ComponentType, useCallback, useState } from 'react';
import { Modal, Col, Form, Row } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import useApiClient from 'hooks/useApiClient';
import { StudentType } from 'types';

type ExportPdfModalProps = {
  show: boolean;
  onHide: () => void;
  student: StudentType;
};

const ExportPdfModal: ComponentType<ExportPdfModalProps> = ({ show, onHide, student }) => {
  /**
   * HOOKS
   */
  const api = useApiClient();

  /**
   * STATES
   */
  const [pdfIncludeObservations, setPdfIncludeObservations] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * CALLBACKS
   */
  const handleDownloadPDF = useCallback(async () => {
    setIsSubmitting(true);
    let baseUrl = `/students/${student?.id}/pdf`;
    if (pdfIncludeObservations) {
      baseUrl += '?includeObservations=true';
    }

    const { data: blob } = await api.get(baseUrl, {
      responseType: 'blob',
      timeout: 60000,
    });
    saveAs(blob, `${student?.givenName} ${student?.familyName}.pdf`);

    setIsSubmitting(false);
    onHide();
  }, [api, onHide, pdfIncludeObservations, student?.familyName, student?.givenName, student?.id]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Télécharger fiche PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Vos collégues n'utilisent pas Elevapp ? Vous pouvez tout de même leur transmettre vos élèves en les exportant
          au format PDF.
        </p>
        <p className="text-danger" style={{ fontSize: '0.9em' }}>
          <u>Attention</u> : vous êtes responsable de la confidentialité de vos données, en les exportant, vous
          garantissez avoir les autorisations nécessaires.
        </p>
        <Form.Check
          type={'switch'}
          id={`pdf-include-observations`}
          checked={pdfIncludeObservations}
          onChange={(e) => setPdfIncludeObservations(e.target.checked)}
          label={`Inclure les observations`}
        />
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xs={'auto'}>
            <ButtonWithIcon variant="link" className={'d-none d-sm-inline'} onClick={onHide} disabled={isSubmitting}>
              Annuler
            </ButtonWithIcon>
            <ButtonWithIcon
              className={'round'}
              type={'submit'}
              onClick={handleDownloadPDF}
              variant="primary"
              isSubmitting={isSubmitting}
            >
              Télécharger
            </ButtonWithIcon>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportPdfModal;
