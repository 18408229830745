import React, { ComponentType, useState, useCallback } from 'react';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { ApiError, parseFormApiError } from 'helpers/apiError';
import { useConnectedUser } from 'hooks';
import Loader from 'components/Loader';
import RegisterForm from './components/RegisterForm';
import { Card, Row, Col, Container } from 'react-bootstrap';
import iconImg from 'assets/icon.png';
import styled from 'styled-components';
import useEntities from 'hooks/useEntities';

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 75px;
  width: 75px;
  border-radius: 15px;
  display: block;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
  }
`;

const Register: ComponentType = () => {
  // HOOKS
  const navigate = useNavigate();
  const [user, , userLoaded] = useConnectedUser();
  const { postEntity } = useEntities();

  // STATES
  const [errorRegister, setErrorRegister] = useState<ApiError | undefined>();

  // HANDLERS
  const handleRegister = useCallback(
    async (values, helpers) => {
      try {
        await postEntity('/users', {
          email: values.email,
          plainPassword: values.password,
          givenName: values.givenName,
          familyName: values.familyName,
          newsletterAccepted: values.newsletterAccepted,
        });
        navigate(`/confirm_account?email=${values.email}`);
      } catch (err) {
        setErrorRegister(parseFormApiError(err, helpers));
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [navigate, postEntity]
  );

  if (!userLoaded) {
    return <Loader />;
  }

  if (user) {
    return <Navigate to={`/dashboard`} replace />;
  }

  return (
    <Container className={'marginauto'}>
      <Row className={'pt-5'}>
        <Col xxl={4} xl={5} lg={6} md={8} sm={10} className={'ms-auto me-auto'}>
          <Logo src={iconImg} onClick={() => navigate('/')} />

          <h4 className={'text-center mb-4'}>Bienvenue sur elevapp</h4>
          <Card>
            <Card.Body className={'p-4'}>
              <RegisterForm onSubmit={handleRegister} error={errorRegister} />
            </Card.Body>
          </Card>
          <p className={'text-center text-muted'}>
            <>
              Vous avez déja un compte ? <Link to={'/signin'}>Connectez-vous</Link>
            </>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
