import { ToastFullConfig, ToastConfig } from 'components/toast/ToastBox';
import { uuid } from 'helpers/uuid';
import { useCallback, useMemo, useState } from 'react';
import createProvider from './createProvider';

const useToast = () => {
  /**
   * STATES
   */
  const [toasts, setToasts] = useState<ToastFullConfig[]>([]);

  /**
   * HANDLERS
   */
  const newToast = useCallback((newToast: ToastConfig) => {
    setToasts((prevToasts) => {
      let alreadyExists = false;
      prevToasts.map((t) => {
        if (t.id === newToast.id) {
          alreadyExists = true;
        }
        return undefined;
      });

      if (alreadyExists) {
        return prevToasts;
      }

      return [...prevToasts, { index: prevToasts.length, id: newToast.id ? newToast.id : uuid(), ...newToast }];
    });
  }, []);

  const hideToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((el) => el.id !== id));
  }, []);

  const helpers = useMemo(() => {
    return {
      toast: newToast,
      hideToast,
    };
  }, [hideToast, newToast]);

  const vars = useMemo(() => {
    return {
      toasts,
    };
  }, [toasts]);

  return [helpers, vars] as [typeof helpers, typeof vars];
};

const [withToast, useProvidedToast] = createProvider(useToast);

export { withToast };

export default useProvidedToast;
