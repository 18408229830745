import { useEffect, useState, useCallback } from 'react';
import createProvider from './createProvider';
import { UserType } from 'types';
import useApiClient from './useApiClient';
import useAuthentication from './useAuthentication';
import useToast from './useToast';

const useConnectedUser = () => {
  // HOOKS
  const [token, { logout }] = useAuthentication();
  const client = useApiClient();
  const [{ toast }] = useToast();

  // SATES
  const [user, setUser] = useState<UserType | undefined>();
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  // REFRESH METHOD
  const refreshUser = useCallback(async () => {
    if (!token) {
      setUser(undefined);
      setUserLoaded(false);
      return;
    }

    if (!token.token) {
      setUser(undefined);
      setUserLoaded(true);
      return;
    }

    try {
      const resp = await client.get('/me');
      setUser(resp.data);
      setUserLoaded(true);
    } catch (err) {
      console.log('error during call /me');
      toast({
        type: 'danger',
        message: 'Vous avez été déconnecté',
        id: 'userlogout',
      });
      logout();
    }
  }, [client, logout, toast, token]);

  // EFFECTS
  // Token empty : set undefined
  useEffect(() => {
    if (!token || !token.token) {
      setUser(undefined);
    }
  }, [token, setUser]);

  useEffect(() => {
    if (user) {
      return;
    }
    refreshUser();
  }, [user, refreshUser]);

  type ReturnType = [typeof user, typeof refreshUser, boolean];
  return [user, refreshUser, userLoaded] as ReturnType;
};

const [withConnectedUser, useProvidedConnectedUser] = createProvider(useConnectedUser);

export { withConnectedUser };

export default useProvidedConnectedUser;
