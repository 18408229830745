import { useState, useCallback, useMemo, useEffect } from 'react';
import { ClassroomType, QuoteType } from 'types';
import createProvider from './createProvider';
import useEntities from './useEntities';
import deepEqual from 'deep-equal';
import useApiClient from './useApiClient';
import { ClassroomBannerType } from 'types/ClassroomType';
import { useConnectedUser } from 'hooks';

let isLoading = false;

const useGlobal = () => {
  /**
   * HOOKS
   */
  const { fetchAll } = useEntities();
  const api = useApiClient();
  const [, , isUserLoaded] = useConnectedUser();

  /**
   * STATES
   */
  const [quote, setQuote] = useState<QuoteType | undefined>();
  const [classrooms, setClassrooms] = useState<ClassroomType[] | undefined>();
  const [banners, setBanners] = useState<ClassroomBannerType[] | undefined>();

  // REFRESH METHOD
  const refreshQuote = useCallback(async () => {
    const { data } = await api.get(`/daily_quote?platform=web`);
    setQuote((state) => (!deepEqual(data, state) ? data : state));
  }, [api]);

  const refreshClassrooms = useCallback(async () => {
    const entities = await fetchAll(`/classrooms?order[createdAt]=asc`);
    setClassrooms((state) => (!deepEqual(entities, state) ? entities : state));
  }, [fetchAll]);

  const refreshBanners = useCallback(async () => {
    const resp = await fetchAll(`/classroom_banners`);
    setBanners(resp);
  }, [fetchAll]);

  const refreshAll = useCallback(async () => {
    await Promise.all([refreshBanners(), refreshQuote(), refreshClassrooms()]);
  }, [refreshBanners, refreshClassrooms, refreshQuote]);

  // cleanup
  const cleanup = useCallback(async () => {
    setClassrooms(undefined);
    setQuote(undefined);
  }, []);

  /**
   * Autoload data where start app
   */
  useEffect(() => {
    if (!isUserLoaded || isLoading) {
      return;
    }

    const loadFunc = async () => {
      isLoading = true;
      await refreshBanners();
      isLoading = false;
    };

    loadFunc();
  }, [isUserLoaded, refreshAll, refreshBanners, refreshClassrooms, refreshQuote]);

  const actions = useMemo(() => {
    return {
      refreshClassrooms,
      refreshQuote,
      refreshBanners,
      cleanup,
    };
  }, [cleanup, refreshBanners, refreshClassrooms, refreshQuote]);

  const values = {
    classrooms,
    quote,
    banners,
  };

  return [values, actions] as [typeof values, typeof actions];
};

const [withGlobal, useProvidedGlobal] = createProvider(useGlobal);

export { withGlobal };

export default useProvidedGlobal;
