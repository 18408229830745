import React, { forwardRef, useRef, useImperativeHandle, useMemo } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import FormError from 'components/form/FormError';
import { ApiError } from 'helpers/apiError';
import { StudentGroupType } from 'types';

const FormInitValues = {
  name: '',
};

type FormValues = {
  name: string;
};

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, 'Doit contenir au moins 1 caractères')
    .max(32, 'Doit contenir au maximum 32 caractères')
    .required('Ne peut être vide'),
});

type StudentGroupFormProps = {
  entity?: StudentGroupType;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  error?: ApiError | undefined;
};

const StudentGroupForm = forwardRef(({ onSubmit, entity, error }: StudentGroupFormProps, ref: any) => {
  /**
   * REF
   */
  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      formRef && formRef.current && (await formRef.current.submitForm());
    },
  }));

  /**
   * HOOKS
   */
  const formRef = useRef<any | null>(null);

  /**
   * MEMO
   */
  const initvalues = useMemo(() => {
    return {
      ...FormInitValues,
      ...(entity
        ? {
            name: entity.name,
          }
        : {}),
    };
  }, [entity]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initvalues}
      enableReinitialize
      validationSchema={FormSchema}
      innerRef={(ref) => {
        formRef.current = ref;
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        handleBlur,
        setFieldError,
        setFieldValue,
      }: FormikProps<FormValues>) => (
        <Form noValidate onSubmit={handleSubmit}>
          {error && <FormError error={error} />}

          <Form.Group className="mb-3">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Saisissez le nom de votre groupe"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete={'off'}
              autoFocus={true}
              isInvalid={touched.name && !!errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

export default StudentGroupForm;
