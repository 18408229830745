import ArchivedBanner from 'components/ArchivedBanner';
import ClassroomAvatar from 'components/ClassroomAvatar';
import { useCurrentClassroom } from 'hooks';
import useEntities from 'hooks/useEntities';
import React, { ComponentType, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { StudentGroupType, StudentType } from 'types';
import { ClassroomType } from 'types/ClassroomType';

const avatarSize = 70;

const ClassroomContainer = styled.div`
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    color: #3092e3;
    img {
      opacity: 0.9;
    }
  }
`;

const ClassroomContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.archived {
    filter: grayscale(100%);
    opacity: 0.7;
  }
`;

type ClassroomItemProps = {
  classroom: ClassroomType;
};

const ClassroomItem: ComponentType<ClassroomItemProps> = ({ classroom }) => {
  /** HOOKS */
  const [, , { init: initClassroom }] = useCurrentClassroom();
  const navigate = useNavigate();
  const { fetchAll } = useEntities();

  /** STATES */
  const [cacheStudents, setCacheStudents] = useState<StudentType[] | undefined>();
  const [cacheGroups, setCacheGroups] = useState<StudentGroupType[] | undefined>();

  /** HANDLERS */
  const goToClassroom = useCallback(async () => {
    initClassroom(classroom['@id'], classroom, cacheStudents, cacheGroups);
    navigate(`/classrooms/${classroom?.id}`);
  }, [cacheGroups, cacheStudents, classroom, initClassroom, navigate]);

  const populateCache = useCallback(async () => {
    await Promise.all([
      (async () => {
        const dataStudents = await fetchAll(`/students?classroom=${classroom['@id']}`);
        setCacheStudents(dataStudents);
      })(),
      (async () => {
        const dataGroups = await fetchAll(`/student_groups?classroom=${classroom['@id']}`);
        setCacheGroups(dataGroups);
      })(),
    ]);
  }, [fetchAll, classroom]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (cacheStudents || cacheGroups) {
      return;
    }
    populateCache();
  }, [cacheGroups, cacheStudents, populateCache]);

  return (
    <ClassroomContainer onClick={goToClassroom}>
      <Row className={'align-items-center g-0'}>
        <Col xs={'auto'} className={'me-3'}>
          <div
            style={{
              borderRadius: avatarSize,
              height: avatarSize,
              width: avatarSize,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {classroom?.archived && <ArchivedBanner size={'sm'}>Archivée</ArchivedBanner>}
            <ClassroomContentContainer className={classroom?.archived ? 'archived' : ''}>
              <ClassroomAvatar classroom={classroom} shape={'round'} size={avatarSize} />
            </ClassroomContentContainer>
          </div>
        </Col>
        <Col>
          <h4 style={{ margin: 0, marginBottom: 3, fontSize: '1.4em', fontWeight: 700 }}>{classroom.name}</h4>
          <p className={'text-muted'} style={{ margin: 0, fontSize: '0.9em', fontWeight: 500 }}>
            {classroom.schoolYear} - {classroom.owner.givenName} {classroom.owner.familyName}
          </p>
        </Col>
      </Row>
    </ClassroomContainer>
  );
};

export default ClassroomItem;
