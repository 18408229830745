import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';

const EditContainer = styled.div`
  display: none;
  margin-left: 15px;
  font-size: 16px;
  vertical-align: center;
  color: #777;

  &:hover {
    cursor: pointer;
    color: #444;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6em;
  font-weight: 700;

  &:hover ${EditContainer} {
    display: inline-block;
  }
`;

type StudentGroupTitleProps = {
  name: string;
  onEdit?: () => void;
};

const StudentGroupTitle: ComponentType<StudentGroupTitleProps> = ({ name, onEdit }) => {
  return (
    <Title>
      {name}
      {onEdit && (
        <EditContainer onClick={onEdit}>
          <FiEdit />
        </EditContainer>
      )}
    </Title>
  );
};

export default StudentGroupTitle;
