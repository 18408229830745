import React, { ComponentType, useRef, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { resizeImage } from 'helpers/image';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { FiCamera } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

type PreviewProps = {
  url?: string;
};

const AddMediaBtn = styled.div`
  padding: 8px;
  &:hover {
    cursor: pointer;
    color: red;
  }
  margin-right: 0.4rem;
`;

const Preview = styled.div<PreviewProps>`
  position: relative;
  height: 100px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-image: url(${(props) => props.url});
  background-position: center;
  border-radius: 5px;
  background-color: silver;
  color: white;

  > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

const PreviewDelete = styled.div`
  position: absolute;
  top: 6%;
  right: 6%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  font-size: 20px;
  width: 24px;
  height: 24px;
  text-align: center;
  z-index: 100;
  color: rgba(255, 255, 255, 0.95);
  transition: background-color 0.2s;

  svg {
    margin-top: -7px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export type MediaInputItem = {
  blob?: Blob | undefined;
  base64?: string | undefined;
  iri?: string | undefined;
  thumbnail?: string | undefined;
  isLoading?: boolean;
};

type MediaInputProps = {
  value?: MediaInputItem[];
  onChange: (arg: MediaInputItem[]) => void;
  onError: (err: string) => void;
  isInvalid?: boolean;
};

const ObservationsMediaInput: ComponentType<MediaInputProps> = ({ value, onChange, isInvalid, onError }) => {
  // HOOKS
  const uploadButtonRef = useRef<any>(null);

  // HANDLERS
  const handleChange = useCallback(
    async (event: any) => {
      const files = event.target.files;

      const newFiles: MediaInputItem[] = [];

      for (let i = 0; i < files.length; i++) {
        // spinner
        onChange([...value, ...newFiles, ...[{ isLoading: true }]]);

        const file = files[i];
        const blob = await resizeImage(file);
        try {
          newFiles.push({
            blob: blob,
            base64: URL.createObjectURL(blob),
          });
          onChange([...value, ...newFiles]);
        } catch (err) {
          onError(err.message);
        }
      }
      onChange([...value, ...newFiles]);
    },
    [onChange, onError, value]
  );

  return (
    <div className={`${isInvalid ? 'is-invalid' : ''}`}>
      <input
        ref={uploadButtonRef}
        type={'file'}
        accept={'image/*'}
        multiple={true}
        onChange={handleChange}
        className={'d-none'}
      />
      <AddMediaBtn
        className="text-black text-opacity-75"
        onClick={() => {
          uploadButtonRef.current && uploadButtonRef.current.click();
        }}
      >
        <FiCamera style={{ fontSize: '1.8em' }} />
      </AddMediaBtn>
    </div>
  );
};

type MediaPreviewProps = {
  value?: MediaInputItem[];
  onChange: (arg: MediaInputItem[]) => void;
};

export const MediaInputPreview: ComponentType<MediaPreviewProps> = ({ onChange, value }) => {
  // HANDLERS
  const handleDelete = useCallback(
    (idToDelete: number) => {
      const newArray: MediaInputItem[] = [];
      value.map((media, idx) => idx !== idToDelete && newArray.push(media));
      onChange(newArray);
    },
    [onChange, value]
  );

  return (
    <Row className={'px-3'}>
      {value?.map((media, idx) => (
        <Col xs={'auto'} className={'px-1 py-1'} key={media.isLoading ? 'new' : idx}>
          {!media.isLoading && (
            <Preview url={media.iri ? media.thumbnail : media.base64}>
              <div>
                <PreviewDelete onClick={() => handleDelete(idx)}>
                  <IoClose />
                </PreviewDelete>
              </div>
            </Preview>
          )}
          {media.isLoading && (
            <Preview>
              <div className={'mt-2'}>
                <Loader />
              </div>
            </Preview>
          )}
        </Col>
      ))}
    </Row>
  );
};

export default ObservationsMediaInput;
